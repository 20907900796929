import { Download, FormPageHeader, Paginator } from 'components/Shared';
import { useAuth, usePaginationCache } from 'hooks';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { useLocation } from 'react-router-dom';
import {
  ExportOrdersActions,
  PaginateOrdersActions as PaginateActions,
} from 'store/ducks/orders';
import Filters, { FindOrders } from './Filters';
import OrdersList from './OrdersList';
import OrderModal, { Ref as OrderModalRef } from '../OrderModal';
import * as S from './styles';
import { useOrder } from 'hooks/order';

export const PaginatedOrders: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { userBelongsToAnyOf } = useAuth();
  const { generateXLSX } = useOrder();
  const orderModalRef = useRef<OrderModalRef>(null);

  const {
    data: orders,
    loading: loadingOrders,
    pagination,
  } = useSelector((state: RootState) => state.paginateOrders);

  const { loading: loadingExportOrders } = useSelector(
    (state: RootState) => state.exportOrders
  );

  const { updatePaginationCache, paginationCache, handleFilter, handleSort } =
    usePaginationCache<FindOrders>('orders');

  const [query, setQuery] = useState<FindOrders>(paginationCache || {});

  const onFilter = useCallback((filter: any) => {
    handleFilter(query, filter, setQuery);
  }, []);

  const exportOrders = useCallback(() => {
    dispatch(ExportOrdersActions.request(query, generateXLSX));
  }, [query, dispatch, generateXLSX]);

  const onPageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const onQueryChange = useCallback((): void => {
    dispatch(PaginateActions.request(query));
  }, [dispatch, query]);

  const CreateOrderButton = useCallback((): JSX.Element => {
    if (!userBelongsToAnyOf('admin', 'warehouseMember', 'companyMember')) {
      return <></>;
    }

    return (
      <S.LinkButton
        size="small"
        to="/agendamento/criar"
        state={{ from: location.pathname }}
      >
        <S.PlusIcon /> Novo agendamento
      </S.LinkButton>
    );
  }, [location.pathname, userBelongsToAnyOf]);

  const CreateOrderButtonDownloand = useCallback((): JSX.Element => {
    if (
      !userBelongsToAnyOf(
        'admin',
        'carrierMember',
        'warehouseMember',
        'companyMember'
      )
    ) {
      return <></>;
    }

    return <Download loading={loadingExportOrders} onExport={exportOrders} />;
  }, [userBelongsToAnyOf, loadingExportOrders, exportOrders]);

  useEffect(() => {
    onQueryChange();
  }, [onQueryChange]);

  useEffect(() => {
    return () => {
      updatePaginationCache(query);
    };
  }, [updatePaginationCache, query]);

  return (
    <S.Container>
      <S.Panel>
        <OrderModal onUpdate={onQueryChange} ref={orderModalRef} />
        <FormPageHeader
          title="Painel gerencial"
          icon={<S.ListIcon />}
          isLoading={loadingOrders}
          actions={<CreateOrderButton />}
          downloand={<CreateOrderButtonDownloand />}
        />
        <Filters cacheFilter={paginationCache} onFilter={onFilter} />
        <OrdersList
          orders={orders}
          currentSort={query}
          onSort={(sort) => handleSort(query, sort, setQuery)}
          onSelectOrder={(id) => orderModalRef.current?.selectOrder(id)}
        />
        <Paginator onPageChange={onPageChange} pagination={pagination} />
      </S.Panel>
    </S.Container>
  );
};
