import { OrderFile } from "contracts/OrderFiles";
import * as S from "./styles";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { FetchOrderFileActions } from "store/ducks/orderFiles";

interface Props {
  files: OrderFile[];
}

export const OrderFiles = ({ files }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<number | null>(null);

  const onSuccess = (link: string) => {
    const element = document.createElement("a");
    element.href = link;
    element.rel = "noreferrer";
    element.click();
    element.remove();
    setLoading(null);
  };

  const downloadFile = useCallback((id: number) => {
    setLoading(id)
    dispatch(FetchOrderFileActions.request(id, onSuccess));
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <div>Documento</div>
        <div>Download</div>
      </S.ListHeader>
      {files.map((item) => (
        <S.ListItem key={item.id}>
          <S.Column>{item.fileName}</S.Column>
          <S.Column>
            <button
              disabled={loading === item.id}
              onClick={() => downloadFile(item.id)}
            >
              {loading === item.id ? <S.Loading/> : <S.DownloadIcon />}
            </button>
          </S.Column>
        </S.ListItem>
      ))}
    </S.List>
  );
};
