import React, { useEffect } from 'react';
import { Scaffold } from 'layouts';
import * as S from './styles';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FetchOrderActions } from 'store/ducks/orders';
import { Order } from 'contracts/Orders';
import { RescheduleForm } from 'components/Pages/Scheduling/Reschedule';
import { PageProps } from 'types/global';

interface Props extends PageProps {
  order: Order;
}

export const ReschedulePage: React.FC<any> = ({ order, ...props }: Props) => {
  const { code } = useParams();
  const dispatch = useDispatch();

  const fetchOrder = () => {
    if (!order) return dispatch(FetchOrderActions.request(code));
    dispatch(FetchOrderActions.success(order));
  };

  useEffect(() => {
    fetchOrder();

    return () => {
      dispatch(FetchOrderActions.reset());
    };
  }, []);

  return (
    <Scaffold {...props}>
      <S.PageContainer>
        <RescheduleForm />
      </S.PageContainer>
    </Scaffold>
  );
};
