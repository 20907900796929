import { FormHandles, Scope } from "@unform/core"
import { Form } from "@unform/web"
import { HiddenInput, Input, Select, ToggleInput } from "components/Shared"
import { FORM_BACK_ACTION } from "constants/Common"
import { useCompanies, useValidation } from "hooks"
import React, { useCallback, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import type { AppDispatch, RootState } from "store"
import {
  FetchWarehouseMemberActions as FetchActions,
  UpdateWarehouseMemberActions as UpdateActions,
} from "store/ducks/warehouseMembers"
import { ListCarriersActions } from "store/ducks/carriers"
import { UpdateMemberValidator } from "validators/WarehouseMembers"
import * as S from "./styles"
import { WarehouseMember } from "contracts/WarehouseMembers"

interface Props {
  member: WarehouseMember | null
  onUpdate?: () => void
}

export const MemberUpdateForm: React.FC<Props> = ({ member, onUpdate }) => {
  const dispatch: AppDispatch = useDispatch()
  const formRef = useRef<FormHandles>(null)
  const { handleFormErrors, handleApiErrors } = useValidation()
  const { companyOptions, loadingCompanies, fetchCompanies } = useCompanies()

  const { loading: updatingMember, validationErrors } = useSelector(
    (state: RootState) => state.updateWarehouseMember
  )

  const onMemberLoad = useCallback((): void => {
    if (!member) return

    const { companyUser, name, email, id, blockedAt } = member

    const companyOption = companyOptions.find(
      (o) => o.value === companyUser[0]?.id
    )

    if (companyOption) {
      formRef.current?.setFieldValue("companyId", companyOption)
    }

    formRef.current?.setFieldValue("userId", id)
    formRef.current?.setFieldValue("user.name", name)
    formRef.current?.setFieldValue("user.email", email)
    formRef.current?.setFieldValue(
      "user.blockedAt",
      Boolean(blockedAt) ? "1" : "0"
    )
  }, [member, companyOptions])

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset()
    onUpdate && onUpdate()
  }, [onUpdate])

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef?.current?.setErrors({})

        const formatData = {
          ...data,
          companyId: [data.companyId],
        }

        const { schema } = new UpdateMemberValidator()

        const validData = await schema.validate(formatData, {
          abortEarly: false,
        })

        dispatch(UpdateActions.request(member?.id, validData, onSuccess))
      } catch (error) {
        handleFormErrors(error, formRef)
      }
    },
    [dispatch, handleFormErrors, member, onSuccess]
  )

  useEffect(() => {
    fetchCompanies()
  }, [fetchCompanies])

  useEffect(() => {
    onMemberLoad()
  }, [onMemberLoad])

  useEffect(() => {
    handleApiErrors(validationErrors, formRef)
  }, [handleApiErrors, validationErrors])

  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset())
      dispatch(UpdateActions.reset())
      dispatch(ListCarriersActions.reset())
    }
  }, [dispatch])

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={onSubmit}>
        <HiddenInput name="userId" />
        <Select
          name="companyId"
          label="Armazém"
          options={companyOptions}
          isLoading={loadingCompanies}
        />
        <Scope path="user">
          <S.FormRow>
            <Input name="name" label="Nome completo" />
            <Input name="email" label="Email" />
            <Input name="password" label="Senha" type="password" />
            <ToggleInput name="blockedAt" label="Bloqueado" />
          </S.FormRow>
        </Scope>
        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/armazem/usuarios">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit">
            {updatingMember ? <S.ActivityIndicator /> : "Salvar"}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.Container>
  )
}
