import styled, { css } from 'styled-components';
import {
  BasePanel,
  ChevronRightCircleOutlineIcon,
  InvoiceIcon as Invoice,
  TrashLucideIcon,
} from 'styles/components';
import { Colors, ColorScheme } from 'styles/constants';
import { BasePaginatorHeader, BasePaginatorListItem } from 'styles/components';
export {
  FormRow,
  Button,
  ActionButton,
  ActivityIndicator,
  FormActions,
  PackageIcon,
  WeightIcon,
  TimerIcon,
  WalletIcon,
  PaginatorColumn as Column,
  PaginatorActionsColumn as ActionsColumn,
} from 'styles/components';

interface IPanel {
  removePadding?: boolean;
}

export const TrashIcon = styled(TrashLucideIcon)`
  width: 22px;
  height: 22px;
  color: ${Colors.Magenta};
`;

export const ChevronIcon = styled(ChevronRightCircleOutlineIcon)`
  width: 22px;
  height: 22px;
`;

export const Panel = styled(BasePanel)<IPanel>`
  flex: 0 1 100%;
  flex-direction: column;
  background: ${Colors.White};
  ${({ removePadding }) => !removePadding && `padding: 24px;`}
`;

export const WeightAndDuration = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 24px;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0 8px;
    color: ${ColorScheme.LightText};
    font-size: 14px;
  }
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const baseGridCss = css`
  grid-template-columns: 240px 200px auto minmax(0px, max-content) minmax(
      0px,
      max-content
    );
`;

export const InvoiceIcon = styled(Invoice)`
  color: ${Colors.Gray50};
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;
