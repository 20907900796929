import * as S from './styles';
import * as C from '../../Constants';
import type { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { SelectOption } from 'contracts/Common';
import { useEffect, useImperativeHandle, useState } from 'react';
import { addHours, format, subHours } from 'date-fns';

import { useCarriers, useCompanies, useVehicleSetups } from 'hooks';
import OrderItems, { Ref as ItemsRef } from '../OrderItems';
import { FilesList } from '../FilesList';

export interface FilesRef {
  getFiles: () => File[];
}

interface ILoad {
  formRef: React.RefObject<FormHandles>;
  filesRef: React.RefObject<FilesRef>;
  itemsRef: React.RefObject<ItemsRef>;
}

interface ISelectedState {
  carrierId?: number | string | null;
  weightCapacity?: number;
  loadDuration?: number;
}

export const Load = ({ formRef, itemsRef, filesRef }: ILoad) => {
  const [selects, setSelects] = useState<ISelectedState>();
  const [files, setFiles] = useState<any[]>([]);

  const {
    fetchVehicleSetups,
    vehicleSetups,
    vehicleSetupOptions,
    loadingVehicleSetups,
  } = useVehicleSetups();
  const { fetchCompanies, companyOptions, loadingCompanies } = useCompanies();
  const { fetchCarriers, carrierOptions, loadingCarriers } = useCarriers();

  const onCarrierChange = (option: SelectOption | null) => {
    setSelects((prev) => ({ ...prev, carrierId: option?.value }));
  };

  const onFileChange = ({ currentTarget }: any) => {
    const [file] = currentTarget.files;

    if (file) {
      const alreadyHasFile = files.some(
        (fileFind) => fileFind.name === file.name
      );

      if (!alreadyHasFile) {
        setFiles((prev) => [...prev, file]);
      }
    }
  };

  const onFileRemove = (fileName: string) => {
    setFiles((prev) => prev.filter((file) => file.name !== fileName));
  };

  const onVehicleSetupChange = (option: SelectOption | null) => {
    const vehicle = vehicleSetups.find(({ id }) => id === option?.value);

    setSelects((prev) => ({
      ...prev,
      weightCapacity: vehicle?.weightCapacity,
      loadDuration: vehicle?.loadDuration,
    }));
  };

  const onSchedulingChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const dateFormat = 'yyyy-MM-dd HH:mm';

    const expectedLoadingTime = format(
      subHours(new Date(value), 4),
      dateFormat
    );

    const optimalOutputTime = format(
      addHours(new Date(expectedLoadingTime), 2),
      dateFormat
    );

    formRef.current?.setFieldValue('expectedLoadingTime', expectedLoadingTime);
    formRef.current?.setFieldValue('optimalOutputTime', optimalOutputTime);
  };

  const onCarregamentoChange = ({ target: { value } }: any) => {
    const hoursToAdd = 2;
    const dateFormat = 'yyyy-MM-dd HH:mm';

    const optimalOutput = format(
      addHours(new Date(value), hoursToAdd),
      dateFormat
    );

    formRef.current?.setFieldValue('optimalOutputTime', optimalOutput);
  };

  useImperativeHandle(filesRef, () => ({
    getFiles: () => files,
  }));

  useEffect(() => {
    fetchVehicleSetups({ excludeBlocked: true });
    fetchCompanies();
    fetchCarriers();
  }, []);

  return (
    <>
      <Form ref={formRef} onSubmit={() => {}}>
        <S.Panel>
          <S.FormRow>
            <S.Input name="vehicleCode" label="Id Veículo" />
            <S.Select
              name="vehicleSetupId"
              label="Setup de veículo"
              options={vehicleSetupOptions}
              isLoading={loadingVehicleSetups}
              onChange={onVehicleSetupChange}
            />
            <S.Select
              name="fleetType"
              label="Tipo de Frota"
              options={C.optionsFleet}
            />
            <S.Select
              name="companyId"
              label="Armazém Origem"
              options={companyOptions}
              isLoading={loadingCompanies}
            />
          </S.FormRow>
          <S.FormRow>
            <S.Select
              name="carrierId"
              label="Transportadora"
              options={carrierOptions}
              isLoading={loadingCarriers}
              onChange={onCarrierChange}
            />
            <S.Input name="driverName" label="Nome Motorista" />
            <S.MaskedInput
              name="driverDocument"
              label="CPF Motorista"
              mask={'999.999.999-99'}
            />
            <S.Input
              name="schedulingDate"
              label="Data Agendada"
              type="datetime-local"
              onChange={onSchedulingChange}
              min={C.MIN_DATETIME}
            />
            <S.Input
              name="plannedDepartureTime"
              label="Saída Planejada"
              type="datetime-local"
              min={C.MIN_DATETIME}
            />
          </S.FormRow>
          <S.FormRow>
            <S.Input
              name="expectedLoadingTime"
              label="Horário esperado carregamento"
              type="datetime-local"
              min={C.MIN_DATETIME}
              disabled
              onChange={onCarregamentoChange}
            />
            <S.Input
              name="materialSeparationTime"
              label="Horário separação material"
              type="datetime-local"
              disabled
            />
            <S.Input
              name="deliveryDocumentationTime"
              label="Entrega Documentação"
              type="datetime-local"
              disabled
            />
            <S.Input
              name="optimalOutputTime"
              label="Saída ideal"
              type="datetime-local"
              disabled={true}
            />
          </S.FormRow>
          <S.FormRow>
            <S.Textarea
              name="observation"
              label="Observações"
              style={{ resize: 'none' }}
            />
            <S.Textarea
              name="material"
              label="Materiais"
              style={{ resize: 'none' }}
            />
          </S.FormRow>
          <S.FormRow>
            <S.FileInput
              name="documents"
              label="Upload de Documentos"
              onChange={onFileChange}
              auxText="Apenas Arquivos (.jpg, .png ou .pdf)"
              onlyIcon
              accept=".png, .jpeg, .jpg, .pdf"
            />
          </S.FormRow>
          <S.FormRow>
            {files.length > 0 && (
              <FilesList files={files} onFileDelete={onFileRemove} />
            )}
          </S.FormRow>
        </S.Panel>
      </Form>
      <OrderItems
        ref={itemsRef}
        weightCapacity={selects?.weightCapacity}
        duration={selects?.loadDuration}
        carrierId={selects?.carrierId}
      />
    </>
  );
};
