import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  ConfirmationDialog,
  ConfirmationDialogRef,
  Input,
  Select,
} from 'components/Shared';
import { FORM_BACK_ACTION } from 'constants/Common';
import { SelectOption } from 'contracts/Common';
import { useDocks, useValidation, useCompanies } from 'hooks';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import {
  DeleteDockReservedIntervalActions as DeleteActions,
  FetchDockReservedIntervalActions as FetchActions,
  UpdateDockReservedIntervalActions as UpdateActions,
} from 'store/ducks/dockReservedIntervals';
import { Formatter, TimeHelper } from 'utils';
import { UpdateIntervalValidator } from 'validators/DockReservedIntervals';
import * as S from './styles';

interface Props {
  intervalId: string | number;
  onUpdate?: () => void;
  onDelete?: () => void;
}

interface GeneralState {
  companyId?: string | number;
}

const TIME_STEP = 5 * 60;

export const IntervalUpdateForm: React.FC<Props> = ({
  intervalId,
  onUpdate,
  onDelete,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const dialogRef = useRef<ConfirmationDialogRef>(null);
  const [generalState, setGeneralState] = useState<GeneralState>({});
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { companyOptions, loadingCompanies, fetchCompanies } =
    useCompanies();
  const { dockOptions, loadingDocks, fetchDocks } = useDocks();

  const { data: interval } = useSelector(
    (state: RootState) => state.fetchDockReservedInterval
  );

  const { loading: updatingInterval, validationErrors } = useSelector(
    (state: RootState) => state.updateDockReservedInterval
  );

  const { loading: deletingInterval } = useSelector(
    (state: RootState) => state.deleteDockReservedInterval
  );

  const fetchInterval = useCallback((): void => {
    dispatch(FetchActions.request(intervalId));
  }, [dispatch, intervalId]);

  const setDefaultWarehouseOption = useCallback((): void => {
    if (!interval?.dock) return;

    const companyOption = companyOptions.find(
      (o) => o.value === interval.dock.company.id
    );
    if (companyOption) {
      formRef.current?.setFieldValue('companyId', companyOption);
      setGeneralState((state) => ({
        ...state,
        companyId: companyOption.value,
      }));
    }
  }, [companyOptions, interval?.dock]);

  const onIntervalLoad = useCallback((): void => {
    if (!interval) return;

    const { dock, startDate, endDate, startTime, endTime } = interval;

    const dockOption = dockOptions.find((o) => o.value === dock.id);

    formRef.current?.setFieldValue('dockId', dockOption);
    formRef.current?.setFieldValue(
      'startDate',
      Formatter.date(startDate, { format: 'yyyy-MM-dd' })
    );
    formRef.current?.setFieldValue(
      'endDate',
      Formatter.date(endDate, { format: 'yyyy-MM-dd' })
    );
    formRef.current?.setFieldValue('startTime', startTime);
    formRef.current?.setFieldValue('endTime', endTime);
  }, [dockOptions, interval]);

  const onWarehouseChange = useCallback(
    (option: SelectOption | null): void => {
      formRef.current?.setFieldValue('dockId', null);
      setGeneralState((state) => ({
        ...state,
        companyId: option?.value,
      }));
      if (option) {
        fetchDocks({ companyId: option.value });
      }
    },
    [fetchDocks]
  );

  const onTimeBlur = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const { value, name } = event.target;
      // manipulate the start time to be the next closest 5 minutes
      const newStartTime = TimeHelper.roundUpMinutes(value, 5);
      formRef.current?.setFieldValue(name, newStartTime);
    },
    []
  );

  const onUpdateSuccess = useCallback((): void => {
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        const { schema } = new UpdateIntervalValidator();

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(UpdateActions.request(intervalId, validData, onUpdateSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, intervalId, onUpdateSuccess]
  );

  const onDeleteSuccess = useCallback((): void => {
    onDelete && onDelete();
  }, [onDelete]);

  const handleDelete = useCallback(async (): Promise<void> => {
    const confirmed = await dialogRef.current?.openDialog({
      title: 'Deseja remover o bloqueio de horário?',
      message: 'Esta ação não poderá ser desfeita após confirmada.',
      confirmButtonMood: 'outlinedDanger',
    });
    if (confirmed) {
      dispatch(DeleteActions.request(intervalId, onDeleteSuccess));
    }
  }, [dispatch, intervalId, onDeleteSuccess]);

  useEffect(() => {
    fetchInterval();
  }, [fetchInterval]);

  useEffect(() => {
    setDefaultWarehouseOption();
  }, [setDefaultWarehouseOption]);

  useEffect(() => {
    onIntervalLoad();
  }, [onIntervalLoad]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return (): void => {
      dispatch(FetchActions.reset());
      dispatch(UpdateActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <ConfirmationDialog ref={dialogRef} />
      <Form ref={formRef} onSubmit={onSubmit}>
        <S.FormRow>
          <Select
            name="companyId"
            label="Armazém"
            options={companyOptions}
            isLoading={loadingCompanies}
            onChange={onWarehouseChange}
          />
          <Select
            name="dockId"
            label="Doca"
            options={dockOptions}
            isLoading={loadingDocks}
            isDisabled={!generalState.companyId}
          />
        </S.FormRow>

        <S.FormRow>
          <Input name="startDate" label="Data de início" type="date" />
          <Input name="endDate" label="Data de término" type="date" />
          <Input
            name="startTime"
            label="Hora de início"
            type="time"
            step={TIME_STEP}
            onBlur={onTimeBlur}
          />
          <Input
            name="endTime"
            label="Hora de término"
            type="time"
            step={TIME_STEP}
            onBlur={onTimeBlur}
          />
        </S.FormRow>

        <S.FormActions>
          <S.LinkButton mood="light" to="/configuracoes/bloqueio-de-horarios">
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit">
            {updatingInterval ? <S.ActivityIndicator /> : 'Salvar'}
          </S.Button>
          <S.Button
            mood="danger"
            type="button"
            style={{ marginLeft: 'auto' }}
            onClick={handleDelete}
          >
            {deletingInterval ? <S.ActivityIndicator /> : 'Remover'}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.Container>
  );
};
