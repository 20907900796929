import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { UpdateFormType, updateFormSchema } from "../../formSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem } from "components/ui/form";
import { InputGs, SelectGs } from "components/ui/Forms";
import { useMutation, useQuery } from "react-query";
import { useErrorHandler } from "Exceptions/ErrorHandler";
import { useNavigate, useParams } from "react-router-dom";
import { Carrier } from "contracts/Carriers";
import { getCompanies,getClients, getCarriers } from 'hooks/api/Users/Options'
import { Company } from "contracts/Companies";
import { Button } from "components/ui/ButtonGs";
import { notify } from "services";
import { ActivityIndicator } from "styles/components";
import { showUser } from "hooks/api/Users/showUser";
import { User } from "contracts/Users";
import MultipleSelector from "components/ui/multi-select";
import { updateUser } from "hooks/api/Users/updateUser";
import { Client } from "contracts/Client";

interface SelectOption {
  name: string;
  value: string;
  label: string
}
interface OptionsType {
  [key: string]: SelectOption[];
}
type UserTypes = "carrierMember" | "warehouseMember" | "admin" | "clientMember";
const userOptions = [
  {
    name: "Usuário de transportadora",
    value: "carrierMember",
  },
  {
    name: "Usuário de armazém",
    value: "warehouseMember",
  },
  {
    name: "Usuário de cliente",
    value: "clientMember",
  },
  {
    name: "Administrador",
    value: "admin",
  },
];

const fieldNameDict: Record<string, string> = {
  carrierMember: "carrierId",
  warehouseMember: "companyId",
  clientMember: "clientId",
};

const UpdateUsuarioForm = () => {
  const { errorHandler } = useErrorHandler();
  const navigate = useNavigate();
  const { id } = useParams()
  const [userType, setUserType] = useState<UserTypes | null>(null);
  const { data: carriers } = useQuery<Carrier[]>({
    queryKey: "carriers",
    queryFn: getCarriers,
    onError: errorHandler,
  });
  const { data: companies } = useQuery<Company[]>({
    queryKey: "companies",
    queryFn: getCompanies,
    onError: errorHandler,
  });
  const { data: clients } = useQuery<Client[]>({
    queryKey: "clients",
    queryFn: getClients,
    onError: errorHandler,
  });
  const {data:user,isLoading:userLoading} = useQuery<User>({
      queryKey: "user",
      queryFn: () => showUser(id!),
      onError: errorHandler,
    });
  const form = useForm<UpdateFormType>({
    resolver: zodResolver(updateFormSchema),
  });
  const options: OptionsType = useMemo(() => {
    if (!carriers || !companies || !clients)
      return { carrierMember: [], warehouseMember: [], clientMember: [] };
    return {
      carrierMember: carriers.map((item) => ({
        name: item.tradeName,
        value: `${item.id}`,
        label: item.tradeName,
      })),
      warehouseMember: companies.map((item) => ({
        name: item.tradeName,
        value: `${item.id}`,
        label: item.tradeName,
      })),
      clientMember: clients.map((item) => ({
        name: item.tradeName,
        label: item.tradeName,
        value: `${item.id}`,
      })),
    };
  }, [companies, carriers, clients]);

  const mutation = useMutation(updateUser, {
    onSuccess: () => {
      notify("success", "Usuário atualizado com sucesso");
      navigate(-1);
    },
    onError: (error) => {
      errorHandler(error);
    },
  });
  function handleSubmit(data: UpdateFormType) {
    const field = fieldNameDict[userType as keyof typeof fieldNameDict] as "carrierId" | "companyId"
    let formatData = {
        ...data,
        [field]: data[field]?.map(company => company.value) ?? []
    };
    mutation.mutate({data: formatData, id: id!});
  }


//   const onChangeMultiSelector = (e: Option[]) => {
//    const opt = e.filter((item) => item.value)
//   form.setValue(fieldNameDict[userType as keyof typeof fieldNameDict] as keyof UpdateFormType, opt.map((item) => item.value));
//   }

  useEffect(() => {
    if(user) {
        setUserType(user.type as UserTypes);
        form.setValue("type", user.type! as UserTypes);
        form.setValue("user.name", user.name);
        form.setValue("user.email", user?.email);
        if(user.type !== 'admin') {
          const userType = user.type!;
          const fieldType = fieldNameDict[userType];
          form.setValue(fieldType as unknown as any, user[userType].map((item: any) => ({ value: item.id, label: item.tradeName})));
        }
    }
  }, [user, form])

  return (
    <Form {...form}>
      <>
      {
        userLoading ? <ActivityIndicator/> :
        <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="grid grid-cols-4 gap-4"
      >
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => {
            return (
              <SelectGs
                {...field}
                onValueChange={(e: UserTypes) => {
                  setUserType(e);
                  form.setValue("type", e);
                }}
                
                label="Tipo de usuário"
                content={userOptions}
              />
            )
          }}
        />
        <FormField
          control={form.control}
          name="user.name"
          render={({ field }) => {
            return <InputGs {...field} label="Nome do usuário" />;
          }}
        />
        <FormField
          control={form.control}
          name="user.email"
          render={({ field }) => {
            return <InputGs {...field} label="E-mail" />;
          }}
        />
        <InputGs disabled label="Senha" />
        <div className="col-span-full">
        {userType && userType !== "admin" && (
            <FormField
              control={form.control}
              name={fieldNameDict[userType] as any}
              render={({ field }) => {
                return (
                  <FormItem {...field}>
                    <FormControl {...field}>
                    <>
                        <label className="font-GilroySemibold text-sm text-slate-800">
                          Selecione...
                        </label>
                      <MultipleSelector
                        {...field}
                        // onChange={onChangeMultiSelector}
                        options={
                          options[userType] as unknown as {
                            label: string;
                            value: string;
                          }[]
                        }
                        inputProps={{
                          autoFocus: false,
                          ref: field.ref,
                        }}
                      />
                      </>
                    </FormControl>
                  </FormItem>
                );
              }}
            />
          )}
        </div>
        <div className="col-span-4">
          <div className="flex gap-4 justify-end">
            <Button.Root
              className="bg-orange-100 text-orange-600"
              size="sm"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button.Root>
            <Button.Root className="bg-orange-500" size="sm" type="submit">
              {mutation.isLoading ? <ActivityIndicator /> : "Salvar"}
            </Button.Root>
          </div>
        </div>
      </form>
      }
      </>
     
    </Form>
  );
};

export default UpdateUsuarioForm;
