import * as S from './styles';
import * as C from '../../Constants';
import type { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { SelectOption } from 'contracts/Common';
import { useEffect, useState } from 'react';
import { addHours, format } from 'date-fns';

import { useCarriers, useCompanies, useVehicleSetups } from 'hooks';
import { Order } from 'contracts/Orders';

interface ILoad {
  formRef: React.RefObject<FormHandles>;
  data: Order | null;
}

interface ICheckState {
  performance?: boolean;
  escort?: boolean;
}

interface ISelectedState {
  carrierId?: number | string | null;
  weightCapacity?: number;
  loadDuration?: number;
}

export const Load = ({ formRef, data }: ILoad) => {
  const [checks, setChecks] = useState<ICheckState>({
    performance: true,
  });
  const [selects, setSelects] = useState<ISelectedState>();
  const [optimalOutputTime, setOptimalOutputTime] = useState<
    string | undefined
  >();

  const editDriverDisabled = Boolean(data?.vehicleArrivalCompany);

  const {
    fetchVehicleSetups,
    vehicleSetups,
    vehicleSetupOptions,
    loadingVehicleSetups,
  } = useVehicleSetups();
  const { fetchCompanies, companyOptions, loadingCompanies } = useCompanies();
  const { fetchCarriers, carrierOptions, loadingCarriers } = useCarriers();

  const onEscoltaChange = (value: boolean) => {
    const fieldsToClear = ['GENumber', 'escortRequestTime'];
    setChecks((prev) => ({ ...prev, escort: value }));
    fieldsToClear.forEach((field) => formRef.current?.clearField(field));
  };

  const onPerformanceChange = (value: boolean) => {
    const fieldsToClear = [
      'responsibleCompany',
      'reasonCompany',
      'ncProcessCompany',
    ];
    setChecks((prev) => ({ ...prev, performance: value }));
    fieldsToClear.forEach((field) => formRef.current?.clearField(field));
  };

  const onCarrierChange = (option: SelectOption | null) => {
    setSelects((prev) => ({ ...prev, carrierId: option?.value }));
  };

  const onVehicleSetupChange = (option: SelectOption | null) => {
    const vehicle = vehicleSetups.find(({ id }) => id === option?.value);

    setSelects((prev) => ({
      ...prev,
      weightCapacity: vehicle?.weightCapacity,
      loadDuration: vehicle?.loadDuration,
    }));
  };

  const onCarregamentoChange = ({ target: { value } }: any) => {
    const hoursToAdd = 2;
    const dateFormat = 'yyyy-MM-dd HH:mm';

    const optimalOutput = format(
      addHours(new Date(value), hoursToAdd),
      dateFormat
    );
    setOptimalOutputTime(optimalOutput);
  };

  const onDataLoad = () => {
    setChecks((prev) => ({
      ...prev,
      escort: Boolean(data?.escortRequired),
      performance: Boolean(data?.withinTimeframeCompany),
    }));
  };

  useEffect(() => {
    fetchVehicleSetups({ excludeBlocked: true });
    fetchCompanies();
    fetchCarriers();
  }, []);

  useEffect(() => {
    onDataLoad();
  }, [data]);

  return (
    <>
      <Form ref={formRef} onSubmit={() => {}}>
        <S.Panel>
          <S.FormRow>
            <S.Input name="vehicleCode" label="Id Veículo" disabled />
            <S.Select
              name="vehicleSetupId"
              label="Setup de veículo"
              options={vehicleSetupOptions}
              isLoading={loadingVehicleSetups}
              onChange={onVehicleSetupChange}
              isDisabled
            />
            <S.Select
              name="fleetType"
              label="Tipo de Frota"
              options={C.optionsFleet}
              isDisabled
            />
            <S.Select
              name="companyId"
              label="Armazém Origem"
              options={companyOptions}
              isLoading={loadingCompanies}
              isDisabled
            />
          </S.FormRow>
          <S.FormRow>
            <S.Select
              name="carrierId"
              label="Transportadora"
              options={carrierOptions}
              isLoading={loadingCarriers}
              onChange={onCarrierChange}
              isDisabled
            />
            <S.Input
              name="driverName"
              label="Nome Motorista"
              disabled={editDriverDisabled}
            />
            <S.MaskedInput
              name="driverDocument"
              label="CPF Motorista"
              mask={'999.999.999-99'}
              disabled={editDriverDisabled}
            />
            <S.Input
              name="schedulingDate"
              label="Data Agendada"
              type="datetime-local"
              min={C.MIN_DATETIME}
              disabled
            />
            <S.Input
              name="plannedDepartureTime"
              label="Saída Planejada"
              type="datetime-local"
              min={C.MIN_DATETIME}
              disabled
            />
          </S.FormRow>
          <S.FormRow>
            <S.Input
              name="expectedLoadingTime"
              label="Horário esperado carregamento"
              type="datetime-local"
              min={C.MIN_DATETIME}
              onChange={onCarregamentoChange}
              disabled
            />
            <S.Input
              name="materialSeparationTime"
              label="Horário separação material"
              type="datetime-local"
              min={C.MIN_DATETIME}
            />
            <S.Input
              name="deliveryDocumentationTime"
              label="Entrega Documentação"
              type="datetime-local"
              min={C.MIN_DATETIME}
            />
            <S.Input
              name="optimalOutputTime"
              label="Saída ideal"
              type="datetime-local"
              value={optimalOutputTime}
              disabled
            />
          </S.FormRow>
          <S.FormRow>
            <S.FlipFlopCheckbox
              name="withinTimeframeCompany"
              label="Atendeu a performance de Carga"
              onChange={onPerformanceChange}
              defaultChecked="Y"
            />
            <S.Input
              name="responsibleCompany"
              label="Responsável"
              disabled={checks?.performance}
            />
            <S.Input
              name="reasonCompany"
              label="Motivo Macro"
              disabled={checks?.performance}
            />
            <S.Input
              name="ncProcessCompany"
              label="Houve alguma NC no processo"
              disabled={checks?.performance}
            />
          </S.FormRow>
          <S.FormRow>
            <S.FlipFlopCheckbox
              name="escortRequired"
              label="Necessário escolta"
              onChange={onEscoltaChange}
              defaultChecked="N"
              disabled={Boolean(data?.escortRequired) || undefined}
            />
            <S.Input
              name="escortRequestTime"
              label="Horário Solic. escolta"
              type="datetime-local"
              min={C.MIN_DATETIME}
              disabled={!checks?.escort}
            />
            <S.Input name="geNumber" label="Nº GE" disabled={!checks?.escort} />
            <div />
          </S.FormRow>
          <S.FormRow>
            <S.Textarea
              name="observation"
              label="Observações"
              style={{ resize: 'none' }}
              disabled
            />
            <S.Textarea
              name="material"
              label="Materiais"
              style={{ resize: 'none' }}
              disabled
            />
          </S.FormRow>
        </S.Panel>
      </Form>
    </>
  );
};
