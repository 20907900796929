import { OrderStatus } from 'contracts/Orders';
import styled, { css } from 'styled-components';
import {
  BasePaginatorHeader,
  BasePaginatorListItem,
  EyeIcon,
  RefreshIcon,
  PencilIcon,
  ChevronRightCircleOutlineIcon,
} from 'styles/components';
import { Colors, Fonts, OrderStatusColor } from 'styles/constants';
import * as HoverCard from '@radix-ui/react-hover-card';
export {
  ActionButton,
  LinkActionButton,
  PaginatorActionsColumn as ActionsColumn,
  PaginatorColumn as Column,
  EditAltIcon as IconEdit,
  RefreshIcon,
  ButtonIcon,
} from 'styles/components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChevronIcon = styled(ChevronRightCircleOutlineIcon)`
  width: 22px;
  height: 22px;
`
export const EditIcon = styled(PencilIcon)`
  width: 20px;
  height: 20px;
`;

export const UpdateIcon = styled(RefreshIcon)`
  width: 20px;
  height: 20px;
`;

const baseGridCss = css`
  grid-template-columns: 64px // order number
    100px // status
    64px // items count
    110px // scheduledAt
    140px // dock
    100px // weight
    120px // value
    140px // vehicle type
    70px // vehicle plate
    180px // company
    auto // carrier
    minmax(0px, max-content); // actions

  @media screen and (max-width: 1880px) {
    grid-template-columns: 64px // order number
      100px // status
      110px // scheduledAt
      140px // dock
      140px // vehicle type
      70px // vehicle plate
      180px // company
      auto // carrier
      minmax(0px, max-content); // actions
    > div:nth-child(3),
    > div:nth-child(6),
    > div:nth-child(7) {
      display: none;
    }
  }

  @media screen and (max-width: 1500px) {
    grid-template-columns: 64px // order number
      100px // status
      110px // scheduledAt
      140px // dock
      180px // company
      auto // carrier
      minmax(0px, max-content); // actions
    > div:nth-child(8),
    > div:nth-child(9) {
      display: none;
    }
  }
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
`;

// Status Label

const applyStatusColor = (status?: OrderStatus) => {
  switch (status) {
    case 'aguardando':
      return css`
        background-color: ${OrderStatusColor.Aguardando};
      `;
    case 'chegada':
      return css`
        background-color: ${OrderStatusColor.Chegada};
      `;
    case 'iniciado':
      return css`
        background-color: ${OrderStatusColor.Iniciado};
      `;
    case 'finalizado':
      return css`
        background-color: ${OrderStatusColor.Finalizado};
      `;
    case 'liberado':
      return css`
        background-color: ${OrderStatusColor.Liberado};
      `;
    case 'noShow':
      return css`
        background-color: ${OrderStatusColor.NoShow};
      `;
    case 'cancelado':
      return css`
        background-color: ${OrderStatusColor.Cancelado};
      `;
    default:
      return css``;
  }
};

interface IStatusLabel {
  status?: OrderStatus;
}

export const StatusLabel = styled.label<IStatusLabel>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
  padding: 4px 8px;
  font-family: ${Fonts.GilroyBold};
  font-size: 12px;
  border-radius: 4px;
  color: ${Colors.White};
  background-color: ${Colors.Blue};
  ${({ status }) => applyStatusColor(status)}
`;

export const Eye = styled(EyeIcon)`
  color: ${Colors.Gray50};
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
`;

export const HoverCardArrow = styled(HoverCard.Arrow)`
  fill: ${Colors.Gray70};
`;

export const HoverCardContent = styled(HoverCard.Content)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 6px;
  background: ${Colors.Gray70};
  width: 100%;
  max-width: 400px;
  z-index: 1;

  p {
    font-family: ${Fonts.GilroySemiBold};
    font-size: 14px;
    color: ${Colors.Gray10};
  }
`;
