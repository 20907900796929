import { DataTable } from 'components/ui/DataTable';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import { ListOldOrdersActions } from 'store/ducks/orders';
import { OldListProps, useColumns } from './columns';

interface Props extends OldListProps {
  orderCode?: string;
}

export const OldList = ({ orderCode, onRowClick }: Props) => {
  const dispatch = useDispatch();
  const { columns } = useColumns({ onRowClick });

  const { data } = useSelector(
    (state: RootState) => state.listOldOrders
  );

  useEffect(() => {
    orderCode && dispatch(ListOldOrdersActions.request({ code: orderCode }));
  }, [orderCode]);

  return (
    <div className="mt-5">
      <DataTable columns={columns} data={data || []} />
    </div>
  );
};
