import { Order } from 'contracts/Orders';
import { differenceInMinutes, isPast, isToday } from 'date-fns';
import { Validator } from 'utils';
import * as Yup from 'yup';

interface Props extends Order {
  canEditDriver: boolean;
}

const testDate: any = {
  name: 'testDate',
  test: function (value: any) {
    try {
      if (!value) {
        throw new Error('Data inválida');
      }

      if (isPast(value) && !isToday(value)) {
        throw new Error('A data não pode estar no passado');
      }

      return true;
    } catch (error: any) {
      return this.createError({
        message: error?.message,
      });
    }
  },
};

const testOptionalDate: any = {
  name: 'testOptionalDate',
  test: function (value: any) {
    try {
      if (!value) return true;

      if (isPast(value) && !isToday(value)) {
        throw new Error('A data não pode estar no passado');
      }

      return true;
    } catch (error: any) {
      return this.createError({
        message: error?.message,
      });
    }
  },
};

export class RescheduleOrderValidator {
  constructor(private props: Props) {}

  public get schema() {
    const requiredMessage = 'Campo obrigatório';
    return {
      loadSchema: Yup.object().shape({
        // parent relationships
        vehicleCode: Yup.string()
          .typeError(requiredMessage)
          .required(requiredMessage),
        vehicleSetupId: Yup.number()
          .typeError(requiredMessage)
          .required(requiredMessage),
        fleetType: Yup.string()
          .typeError(requiredMessage)
          .required(requiredMessage),
        companyId: Yup.number()
          .typeError(requiredMessage)
          .required(requiredMessage),
        carrierId: Yup.number()
          .typeError(requiredMessage)
          .required(requiredMessage),
        driverName: Yup.string()
          .required(requiredMessage)
          .typeError(requiredMessage),
        driverDocument: Yup.string()
          .required(requiredMessage)
          .typeError(requiredMessage)
          .test({
            name: 'testDocument',
            test(value = '') {
              try {
                Validator.validateDocument(value, 'cpf');
                return true;
              } catch (error: any) {
                return this.createError({
                  message: error?.message,
                });
              }
            },
          }),
        plannedDepartureTime: Yup.date()
          .typeError(requiredMessage)
          .test({
            test: (value, { createError }) => {
              const maxDiffMinutes = 2 * 60; /**2h */

              try {
                if (!value) throw new Error(requiredMessage);

                Validator.dateIsPast(value);

                if (
                  differenceInMinutes(
                    new Date(this.props.schedulingDate),
                    value
                  ) > maxDiffMinutes
                ) {
                  throw new Error('Diferença maior que 2 horas');
                }

                if (
                  differenceInMinutes(
                    new Date(this.props.schedulingDate),
                    value
                  ) < 0
                ) {
                  throw new Error('Saída posterior ao agendamento');
                }

                return true;
              } catch (error: any) {
                return createError({
                  message: error?.message,
                });
              }
            },
          }),
        schedulingDate: Yup.date().typeError(requiredMessage).test(testDate),
        expectedLoadingTime: Yup.date()
          .typeError(requiredMessage)
          .test(testDate),
        optimalOutputTime: Yup.date().typeError(requiredMessage).test(testDate),
        observation: Yup.string()
          .nullable()
          .transform((value: string) => {
            if (!value) return null;
            return value.trim();
          }),
        material: Yup.string()
          .nullable()
          .transform((value: string) => {
            if (!value) return null;
            return value.trim();
          }),
        withinTimeframeCompany: Yup.string().typeError(requiredMessage),
        ...(this.props.canEditDriver && {
          driverName: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
          driverDocument: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage)
            .test({
              name: 'testDocument',
              test(value = '') {
                try {
                  Validator.validateDocument(value, 'cpf');
                  return true;
                } catch (error: any) {
                  return this.createError({
                    message: error?.message,
                  });
                }
              },
            }),
        }),
        ...(!this.props.withinTimeframeCompany && {
          responsibleCompany: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
          reasonCompany: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
          ncProcessCompany: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
        }),
        escortRequired: Yup.boolean()
          .required(requiredMessage)
          .typeError(requiredMessage),
        ...(this.props?.escortRequired && {
          escortRequestTime: Yup.date()
            .required(requiredMessage)
            .test(testDate),
          geNumber: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
        }),
        deliveryDocumentationTime: Yup.date().optional().test(testOptionalDate),
        materialSeparationTime: Yup.date().optional().test(testOptionalDate),
      }),
      unloadSchema: Yup.object().shape({
        responsible: Yup.string().optional().typeError(requiredMessage),
        clientId: Yup.string().nullable().optional().typeError(requiredMessage),
        dischargeDocumentationTime: Yup.date()
          .optional()
          .test(testOptionalDate),
        withinTimeframeClient: Yup.string()
          .required(requiredMessage)
          .typeError(requiredMessage),
        ...(!this.props.withinTimeframeClient && {
          responsibleClient: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
          reasonClient: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
          ncProcessClient: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
        }),
      }),
    };
  }
}
