import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';

const { Types, Creators } = createActions(
  {
    request: ['id', 'onSuccess', 'onFailure'],
    success: [],
    failure: ['errorMessage'],
    softReset: [],
    reset: [],
  },
  {
    prefix: 'DELETE_COMPANY_CARRIER_',
  }
);

export interface DeleteCompanyCarrierState {
  id: number | null;
  loading: boolean;
  errorMessage: string | null;
}

export interface DeleteCompanyCarrierRequestAction {
  id: number;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: DeleteCompanyCarrierState = {
  id: null,
  loading: false,
  errorMessage: null,
};

const request = (
  state: DeleteCompanyCarrierState,
  action: DeleteCompanyCarrierRequestAction
) =>
  update(state, {
    id: { $set: action.id },
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (state: DeleteCompanyCarrierState) =>
  update(state, {
    id: { $set: null },
    loading: { $set: false },
  });

const failure = (state: DeleteCompanyCarrierState, action: FailureAction) =>
  update(state, {
    id: { $set: null },
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: DeleteCompanyCarrierState) =>
  update(state, {
    id: { $set: null },
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const deleteCompanyCarrier = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const DeleteCompanyCarrierTypes = Types;
export const DeleteCompanyCarrierActions = Creators;
