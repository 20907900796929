import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CancelationModal,
  CancelationModalRef,
  CancelationReasonAlert,
  DriverVehicleRef,
  EventsManager,
  InfoGrid,
  MainGrid,
  OrderFiles,
  OrderItems,
  UpdateDriverAndVehicleModal,
  WarehouseGrid,
} from './components';
import {
  FetchOrderActions as FetchActions,
  UpdateOrderNoShowActions as NoShowActions,
} from 'store/ducks/orders';
import { ConfirmationDialog, ConfirmationDialogRef } from 'components/Shared';
import { Modal, ModalCloseButton } from 'components/Shared/Modal';
import * as Tabs from 'components/Shared/Tabs';
import { useAuth } from 'hooks';
import * as S from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { Formatter } from 'utils';

export interface Ref {
  selectOrder: (orderId: number) => void;
}

interface Props {
  onUpdate?: () => void;
}

interface IOrderModal
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

/**
 * Only admins and company members can set events, No show and cancel orders
 *
 * Only admins, carrier members and company members can update
 * driverName, driverDocument and vehiclePlate if the vehicle did not arrive.
 *
 * Any other users will only see the order details.
 */

const OrderModal: IOrderModal = forwardRef<Ref, Props>((props, ref) => {
  const { onUpdate } = props;
  const dispatch: AppDispatch = useDispatch();
  const { userBelongsToAnyOf } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<number | null>(null);
  const tabsOutletRef = useRef<Tabs.Ref>(null);
  const dialogRef = useRef<ConfirmationDialogRef>(null);
  const cancelationModalRef = useRef<CancelationModalRef>(null);
  const updateDriverAndVehicleModalRef = useRef<DriverVehicleRef>(null);

  const { data: order, loading: loadingOrder } = useSelector(
    (state: RootState) => state.fetchOrder
  );

  const { loading: updatingNoShow } = useSelector(
    (state: RootState) => state.updateOrderNoShow
  );

  const isNoShowLocked = useMemo((): boolean => {
    if (!order) return true;

    const { vehicleArrivalCompany, noShowAt, cancelationReason } = order;

    return !!vehicleArrivalCompany || !!noShowAt || !!cancelationReason;
  }, [order]);

  const isCancelationLocked = useMemo((): boolean => {
    if (!order) return true;

    const { status, orderStartedCompany, noShowAt, cancelationReason } = order;

    if (
      status === 'iniciado' &&
      userBelongsToAnyOf('admin', 'warehouseMember')
    ) {
      return false;
    }

    return !!orderStartedCompany || !!noShowAt || !!cancelationReason;
  }, [order, userBelongsToAnyOf]);

  const fetchOrder = useCallback((): void => {
    if (!orderId) return;
    dispatch(FetchActions.request(orderId));
  }, [dispatch, orderId]);

  const onCloseModal = useCallback((): void => {
    setOrderId(null);
    setIsOpen(false);
    dispatch(FetchActions.reset());
  }, [dispatch]);

  const onEventsUpdate = useCallback((): void => {
    onUpdate && onUpdate();
    fetchOrder();
  }, [fetchOrder, onUpdate]);

  const onNoShowUpdate = useCallback((): void => {
    onUpdate && onUpdate();
    fetchOrder();
  }, [fetchOrder, onUpdate]);

  const onCancelationReasonUpdate = useCallback((): void => {
    onUpdate && onUpdate();
    fetchOrder();
  }, [fetchOrder, onUpdate]);

  const onDriverAndVehicleUpdate = useCallback((): void => {
    onUpdate && onUpdate();
    fetchOrder();
  }, [fetchOrder, onUpdate]);

  const onNoShow = useCallback(async (): Promise<void> => {
    if (order?.vehicleArrivalCompany) return;

    const confirmed = await dialogRef.current?.openDialog({
      title: 'Confirmar no show do veículo?',
      message: 'Esta ação não poderá ser desfeita.',
    });

    if (confirmed) {
      const data = {
        noShowAt: new Date().toISOString(),
      };
      dispatch(NoShowActions.request(orderId, data, onNoShowUpdate));
    }
  }, [dispatch, onNoShowUpdate, order?.vehicleArrivalCompany, orderId]);

  const ModalHeader = useCallback((): JSX.Element => {
    const orderNumber = orderId ? `${orderId}`.padStart(4, '0') : '';

    return (
      <S.ModalHeader>
        <S.HeaderTitle>
          <S.PackageIcon />
          <h4>Ordem {orderNumber}</h4>
          {loadingOrder && <S.ActivityIndicator />}
        </S.HeaderTitle>

        <S.HeaderCenter>
          {!!order?.status && (
            <S.StatusLabel status={order.status as any}>
              {Formatter.orderStatusName(order.status as any)}
            </S.StatusLabel>
          )}
          {order?.orderReader && (
            <S.Reader>
              Lido por {order?.orderReader.user.name} <br /> em{' '}
              {Formatter.date(order?.orderReader.createdAt, {
                format: 'dd/MM/yyyy HH:mm',
              })}
            </S.Reader>
          )}
        </S.HeaderCenter>
        <S.HeaderButtons>
          {/* if order.status is ready, the entire order is also, so show the button */}
          {!!order?.status && (
            <S.PrintButton
              mood="void"
              size="small"
              target="_blank"
              to={`/agendamento/${orderId}/impressao`}
            >
              <S.PrinterIcon />
            </S.PrintButton>
          )}
        </S.HeaderButtons>
        <ModalCloseButton onClick={onCloseModal} />
      </S.ModalHeader>
    );
  }, [orderId, loadingOrder, order?.status, onCloseModal]);

  useImperativeHandle(
    ref,
    () => ({
      selectOrder: (orderId: number): void => {
        setOrderId(orderId);
        setIsOpen(true);
      },
    }),
    []
  );

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  return (
    <Modal isOpen={isOpen} onClickOutside={onCloseModal}>
      <ConfirmationDialog ref={dialogRef} />
      {!!orderId && (
        <CancelationModal
          orderId={orderId}
          ref={cancelationModalRef}
          onUpdate={onCancelationReasonUpdate}
        />
      )}

      {!!order && (
        <UpdateDriverAndVehicleModal
          order={order}
          ref={updateDriverAndVehicleModalRef}
          onUpdate={onDriverAndVehicleUpdate}
        />
      )}
      <S.ModalContent style={{ maxWidth: '960px' }}>
        <ModalHeader />
        <S.ModalBody>
          <MainGrid order={order} />
          <InfoGrid order={order} />
          <WarehouseGrid order={order} />
          <CancelationReasonAlert order={order} />
          {!!order && (
            <S.TabsContainer>
              <Tabs.Controller
                onTabChange={(i) => tabsOutletRef.current?.selectTab(i)}
              >
                <Tabs.ModalTab title="Apontamento" />
                <Tabs.ModalTab title="Itens do agendamento" />
                {order.orderFiles && order.orderFiles.length > 0 && (
                  <Tabs.ModalTab title="Documentos" />
                )}
              </Tabs.Controller>
              <Tabs.Outlet ref={tabsOutletRef}>
                <EventsManager onUpdate={onEventsUpdate} order={order} />
                <OrderItems items={order.orderItems} />
                {order.orderFiles && order.orderFiles.length > 0 && (
                  <OrderFiles files={order.orderFiles} />
                )}
              </Tabs.Outlet>
            </S.TabsContainer>
          )}

          {userBelongsToAnyOf(
            'admin',
            'warehouseMember',
            'carrierMember',
            'companyMember'
          ) && (
            <S.FormActions>
              {userBelongsToAnyOf('admin', 'warehouseMember') && (
                <>
                  <S.Button
                    mood="warning"
                    disabled={isNoShowLocked}
                    onClick={onNoShow}
                  >
                    {updatingNoShow ? <S.ActivityIndicator /> : 'No show'}
                  </S.Button>

                  <S.Button
                    mood="danger"
                    disabled={isCancelationLocked}
                    onClick={() => cancelationModalRef.current?.openModal()}
                  >
                    Cancelar ordem
                  </S.Button>
                </>
              )}
            </S.FormActions>
          )}
        </S.ModalBody>
      </S.ModalContent>
    </Modal>
  );
});

export default OrderModal;
