export * from './Selects';

export enum OperationTypeEnum {
  DEVOLUCAO = 1,
  EXPORTACAO = 2,
  IMPORTACAO = 3,
  INSUMO = 4,
  TRANSFERENCIA = 5,
  VENDA = 6,
}

export enum OrderTypeEnum {
  CARREGAMENTO = 1,
  DESCARREGAMENTO = 2,
}

/** How many days to show when picking a date */
export const SCHEDULING_DATE_PICKER_INTERVAL = 15;

export const FORM_BACK_ACTION = 'Voltar';
export const MODAL_DISMISS_ACTION = 'Fechar';
export const EMPTY_COLUMN_VALUE = '---';
