import type { Ref as ItemsRef } from './OrderItems';
import type { FormHandles } from '@unform/core';
import { useEffect, useRef } from 'react';
import * as S from './styles';
import { FormPageHeader } from 'components/Shared';
import * as Tabs from 'components/Shared/Tabs';
import { FilesRef, Load } from './Forms/Load';
import { Unload } from './Forms/Unload';
import { CreateOrderValidator } from 'validators/Orders/CreateOrder';
import { useValidation } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { CreateOrderActions } from 'store/ducks/orders';
import { Loading } from 'components/Shared/Loading';
import { useNavigate } from 'react-router-dom';
import { Cleaner } from 'utils';
import { notify } from 'services';

export const OrderCreationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiErrors } = useValidation();

  const { loading, validationErrors } = useSelector(
    (state: RootState) => state.createOrder
  );

  const loadRef = useRef<FormHandles>(null);
  const unloadRef = useRef<FormHandles>(null);
  const itemsRef = useRef<ItemsRef>(null);
  const filesRef = useRef<FilesRef>(null);
  const tabsRef = useRef<Tabs.Ref>(null);

  const { handleFormErrors } = useValidation();

  const handleSubmit = async () => {
    if (loading) return;

    const loadData = loadRef.current?.getData() as any;
    const unloadData = unloadRef.current?.getData();
    const items = itemsRef.current?.getItems();
    const documents = filesRef.current?.getFiles();

    loadRef.current?.setErrors({});
    unloadRef.current?.setErrors({});

    const allData = Cleaner.removeEmptyFields({
      ...loadData,
      ...unloadData,
      documents,
      items,
    });

    let hasError: any = null;

    const abort = {
      abortEarly: false,
    };

    const {
      schema: { loadSchema, unloadSchema },
    } = new CreateOrderValidator(loadData);

    await loadSchema.validate(allData, abort).catch((error) => {
      hasError = true;
      handleFormErrors(error, loadRef);
    });

    await unloadSchema.validate(allData, abort).catch((error) => {
      hasError = true;
      handleFormErrors(error, unloadRef);
    });

    if (hasError) return;

    if (!items?.length) return notify('error', 'Adicione items ao agendamento');

    try {
      dispatch(
        CreateOrderActions.request(allData, () => navigate(`/agendamento`))
      );
    } catch (error: any) {
      handleApiErrors(error, loadRef);
      handleApiErrors(error, unloadRef);
    }
  };

  useEffect(() => {
    handleApiErrors(validationErrors, loadRef);
    handleApiErrors(validationErrors, unloadRef);
  }, [validationErrors]);

  return (
    <S.Container>
      <S.Panel>
        <FormPageHeader
          title="Novo agendamento"
          icon={<S.CalendarIcon />}
          showBorder={false}
          removeSpacing={true}
          actions={
            <>
              <S.LinkButton
                size="small"
                to={'/agendamento'}
                style={{ pointerEvents: 'auto' }}
              >
                <S.ArrowLeftIcon /> Voltar
              </S.LinkButton>
              <S.Button
                size="small"
                mood="secondary"
                className="p-0"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <Loading color="white" /> : <S.CheckIcon />} Salvar
              </S.Button>
            </>
          }
        />
        <Tabs.Controller onTabChange={(i) => tabsRef?.current?.selectTab(i)}>
          <Tabs.ModalTab title="Carregamento" />
          <Tabs.ModalTab title="Descarregamento" />
        </Tabs.Controller>
        <Tabs.Outlet ref={tabsRef}>
          <Load formRef={loadRef} itemsRef={itemsRef} filesRef={filesRef} />
          <Unload formRef={unloadRef} />
        </Tabs.Outlet>
      </S.Panel>
    </S.Container>
  );
};
