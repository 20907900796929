import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  UpdateOrderActions as Actions,
  UpdateOrderRequestAction as RequestAction,
} from 'store/ducks/orders';

export function* updateOrderRequest(action: any) {
  const { id, postData, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.put, `orders/${id}`, postData);
    onSuccess && onSuccess();
    notify('success', 'Ordem atualizada com sucesso');
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify('error', errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
