import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import { Input, Select } from "components/Shared";
import { DialogContent } from "components/Shared/Dialog";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Status } from "components/Pages/Invoices";

import * as S from "./styles";
import { Formatter } from "utils";
import { UpdateInvoiceJustificationsPayload } from "contracts/Invoice";
import { JUSTIFICATION_TYPES, useAuth, useJustifications } from "hooks";
import { CreateManyInvoiceJustificationsActions as MainActions } from "store/ducks/invoiceJustifications";
import { DeliveredContext } from "contexts/DeliveredContext";
import { differenceInDays, parse } from 'date-fns';
import { usePermission } from "hooks/usePermission";
import { notify } from "services";

interface FormData {
  justificationId: number;
  description?: string | null;
  returnDate?: string
  sapCode?: string
}

const AddJustificationsModal = () => {
  const {
    refModal,
    selectedList,
    onQueryChange,
    clearSelectList,
    changeOpenModal,
  } = useContext(DeliveredContext);
  const formRef = useRef<FormHandles>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const { hasPermissionTo } = usePermission()
  const isCarrierMember = hasPermissionTo(['CARRIER-MEMBERS.FINDMEMBERS', 'CARRIER-MEMBERS.FINDMEMBER'])
  const isAdmin =  hasPermissionTo(['ADMINS.FINDADMIN'])

  const { justificationOptions, fetchJustifications, loadingJustifications } =
    useJustifications();

  const { loading } = useSelector(
    (state: RootState) => state.updateInvoiceStatus
  );

  const formattedList = useMemo(() => {
    return selectedList.map((invoice) => ({
      id: invoice.id,
      nf: `${invoice.infnfeIdeNnf}-${invoice.infnfeIdeSerie}`,
      dhEmi: Formatter.date(invoice.infnfeIdeDhemi, {
        format: "dd/MM/yyyy HH:mm",
      }),
      prev: invoice.deadlineDate
        ? Formatter.date(invoice.deadlineDate, { format: "dd/MM/yyyy HH:mm" })
        : "---",
      status:
        invoice.status.length > 0
          ? {
              name: invoice.status[0].name,
              color: invoice.status[0].color,
            }
          : null,
      dtEntrega: invoice.deliveryDate ? Formatter.date(invoice.deliveryDate, { format: "dd/MM/yyyy HH:mm" })
      : "---",
      dtGr: invoice.returnDate ? Formatter.date(invoice.returnDate, { format: "dd/MM/yyyy HH:mm" })
      : "---",
      codSap: invoice.returnDate ? invoice.sapCode : '---',
      nfAtrasada: invoice.isDelayedLeadTime
    }));
  }, [selectedList]);

  const hasSomeDelayed = formattedList.some(invoice => invoice.nfAtrasada)


  const handleSubmit = useCallback<SubmitHandler>(
    (data: FormData) => {
      setIsDisabled(true);
        if(hasSomeDelayed && !data.justificationId) {
          notify('error', 'Selecione uma justificativa.')
          setIsDisabled(false)
          return
        }
        if(data.returnDate || data.sapCode) {
           const hasSameItemsAttr = formattedList.every(invoice => invoice.nfAtrasada === formattedList[0].nfAtrasada)

           if(!hasSameItemsAttr) {
            notify('error', 'Não é permitido selecionar NFs em atraso juntamente com NFs dentro do prazo de Efetivação SAP.')
            return
           }
        }

      
      const payloadData: UpdateInvoiceJustificationsPayload = {
        justificationId: data.justificationId,
        ids: selectedList.map((invoice) => invoice.id),
        description: data.description,
        sapCode: data?.sapCode,
        returnDate: data?.returnDate
      };
     
      dispatch(
        MainActions.request(payloadData, () => {
          onQueryChange({}, true);
          clearSelectList();
          changeOpenModal();
        })
      );
    },
    [changeOpenModal, clearSelectList, dispatch, onQueryChange, selectedList,hasSomeDelayed]
  );

  useEffect(() => {
      fetchJustifications();
    // return () => setIsDisabled(false)
  }, []);

  return (
    <DialogContent
      container={refModal}
      title="Adicionar justificativa nas notas listadas abaixo"
      icon={<S.MessageDetailIcon />}
      style={{ maxWidth: 1000 }}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.Content>
          <S.ListHeader>
            <span>NF</span>
            <span>DT. Emissão</span>
            <span>P. Contratado</span>
            <span>Dt. Entrega</span>
            {!isCarrierMember && (<span>Data GR</span>)}
            {!isCarrierMember && (<span>Cod. SAP</span>)}
            <span>Status</span>
            <span></span>
          </S.ListHeader>
          {formattedList.map((invoice) => (
            <S.ListItem key={invoice.id}>
              <span>{invoice.nf}</span>
              <span>{invoice.dhEmi}</span>
              <span>{invoice.prev}</span>
              <span>{invoice.dtEntrega}</span>
              {!isCarrierMember && (<span>{invoice.dtGr}</span>)}
              {!isCarrierMember && (<span>{invoice.codSap}</span>)}
              <span>
                {invoice.status ? (
                  <Status color={invoice.status.color}>
                    {invoice.status.name}
                  </Status>
                ) : (
                  <Status>Sem status</Status>
                )}
              </span>
              <span>{invoice.dtGr}</span>
              <span>{invoice.codSap}</span>
              {
                invoice.nfAtrasada &&  <span title="Em Atraso Efetivação SAP"><S.ErrorIcon/></span>
              }
            </S.ListItem>
          ))}
        </S.Content>
        <S.Footer>
        {
        hasPermissionTo(['ADMINS.FINDADMIN']) &&   (
            <S.InputWrapper>
                <Input name="returnDate" label="Data GR" type="date"/>
                <Input name="sapCode" label="Código SAP" placeholder="Código SAP"/>
            </S.InputWrapper>
         )   
        }

         <S.InputWrapper>
            <Select
                name="justificationId"
                options={justificationOptions}
                isLoading={loadingJustifications}
                placeholder="Justificativa"
                label="Justificativa"
                onChange={(opt) => setIsDisabled(opt?.value ? false : true)}
                maxMenuHeight={120}
            />
            <Input name="description" label="Comentário" />
            <S.Button type="submit" disabled={(isDisabled || loading) && hasSomeDelayed}>
            {loading ? `Adicionando` : `Adicionar`}
          </S.Button>
         </S.InputWrapper>
        </S.Footer>
      </Form>
    </DialogContent>
  );
};

export { AddJustificationsModal };
