import { combineReducers } from 'redux';

import {
  auth,
  generatePasswordResetToken,
  impersonate,
  login,
  logout,
  resetPassword,
  updateAccount,
} from './auth';
import {
  createAdmin,
  deleteAdmin,
  fetchAdmin,
  paginateAdmins,
  updateAdmin,
} from './admins';
import {
  createSupplier,
  fetchSupplier,
  listSuppliers,
  paginateSuppliers,
  updateSupplier,
} from './suppliers';
import {
  createCompany,
  fetchCompany,
  listCompanies,
  paginateCompanies,
  updateCompany,
} from './companies';
import {
  fetchCollect,
  paginateCollects,
  createCollect,
  fetchDocument,
  exportCollects,
} from './collects';
import {
  createClient,
  fetchClient,
  updateClient,
  listClients,
  paginateClients,
} from './clients';
import {
  fetchAuditor,
  listAuditors,
  paginateAuditors,
  createAuditor,
  updateAuditor,
} from './auditors';
import {
  fetchReason,
  listReasons,
  paginateReasons,
  createReason,
  updateReason,
} from './reasons';
import {
  fetchClassification,
  listClassifications,
  paginateClassifications,
  createClassification,
  updateClassification,
} from './classifications';
import {
  createProduct,
  fetchProduct,
  listProducts,
  paginateProducts,
  updateProduct,
} from './products';
import { exportInvoices } from './exportInvoices';
import {
  fetchOperationType,
  listOperationTypes,
  paginateOperationTypes,
} from './operationTypes';
import {
  createOperationTypesCfop,
  paginateOperationTypesCfop,
  toggleDisableOperationTypeCFOP,
} from './operationsTypesCfop';
import {
  createInvoiceJustification,
  createManyInvoiceJustifications,
  fetchInvoiceJustification,
  listInvoiceJustifications,
  abonedInvoiceJustification,
  abonedInvoiceJustifications
} from './invoiceJustifications';
import {
  createJustification,
  fetchJustification,
  listJustifications,
  paginateJustifications,
  updateJustification,
} from './justifications';
import {
  createJustificationReverseLogistics,
  fetchJustificationReverseLogistics,
  listJustificationsReverseLogistics,
  paginateJustificationsReverseLogistics,
  updateJustificationReverseLogistics,
} from './justificationsReverseLogistics';
import {
  createVehicleType,
  fetchVehicleType,
  listVehicleTypes,
  paginateVehicleTypes,
  updateVehicleType,
} from './vehicleTypes';
import {
  createProductType,
  fetchProductType,
  listProductTypes,
  paginateProductTypes,
  updateProductType,
} from './productTypes';
import {
  createCarrier,
  fetchCarrier,
  listCarriers,
  paginateCarriers,
  updateCarrier,
} from './carriers';
import {
  createCarrierMember,
  deleteCarrierMember,
  fetchCarrierMember,
  paginateCarrierMembers,
  updateCarrierMember,
} from './carrierMembers';
import {
  createWarehouseMember,
  deleteWarehouseMember,
  fetchWarehouseMember,
  paginateWarehouseMembers,
  updateWarehouseMember,
  deleteWarehouseCompanyMember,
} from './warehouseMembers';
import { listCountries } from './countries/listCountries';
import { listCities } from './cities/listCities';
import { listProductUnits } from './productUnits/listProductUnits';

import { paginationParamsCache, warehouseScheduleParamsCache } from './cache';
import { fetchAddressByZipcode } from './addressLookup';
import {
  paginatePendingRelease,
  fetchPendingRelease,
  updateInvoiceStatus,
  updateInvoiceDeadline,
  releaseInvoicesToTracking,
  updateInvoiceCarrier,
  invoiceRecalculate,
  createCorrectionLetter,
  fetchCorrectionLetter,
  disablePendingInvoice,
  updateInvoiceDeliveryDate,
} from './pendingRelease';
import {
  createTransitTime,
  fetchTransitTime,
  listTransitTimes,
  updateTransitTime,
  paginateTransitTimes,
  recalculateTransitTime,
} from './transitTime';
import { paginateReleasedInvoices } from './releasedInvoices';
import {
  paginateDeliveredInvoices,
  updateSAPCodeInvoice,
  ignoreInvoice,
} from './deliveredInvoices';
import { paginateFinishedInvoices } from './finishedInvoices';
import { fetchCarriersList } from './carriers';
import { fetchStatusList } from './status';
import { fetchPublicInvoice } from './publicInvoice';
import { fetchJustificationTypes } from './justificationTypes';
import recallSlice from './leftPanel/recall';
import { createCargoType, fetchCargoType, listCargoTypes, paginateCargoTypes, updateCargoType } from './cargoTypes';
import { fetchOrderType, listOrderTypes } from './orderTypes';
import { listSchedulingDocks, listSchedulingVehicleSetups, listWarehouseScheduleEntries } from './scheduling';
import { createOrder, exportOrders, fetchOrder, listOldOrders, paginateOrders, rescheduleOrder, updateOrder, updateOrderCancelationReason, updateOrderDriverAndVehicle, updateOrderEvents, updateOrderNoShow } from './orders';
import { createCancelationReason, fetchCancelationReason, listCancelationReasons, paginateCancelationReasons, updateCancelationReason } from './cancelationReasons';
import { createDock, fetchDock, listDocks, paginateDocks, updateDock } from './docks';
import { createDockCarrier, deleteDockCarrier } from './dockCarriers';
import { createDockClient, deleteDockClient } from './dockClients';
import { createDockOperationType, deleteDockOperationType } from './dockOperationTypes';
import { createDockReservedInterval, deleteDockReservedInterval, fetchDockReservedInterval, listDockReservedIntervals, paginateDockReservedIntervals, updateDockReservedInterval } from './dockReservedIntervals';
import { createVehicleSetup, fetchVehicleSetup, listVehicleSetups, paginateVehicleSetups, updateVehicleSetup } from './vehicleSetups';
import { createCompanyVehicleSetup, deleteCompanyVehicleSetup, fetchCompanyVehicleSetup, listCompanyVehicleSetups, paginateCompanyVehicleSetups, updateCompanyVehicleSetup } from './companyVehicleSetups';
import { listOrderItemInvoices } from './orderItemInvoices';
import { createCompanyCarrier, deleteCompanyCarrier } from './companyCarriers';
import { createCompanyClient, deleteCompanyClient } from './companyClients';
import { createCompanyVehicleType, deleteCompanyVehicleType } from './companyVehicleTypes';
import { createCompanyOperationType, deleteCompanyOperationType } from './companyOperationTypes';
import { fetchOrderFile } from './orderFiles';

export default combineReducers({
  // auth
  auth,
  generatePasswordResetToken,
  impersonate,
  login,
  logout,
  resetPassword,
  updateAccount,
  // admins
  createAdmin,
  deleteAdmin,
  fetchAdmin,
  paginateAdmins,
  updateAdmin,
  //auditors
  fetchAuditor,
  listAuditors,
  paginateAuditors,
  createAuditor,
  updateAuditor,
  // cache
  paginationParamsCache,
  // carriers
  createCarrier,
  fetchCarrier,
  listCarriers,
  paginateCarriers,
  updateCarrier,
  // carrier members
  createCarrierMember,
  deleteCarrierMember,
  fetchCarrierMember,
  paginateCarrierMembers,
  updateCarrierMember,
  // warehouse members
  createWarehouseMember,
  deleteWarehouseMember,
  fetchWarehouseMember,
  paginateWarehouseMembers,
  updateWarehouseMember,
  deleteWarehouseCompanyMember,
  //classifications
  createClassification,
  fetchClassification,
  listClassifications,
  paginateClassifications,
  updateClassification,
  // companies
  createCompany,
  fetchCompany,
  listCompanies,
  paginateCompanies,
  updateCompany,
  //collects
  fetchCollect,
  paginateCollects,
  createCollect,
  fetchDocument,
  exportCollects,
  //clients
  createClient,
  fetchClient,
  updateClient,
  listClients,
  paginateClients,
  // countries
  listCountries,
  // cities
  listCities,
  // address lookup
  fetchAddressByZipcode,
  // pending release
  paginatePendingRelease,
  fetchPendingRelease,
  updateInvoiceStatus,
  updateInvoiceDeadline,
  updateInvoiceDeliveryDate,
  releaseInvoicesToTracking,
  updateInvoiceCarrier,
  invoiceRecalculate,
  createCorrectionLetter,
  fetchCorrectionLetter,
  disablePendingInvoice,
  // export invoices
  exportInvoices,
  // released invoices
  paginateReleasedInvoices,
  // delivered invoices
  paginateDeliveredInvoices,
  updateSAPCodeInvoice,
  ignoreInvoice,
  // finished invoices
  paginateFinishedInvoices,
  // carriers
  fetchCarriersList,
  // products
  createProduct,
  fetchProduct,
  listProducts,
  paginateProducts,
  updateProduct,
  // product types
  createProductType,
  fetchProductType,
  listProductTypes,
  paginateProductTypes,
  updateProductType,
  //operation types
  fetchOperationType,
  listOperationTypes,
  paginateOperationTypes,
  // invoiceJustification
  createInvoiceJustification,
  createManyInvoiceJustifications,
  fetchInvoiceJustification,
  listInvoiceJustifications,
  abonedInvoiceJustification,
  abonedInvoiceJustifications,
  // justifications - tracking
  createJustification,
  fetchJustification,
  listJustifications,
  paginateJustifications,
  updateJustification,
  // justifications - reverse logistics
  createJustificationReverseLogistics,
  fetchJustificationReverseLogistics,
  listJustificationsReverseLogistics,
  paginateJustificationsReverseLogistics,
  updateJustificationReverseLogistics,
  // vehicle types
  createVehicleType,
  fetchVehicleType,
  listVehicleTypes,
  paginateVehicleTypes,
  updateVehicleType,
  // product units
  listProductUnits,
  //reasons
  createReason,
  fetchReason,
  listReasons,
  paginateReasons,
  updateReason,
  // suppliers
  createSupplier,
  fetchSupplier,
  listSuppliers,
  paginateSuppliers,
  updateSupplier,
  // status
  fetchStatusList,
  // transit time
  createTransitTime,
  fetchTransitTime,
  listTransitTimes,
  paginateTransitTimes,
  updateTransitTime,
  recalculateTransitTime,
  fetchPublicInvoice,
  // operation types cfop
  createOperationTypesCfop,
  paginateOperationTypesCfop,
  toggleDisableOperationTypeCFOP,
  //justifitication types
  fetchJustificationTypes,

  //recall slice
  recallSlice,
  // cargo types
  createCargoType,
  fetchCargoType,
  listCargoTypes,
  paginateCargoTypes,
  updateCargoType,
  // order types
  fetchOrderType,
  listOrderTypes,
  // scheduling
  listSchedulingDocks,
  listSchedulingVehicleSetups,
  listWarehouseScheduleEntries,
  // orders
  createOrder,
  updateOrder,
  fetchOrder,
  listOldOrders,
  rescheduleOrder,
  paginateOrders,
  updateOrderCancelationReason,
  updateOrderDriverAndVehicle,
  updateOrderEvents,
  updateOrderNoShow,
  exportOrders,
  // cancelation reasons
  createCancelationReason,
  fetchCancelationReason,
  listCancelationReasons,
  paginateCancelationReasons,
  updateCancelationReason,
  //warehouse params cache
  warehouseScheduleParamsCache,
  // docks
  createDock,
  fetchDock,
  listDocks,
  paginateDocks,
  updateDock,
  // docks and carriers
  createDockCarrier,
  deleteDockCarrier,
  // docks and companies
  createDockClient,
  deleteDockClient,
  // docks and operation types
  createDockOperationType,
  deleteDockOperationType,
  // dock reserved intervals
  createDockReservedInterval,
  deleteDockReservedInterval,
  fetchDockReservedInterval,
  listDockReservedIntervals,
  paginateDockReservedIntervals,
  updateDockReservedInterval,
  // vehicle setups
  createVehicleSetup,
  fetchVehicleSetup,
  listVehicleSetups,
  paginateVehicleSetups,
  updateVehicleSetup,
  // company vehicle setups
  createCompanyVehicleSetup,
  deleteCompanyVehicleSetup,
  fetchCompanyVehicleSetup,
  listCompanyVehicleSetups,
  paginateCompanyVehicleSetups,
  updateCompanyVehicleSetup,
  // order item invoice
  listOrderItemInvoices,
  // warehouse rules carrier
  createCompanyCarrier,
  deleteCompanyCarrier,
  // warehouse rules carrier
  createCompanyClient,
  deleteCompanyClient,
  // warehouse rules vehicle type
  createCompanyVehicleType,
  deleteCompanyVehicleType,
  // warehouse rules carrier
  createCompanyOperationType,
  deleteCompanyOperationType,
  // order file
  fetchOrderFile,
});
