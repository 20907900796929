import { Order } from 'contracts/Orders';
import { useCallback } from 'react';
import { exportXLSX, Formatter } from 'utils';

export const useOrder = () => {
  const generateXLSX = useCallback((ordersToExport: Order[]) => {
    if (ordersToExport.length) {
      const fileName = 'AGENDAMENTO-PAINEL-GERENCIAL';
      const data = ordersToExport.map((item: Order) => {
        const {
          id,
          schedulingDate,
          vehicleSetup,
          client,
          carrier,
          status,
          company,
          driverName,
          driverDocument,
          observation,
          material,
          vehicleArrivalCompany,
          vehicleExitCompany,
          orderStartedCompany,
          orderFinishedCompany,
          vehicleArrivalClient,
          vehicleExitClient,
          orderStartedClient,
          orderFinishedClient,
          noShowAt,
          canceledAt,
          createdByUser,
        } = item;

        return {
          Coleta: id ? id : '',
          Status: status ? status : '',
          'Data do Agendamento': schedulingDate
            ? Formatter.date(schedulingDate, { format: 'dd/MM/yy HH:mm' })
            : '',
          'Tipo de Veiculo': vehicleSetup.vehicleType
            ? vehicleSetup.vehicleType.name
            : '',
          Carga: vehicleSetup.cargoType.name ? vehicleSetup.cargoType.name : '',
          Cliente: client?.tradeName ? client.tradeName : '',
          Transportadora: carrier.tradeName ? carrier.tradeName : '',
          Armazem: company.tradeName ? company.tradeName : '',
          Capacidade: vehicleSetup.weightCapacity
            ? vehicleSetup.weightCapacity
            : '',
          Placa: vehicleSetup.vehiclePlate ? vehicleSetup.vehiclePlate : '',
          'Documento do  motorista': driverDocument ? driverDocument : '',
          'Nome do Motorista': driverName ? driverName : '',
          Observacao: observation ? observation : '',
          Produto: material ? material : '',
          'Chegada Veiculo Origem': vehicleArrivalCompany
            ? Formatter.date(vehicleArrivalCompany, {
                format: 'dd/MM/yy HH:mm',
              })
            : '',
          'Saida Veiculo Origem': vehicleExitCompany
            ? Formatter.date(vehicleExitCompany, { format: 'dd/MM/yy HH:mm' })
            : '',
          'Inicio da Ordem Origem': orderStartedCompany
            ? Formatter.date(orderStartedCompany, { format: 'dd/MM/yy HH:mm' })
            : '',
          'Fim da Ordem Origem': orderFinishedCompany
            ? Formatter.date(orderFinishedCompany, { format: 'dd/MM/yy HH:mm' })
            : '',
          'Chegada Veiculo Destino': vehicleArrivalClient
            ? Formatter.date(vehicleArrivalClient, { format: 'dd/MM/yy HH:mm' })
            : '',
          'Saida Veiculo Destino': vehicleExitClient
            ? Formatter.date(vehicleExitClient, { format: 'dd/MM/yy HH:mm' })
            : '',
          'Inicio da Ordem Destino': orderStartedClient
            ? Formatter.date(orderStartedClient, { format: 'dd/MM/yy HH:mm' })
            : '',
          'Fim da Ordem Destino': orderFinishedClient
            ? Formatter.date(orderFinishedClient, { format: 'dd/MM/yy HH:mm' })
            : '',
          'No Show': noShowAt ? Formatter.dateFull(noShowAt) : '',
          'Criado por': createdByUser.name ? createdByUser.name : '',
          ' Data/Hora Cancelamento': canceledAt
            ? Formatter.date(canceledAt, { format: 'dd/MM/yy HH:mm' })
            : '',
        };
      });

      exportXLSX(data, fileName);
    }
  }, []);

  return {
    generateXLSX,
  };
};
