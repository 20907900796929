import { SelectOption } from "contracts/Common";
import { useAuth } from "hooks/auth";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "store";
import { ListCompaniesActions as MainActions } from "store/ducks/companies";
import { FetchCompanyActions as FetchActions } from "store/ducks/companies";
import { Formatter } from "utils";

export const useCompanies = () => {
  const dispatch: AppDispatch = useDispatch();
  const { profile } = useAuth();

  const { data: companies, loading: loadingCompanies } = useSelector(
    (state: RootState) => state.listCompanies
  );
  const { data: company, loading: loadingCompany } = useSelector(
    (state: RootState) => state.fetchCompany
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchCompanies = useCallback(
    (dataRequest?: any): void => {
      dispatch(MainActions.request(dataRequest));
    },
    [dispatch]
  );

  const fetchCompany = useCallback(
    (id: string | number): void => {
      dispatch(FetchActions.request(id));
    },
    [dispatch]
  );

  const resetCompany = useCallback(() => {
    dispatch(FetchActions.reset());
  }, [dispatch]);

  const onCompaniesLoad = useCallback((): void => {
    if (!profile) return;
    const { companies: companiesAccess } = profile;

    if (companiesAccess?.length) {
      const accessIds = companiesAccess.map(({ id }) => id);
      const options = companies
        .filter(({ id }) => accessIds.includes(id))
        .map(({ id, tradeName, documentType, document }) => ({
          value: id,
          label: `${tradeName} - ${Formatter.document(document, documentType)}`,
        }));

      setOptions(options);
    } else {
      setOptions(
        companies.map(({ id, tradeName, documentType, document }) => ({
          value: id,
          label: `${tradeName} - ${Formatter.document(document, documentType)}`,
        }))
      );
    }
  }, [companies, profile]);

  useEffect(() => {
    onCompaniesLoad();
  }, [onCompaniesLoad]);

  useEffect(() => {
    return () => {
      setOptions([]);
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    companies,
    companyOptions: options,
    loadingCompanies,
    company,
    loadingCompany,
    fetchCompanies,
    fetchCompany,
    resetCompany,
  };
};

export type CompaniesHook = ReturnType<typeof useCompanies>;
