import { useAuth } from 'hooks';

export const usePermission = () => {
  const { profile } = useAuth();

  const hasPermissionTo = (permissions: string[]): boolean => {
    const actions = profile?.actions;

    if (!actions || !permissions || permissions.length === 0) return false;

    permissions = permissions.map((permission) => permission.toUpperCase());

    const hasPermission = permissions.every((action: string) =>
      actions.includes(action)
    );

    return hasPermission;
  };

  return {
    hasPermissionTo,
  };
};
