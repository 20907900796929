import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import * as S from './styles';

interface Props {
  onTabChange?: (index: number) => void;
  children: Array<React.ReactNode>;
  current?: number;
}

export const Controller: React.FC<Props> = forwardRef(
  ({ current, onTabChange, children }, ref) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const BASE_CLASS = 'tab-button';

    const handleTabChange = useCallback(
      (index: number): void => {
        setActiveTab(index);
        onTabChange && onTabChange(index);
      },
      [onTabChange]
    );

    useImperativeHandle(
      ref,
      () => ({
        setActiveTabHeader: (i: number) => setActiveTab(i),
      }),
      []
    );

    useEffect(() => {
      if (!isNaN(Number(current))) setActiveTab(current || 0);
    }, [current]);

    return (
      <S.Controller>
        {children.map((child, index) => {
          return (
            <S.ControllerButton
              key={index}
              className={
                index === activeTab ? `${BASE_CLASS} active` : BASE_CLASS
              }
              onClick={() => handleTabChange(index)}
              type="button"
            >
              {child}
            </S.ControllerButton>
          );
        })}
      </S.Controller>
    );
  }
);
