import { useEffect } from "react";
import jsCookie from "js-cookie";

import { useDispatch } from "react-redux";
import Logo from "assets/images/logo.png";

import { api } from "services";
import { AuthActions } from "store/ducks/auth";


const LoginRedirectCheck = () => {
  const dispatch = useDispatch();

  async function getData(accessToken: string) {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      const { data } = await api.post("/get-user-data");
      dispatch(AuthActions.setData(data));
    } catch (error) {
      jsCookie.remove("_corteva");
      window.location.href = "https://login.webcol.systems/corteva";
    }
  }

  useEffect(() => {
    const accessToken = jsCookie.get("_corteva");
    (() => {
      if (accessToken) {
        getData(accessToken);
      } else {
        window.location.href = "https://login.webcol.systems/corteva";
      }
    })();
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        gap: "10px",
      }}
    >
      <img src={Logo} alt="iff logo" width={100} />
      <h2 style={{ color: "#222", fontWeight: "normal" }}>Loading...</h2>
    </div>
  );
};

export default LoginRedirectCheck;