import React from 'react';
import { useParams } from 'react-router-dom';
import { OrderPrinting } from 'components/Pages/Scheduling';
import { PageProps } from 'types/global';

export const OrderPrintingPage: React.FC<PageProps> = (props) => {
  const { id } = useParams() as { id: string };

  return <OrderPrinting orderId={id} />;
};
