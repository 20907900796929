import * as S from '../../styles'
import { useAuth } from "hooks";
import type { Detail, IBase } from "../types";
import { Formatter } from 'utils';
import { api } from 'services';

interface MainGridProps extends IBase {}

export const MainGrid = ({ order }: MainGridProps): JSX.Element => {
  const { userBelongsToAnyOf } = useAuth();

  if (!order) return <></>;

  const insertReader = async (): Promise<void> => {
    if (!order) return;
    if (order.orderReader?.id) return;

    try {
      await api.post(`/orders/${order.id}/reader`);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    schedulingDate,
    orderItems,
    totalWeight,
    carrier,
    client,
    vehicleSetup,
    driverName,
    driverDocument,
    orderReader,
    totalValue,
  } = order;

  if (!orderReader && userBelongsToAnyOf('warehouseMember')) {
    insertReader();
  }

  const details: Detail[] = [
    {
      label: 'Data agendada',
      value: Formatter.date(schedulingDate, {
        format: 'dd/MM/yyyy HH:mm',
      }),
    },
    {
      label: 'Cliente',
      value: client?.tradeName || '---',
    },
    {
      label: 'Qtd documentos',
      value: orderItems.length,
    },
    {
      label: 'Peso',
      value: Formatter.weight(totalWeight),
    },
    {
      label: 'Transportadora',
      value: carrier.tradeName,
    },
    {
      label: 'Motorista',
      value: driverName,
    },
    {
      label: 'CPF do motorista',
      value: Formatter.document(driverDocument),
    },
    {
      label: 'Tipo de veículo',
      value: vehicleSetup?.vehicleType.name || '---',
    },
    {
      label: 'Placa do veículo',
      value: vehicleSetup.vehiclePlate || '---',
    },
    {
      label: 'Tipo de carga',
      value: vehicleSetup.cargoType.name,
    },
    {
      label: 'Valor total',
      value: totalValue.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
  ];

  return (
    <S.MainGrid>
      {details.map(({ label, value }, i) => (
        <S.Detail key={i}>
          <S.DetailLabel>{label}</S.DetailLabel>
          <S.DetailValue>{value}</S.DetailValue>
        </S.Detail>
      ))}
    </S.MainGrid>
  );
};