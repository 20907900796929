import type { FormHandles } from '@unform/core';
import * as S from './styles';
import { Form } from '@unform/web';
import { useEffect } from 'react';
import { useClients } from 'hooks';

interface IUnload {
  formRef: React.RefObject<FormHandles>;
}

export const Unload = ({ formRef }: IUnload) => {
  const { fetchClients, clientOptions, loadingClients } = useClients();

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <Form ref={formRef} onSubmit={() => {}}>
      <S.Panel>
        <S.FormRow>
          <S.Input
            name="calculatedArrivalTime"
            label="Chegada Calculada"
            type="datetime-local"
            disabled
          />
          <S.Input
            name="dischargeDocumentationTime"
            label="Entrega Documentação Descarga"
            type="datetime-local"
            disabled
          />
          <S.Input name="responsible" label="Responsável" />
          <S.Select
            name="clientId"
            label="Armazém Destino"
            options={clientOptions}
            isLoading={loadingClients}
          />
        </S.FormRow>
      </S.Panel>
    </Form>
  );
};
