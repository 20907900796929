import styled from 'styled-components';
import { Colors, Fonts } from 'styles/constants';

export const PageContainer = styled.div`
  display: flex;
  background: ${Colors.Gray20};
  flex-direction: column;
  position: relative;
`;

export const Backdrop = styled.div`
  width: 100%;
`;

export const Targe = styled.div`
  display: flex;
  justify-content: center;
  z-index: +9;
  padding: 8px;
  background: ${Colors.Orange};
  color: ${Colors.White};
  font-family: ${Fonts.GilroySemiBold};
  margin-bottom: 20px;
  border-radius: 6px;
  width: 100%;
  top: 0;
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 1920px;
  padding: 24px;
  width: 100%;
  margin: 0 auto;
  gap: 24px;
`;

export const LeftPanelSlot = styled.div`
  width: min-content;
`;

export const MainSlot = styled.div`
  display: flex;
  flex: 0 1 100%;
  flex-direction: column;
  overflow: hidden;
`;

export const ChildrenSlot = styled.main`
  /* animation: ContentSlideIn 1000ms ease forwards; */
`;
