import styled, { css } from "styled-components";

import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as Auditor } from "assets/icons/auditor.svg";
import { ReactComponent as BarChart } from "assets/icons/bar-chart.svg";
import { ReactComponent as Block } from "assets/icons/block.svg";
import { ReactComponent as Content } from "assets/icons/book-content.svg";
import { ReactComponent as Check } from "assets/icons/check.svg";
import { ReactComponent as Buildings } from "assets/icons/buildings.svg";
import { ReactComponent as BuildingsOutline } from "assets/icons/buildings-outline.svg";
import { ReactComponent as BuildingHouse } from "assets/icons/building-house.svg";
import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import { ReactComponent as Cancelation } from "assets/icons/cancelation.svg";
import { ReactComponent as ClearFilter } from "assets/icons/clear-filter.svg";
import { ReactComponent as CheckCircle } from "assets/icons/check-circle.svg";
import { ReactComponent as CheckShield } from "assets/icons/check-shield.svg";
import { ReactComponent as ChevronRightCircleOutline } from "assets/icons/chevron-circle-right-outline.svg";
import { ReactComponent as Category } from "assets/icons/category.svg";
import { ReactComponent as ChevronRightCircle } from "assets/icons/chevron-right-circle.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Comment } from "assets/icons/comment-detail.svg";
import { ReactComponent as Detail } from "assets/icons/detail.svg";
import { ReactComponent as Download } from "assets/icons/download-icon.svg";
import { ReactComponent as Dollar } from "assets/icons/dollar.svg";
import { ReactComponent as Dock } from "assets/icons/dock.svg";
import { ReactComponent as DownArrowSquare } from "assets/icons/down-arrow-square.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as EditAlt } from "assets/icons/edit-alt.svg";
import { ReactComponent as Eye } from "assets/icons/eye-outline.svg";
import { ReactComponent as EyeOff } from "assets/icons/eye-off-outline.svg";
import { ReactComponent as FileBlank } from "assets/icons/file-blank.svg";
import { ReactComponent as Factory } from "assets/icons/factory.svg";
import { ReactComponent as List } from "assets/icons/list.svg";
import { ReactComponent as Lock } from "assets/icons/lock.svg";
import { ReactComponent as Login } from "assets/icons/login.svg";
import { ReactComponent as MapPin } from "assets/icons/map-pin.svg";
import { ReactComponent as Message } from "assets/icons/message.svg";
import { ReactComponent as MessageAdd } from "assets/icons/message-add.svg";
import { ReactComponent as MessageAltCheck } from "assets/icons/message-alt-check.svg";
import { ReactComponent as MessageAltError } from "assets/icons/message-alt-error.svg";
import { ReactComponent as MessageAltMinus } from "assets/icons/message-alt-minus.svg";
import { ReactComponent as MessageCheck } from "assets/icons/message-square-check.svg";
import { ReactComponent as MessageDetail } from "assets/icons/message-detail.svg";
import { ReactComponent as Operation } from "assets/icons/operation.svg";
import { ReactComponent as Package } from "assets/icons/package.svg";
import { ReactComponent as PencilLine } from "assets/icons/pencil-line.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Printer } from "assets/icons/printer.svg";
import { ReactComponent as Refresh } from "assets/icons/refresh.svg";
import { ReactComponent as ReservedInterval } from "assets/icons/reserved-interval.svg";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { ReactComponent as Timer } from "assets/icons/timer.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { ReactComponent as Trash2 } from "assets/icons/trash-lucide.svg";
import { ReactComponent as TriangleLeft } from "assets/icons/triangle-left.svg";
import { ReactComponent as TriangleRight } from "assets/icons/triangle-right.svg";
import { ReactComponent as Truck } from "assets/icons/truck.svg";
import { ReactComponent as UpArrowSquare } from "assets/icons/up-arrow-square.svg";
import { ReactComponent as UseRectangle } from "assets/icons/user-rectangle.svg";
import { ReactComponent as UserPin } from "assets/icons/user-pin.svg";
import { ReactComponent as Warehouse } from "assets/icons/warehouse.svg";
import { ReactComponent as Weight } from "assets/icons/weight.svg";
import { ReactComponent as Invoice } from "assets/icons/invoice.svg";
import { ReactComponent as Close } from "assets/icons/close-circle.svg";
import { ReactComponent as UserCircle } from "assets/icons/user-circle.svg";
import { ReactComponent as UserRequest } from "assets/icons/user-request.svg";
import { ReactComponent as Dot } from "assets/icons/dot.svg";
import { ReactComponent as SAP } from "assets/icons/sap.svg";
import { ReactComponent as Error } from 'assets/icons/error.svg';
import { ReactComponent as ReverseLogistics } from 'assets/icons/reverse-logistics.svg';
import { ReactComponent as FilePDF } from 'assets/icons/file-pdf.svg';
import { ReactComponent as FileXML } from 'assets/icons/file-xml.svg';
import { ReactComponent as Wallet } from 'assets/icons/wallet.svg';
import { ReactComponent as World } from 'assets/icons/world.svg';

import { Colors } from "styles/constants";

export {
  LoaderCircle as Loader,
  Checkbox,
  LogOutCircle,
  DotsVerticalRounded,
  Upload,
} from "@styled-icons/boxicons-regular";
export { CheckboxChecked } from "@styled-icons/boxicons-solid";
export { Check } from "@styled-icons/boxicons-regular";
export {
  ArrowUnsorted as Sort,
  ArrowSortedUp as SortUp,
  ArrowSortedDown as SortDown,
} from "@styled-icons/typicons";

const baseSvgStyle = css`
  path {
    fill: currentColor;
  }
`;

export const AdminIcon = styled(CheckShield)`
  ${baseSvgStyle};
`;

export const ArrowLeftIcon = styled(ArrowLeft)`
  ${baseSvgStyle}
`;

export const AuditorIcon = styled(Auditor)`
  ${baseSvgStyle}
`;

export const BarChartIcon = styled(BarChart)`
  ${baseSvgStyle}
`;

export const BlockIcon = styled(Block)`
  ${baseSvgStyle}
`;

export const BookIcon = styled(Content)`
  ${baseSvgStyle}
`;

export const CheckIcon = styled(Check)`
  ${baseSvgStyle}
`;

export const CalendarIcon = styled(Calendar)`
  ${baseSvgStyle}
`;

export const CancelationIcon = styled(Cancelation)`
  ${baseSvgStyle}
`;

export const ClearFilterIcon = styled(ClearFilter)`

`;

export const CheckCircleIcon = styled(CheckCircle)`
  ${baseSvgStyle}
`;

export const CategoryIcon = styled(Category)`
  ${baseSvgStyle}
`;

export const CompanyIcon = styled(Buildings)`
  ${baseSvgStyle};
`;

export const CompanyIconOutline = styled(BuildingsOutline)`
  ${baseSvgStyle};
`;

export const ClientIcon = styled(BuildingHouse)`
  ${baseSvgStyle};
`;

export const DetailIcon = styled(Detail)`
  ${baseSvgStyle};
`;

export const DownloadIcon = styled(Download)`
  ${baseSvgStyle};
`;

export const DollarIcon = styled(Dollar)`
  ${baseSvgStyle};
`;

export const DockIcon = styled(Dock)`
  ${baseSvgStyle};
`;

export const DownArrowSquareIcon = styled(DownArrowSquare)`
  ${baseSvgStyle}
`;

export const EditIcon = styled(Edit)`
  ${baseSvgStyle}
`;

export const EditAltIcon = styled(EditAlt)`
  ${baseSvgStyle}
`;

export const EyeOffIcon = styled(EyeOff)`
  ${baseSvgStyle};
`;

export const EyeIcon = styled(Eye)`
  ${baseSvgStyle};
`;

export const FileIcon = styled(FileBlank)`
  ${baseSvgStyle};
`;

export const FactoryIcon = styled(Factory)`
  ${baseSvgStyle};
`;

export const ListIcon = styled(List)`
  ${baseSvgStyle};
`;

export const LockIcon = styled(Lock)`
  ${baseSvgStyle};
  color: ${Colors.Gray50};
  margin-top: 2px;
  margin-bottom: -2px;
`;

export const LoginIcon = styled(Login)`
  ${baseSvgStyle};
`;

export const MapPinIcon = styled(MapPin)`
  ${baseSvgStyle};
`;

export const MessageAltCheckIcon = styled(MessageAltCheck)`
  ${baseSvgStyle};
  display: flex;
  border-radius: 100px;
  background-color: ${Colors.Green10};
  color: ${Colors.Green};
  padding: 3px;
`;

export const MessageAltAlertIcon = styled(MessageAltError)`
  ${baseSvgStyle};
  display: flex;
  border-radius: 100px;
  background-color: ${Colors.Yellow10};
  color: #ddab13;
  padding: 3px;
`;

export const MessageAltMinusIcon = styled(MessageAltMinus)`
  ${baseSvgStyle};
  color: #969696;
  display: flex;
  border-radius: 100px;
  background-color: ${Colors.Gray30};
  padding: 3px;
`;

export const MessageIcon = styled(Message)`
  ${baseSvgStyle};
`;

export const MessageAddIcon = styled(MessageAdd)`
  ${baseSvgStyle};
`;

export const MessageCheckIcon = styled(MessageCheck)`
  ${baseSvgStyle};
`;

export const MessageDetailIcon = styled(MessageDetail)`
  ${baseSvgStyle};
`;

export const OperationIcon = styled(Operation)`
  ${baseSvgStyle};
`;

export const PackageIcon = styled(Package)`
  ${baseSvgStyle};
`;

export const PencilIcon = styled(PencilLine)``;

export const PlusIcon = styled(Plus)`
  ${baseSvgStyle}
`;

export const PrinterIcon = styled(Printer)`
  ${baseSvgStyle};
`;

export const RefreshIcon = styled(Refresh)``;

export const ReservedIntervalIcon = styled(ReservedInterval)`
  ${baseSvgStyle};
`;

export const ReverseLogisticsIcon = styled(ReverseLogistics)`
  ${baseSvgStyle};
`;

export const SearchIcon = styled(Search)`
  ${baseSvgStyle}
`;

export const TimerIcon = styled(Timer)`
  ${baseSvgStyle}
`;

export const ChevronRightCircleIcon = styled(ChevronRightCircle)`
  ${baseSvgStyle}
`;

export const ChevronRightCircleOutlineIcon = styled(ChevronRightCircleOutline)``;

export const SettingsIcon = styled(Cog)`
  ${baseSvgStyle}
`;

export const CommentIcon = styled(Comment)`
  ${baseSvgStyle}
`;

export const TrashIcon = styled(Trash)`
  ${baseSvgStyle}
`;

export const TrashLucideIcon = styled(Trash2)``;

export const TriangleLeftIcon = styled(TriangleLeft)`
  ${baseSvgStyle}
`;

export const TriangleRightIcon = styled(TriangleRight)`
  ${baseSvgStyle}
`;

export const UserPinIcon = styled(UserPin)`
  ${baseSvgStyle}
`;

export const UserRectangleIcon = styled(UseRectangle)`
  ${baseSvgStyle}
`;

export const UserRequestIcon = styled(UserRequest)`
  ${baseSvgStyle}
`;

export const SupplierIcon = styled(Warehouse)`
  ${baseSvgStyle}
`;

export const TruckIcon = styled(Truck)`
  ${baseSvgStyle}
`;

export const UpArrowSquareIcon = styled(UpArrowSquare)`
  ${baseSvgStyle}
`;

export const WeightIcon = styled(Weight)`
  ${baseSvgStyle}
`;

export const InvoiceIcon = styled(Invoice)`
  ${baseSvgStyle}
`;

export const CloseIcon = styled(Close)`
  ${baseSvgStyle}
`;

export const UserCircleIcon = styled(UserCircle)`
  ${baseSvgStyle}
`;

export const DotIcon = styled(Dot)`
  ${baseSvgStyle}
`;

export const SAPIcon = styled(SAP)`
  ${baseSvgStyle}
`;

export const ErrorIcon = styled(Error)`
  ${baseSvgStyle}
  color: ${Colors.Orange10};
`;

export const FilePDFIcon = styled(FilePDF)`
  ${baseSvgStyle}
`;

export const FileXMLIcon = styled(FileXML)`
  ${baseSvgStyle}
`;

export const WalletIcon = styled(Wallet)`
  ${baseSvgStyle}
`;

export const WorldIcon = styled(World)`
  ${baseSvgStyle}
`;
