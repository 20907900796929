import styled, { css } from 'styled-components';
import {
  BasePaginatorHeader,
  BasePaginatorListItem,
  ChevronRightCircleOutlineIcon,
} from 'styles/components';
import { InvoiceIcon as Invoice } from 'styles/components';
import { Colors } from 'styles/constants';

export {
  PaginatorColumn as Column,
  PaginatorActionsColumn as ActionsColumn,
} from 'styles/components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 16px;
`;

const baseGridCss = css`
  grid-template-columns: 200px 140px 200px auto;
`;

export const InvoiceIcon = styled(Invoice)`
  color: ${Colors.Gray50};
  width: 20px;
  height: 20px;
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}

  a {
    margin-left: auto;
  }
    
  &:not(:first-child) {
    border-bottom: 1px solid ${Colors.Gray30};
  }
`;

export const ChevronIcon = styled(ChevronRightCircleOutlineIcon)`
  width: 22px;
  height: 22px;
`;
