import styled from 'styled-components';
import { Fonts } from 'styles/constants';

export { ModalContent, ModalHeader, ModalBody } from 'styles/components';
export { ModalCloseButton } from 'components/Shared';

export const ModalTitle = styled.h2`
  font-size: 1.3rem;
  padding: 4px 12px;
`;

const BaseGrid = styled.div`
  display: grid;
  gap: 32px 24px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const MainGrid = styled(BaseGrid)`
  display: grid;
  padding: 12px;
  gap: 32px 24px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const FooterModal = styled.footer`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #eaeaea;
  padding-top: 24px;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  align-items: center;
`  

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DetailLabel = styled.label`
  font-family: ${Fonts.GilroySemiBold};
  font-size: 14px;
`;

export const DetailValue = styled.span`
  font-family: ${Fonts.GilroyRegular};
  font-size: 14px;
`;
