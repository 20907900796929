import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import * as S from './styles';
import { v4 as getRamdonId } from 'uuid';

interface Props {
  name: string;
  id?: string;
  label?: string;
  onChange?: (value: boolean) => void;
  defaultChecked?: 'Y' | 'N';
  disabled?: boolean;
}

export const FlipFlopCheckbox: React.FC<Props> = ({
  name,
  id,
  label,
  onChange,
  defaultChecked,
  disabled,
}) => {
  const ramdonY = getRamdonId();
  const ramdonN = getRamdonId();
  const [checked, setChecked] = useState<string | undefined>(
    defaultChecked || 'Y'
  );
  const inputRefs = useRef<HTMLInputElement[] | null[]>(
    Array.from({ length: 2 }, () => null)
  );

  const { fieldName, defaultValue = [], registerField, error } = useField(name);

  const LabelComponent = useCallback((): JSX.Element => {
    if (!label) return <></>;
    return <S.FieldLabel htmlFor={id || fieldName}>{label}</S.FieldLabel>;
  }, [fieldName, id, label]);

  const ErrorComponent = useCallback((): JSX.Element => {
    if (!error) return <></>;
    return <S.FieldError>{error}</S.FieldError>;
  }, [error]);

  const handleChange = useCallback(
    (value: string) => {
      if (disabled) return;

      setChecked(value);
      return onChange && onChange(value === 'Y');
    },
    [onChange, disabled]
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      //@ts-ignore
      getValue: (refs: HTMLInputElement[]) => {
        return refs.find((ref) => ref?.checked)?.value === 'Y';
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], value: boolean) => {
        const isChecked = Boolean(value)
        const check = isChecked ? ramdonY : ramdonN;

        refs.forEach((ref) => {
          if (ref.name.includes(check)) {
            return ref.checked = true;
          }
          return  ref.checked = false;
        });

        setChecked(isChecked ? 'Y' : 'N')
      },
    });
  }, [fieldName, registerField]);

  return (
    <S.Container>
      <LabelComponent />
      <S.Options>
        <S.Option htmlFor={ramdonY} key={ramdonY}>
          <S.Input
            name={ramdonY}
            type="checkbox"
            id={ramdonY}
            ref={(ref) => (inputRefs.current[0] = ref)}
            checked={checked === 'Y'}
            value={'Y'}
            onChange={() => handleChange('Y')}
            disabled={disabled}
          />
          <S.Icons>
            <S.CheckedIcon
              style={{ color: disabled ? '#94949b' : 'currentColor' }}
            />
            <S.UncheckedIcon />
          </S.Icons>
          Sim
        </S.Option>
        <S.Option htmlFor={ramdonN} key={ramdonN}>
          <S.Input
            name={ramdonN}
            type="checkbox"
            id={ramdonN}
            ref={(ref) => (inputRefs.current[1] = ref)}
            checked={checked === 'N'}
            value={'N'}
            onChange={() => handleChange('N')}
            disabled={disabled}
          />
          <S.Icons>
            <S.CheckedIcon
              style={{ color: disabled ? '#94949b' : 'currentColor' }}
            />
            <S.UncheckedIcon />
          </S.Icons>
          Não
        </S.Option>
      </S.Options>
      <ErrorComponent />
    </S.Container>
  );
};
