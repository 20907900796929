import { Order } from "contracts/Orders";
import * as Yup from "yup";

interface Props {
  order: Order;
}

export class UpdateOrderEventsValidator {
  constructor(private props: Props) {}

  private getShape(field: string, dependency: Object) {
    return {
      ...(Object.values(dependency)[0] && {
        [field]: Yup.string().test({
          name: "testFinishedAt",
          test: function (time) {
            try {
              if (time && this.parent.vehicleArrivalCompany > time) {
                throw new Error('Deve ser após o anterior');
              }

              return true;
            } catch (error: any) {
              return this.createError({
                message: error?.message,
              });
            }
          },
        }),
      }),
    };
  }

  public get schema() {
    const {
      orderStartedCompany,
      orderFinishedCompany,
      vehicleExitCompany,
      vehicleArrivalClient,
      orderStartedClient,
      orderFinishedClient,
      vehicleExitClient,
    } = this.props.order;

    return Yup.object().shape({
      vehicleArrivalCompany: Yup.string().required("Informe o horário"),
      ...this.getShape("orderStartedCompany", { orderStartedCompany }),
      ...this.getShape("orderFinishedCompany", { orderFinishedCompany }),
      ...this.getShape("vehicleExitCompany", { vehicleExitCompany }),
      ...this.getShape("vehicleArrivalClient", { vehicleArrivalClient }),
      ...this.getShape("orderStartedClient", { orderStartedClient }),
      ...this.getShape("orderFinishedClient", { orderFinishedClient }),
      ...this.getShape("vehicleExitClient", { vehicleExitClient }),
    });
  }
}
