import { EMPTY_COLUMN_VALUE } from 'constants/Common';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { FetchOrderActions as MainActions } from 'store/ducks/orders';
import { Formatter } from 'utils';
import * as S from './styles';

interface Props {
  orderId: string | number;
}

interface Detail {
  label: string;
  value: string | number;
}

export const OrderPrinting: React.FC<Props> = ({ orderId }) => {
  const dispatch: AppDispatch = useDispatch();

  const { data: order, loading } = useSelector(
    (state: RootState) => state.fetchOrder
  );

  const onFetchSuccess = useCallback(() => {
    setTimeout(() => {
      window.print();
    }, 500);
  }, []);

  const onFetchFailure = useCallback(() => {
    setTimeout(() => {
      window.close();
    }, 3000);
  }, []);

  const fetchOrder = useCallback((): void => {
    dispatch(MainActions.request(orderId, onFetchSuccess, onFetchFailure));
  }, [dispatch, onFetchFailure, onFetchSuccess, orderId]);

  const Header = useCallback((): JSX.Element => {
    if (!order) return <></>;

    // const logoUrl = order.company.logoUrl;
    const orderNumber = `${order.id}`.padStart(4, '0');
    const date = Formatter.date(order.schedulingDate, {
      format: 'dd/MM/yyyy HH:mm',
    });

    return (
      <S.PageHeader>
        {/* {!!logoUrl && (
          <S.CompanyLogo>
            <img src={logoUrl} alt="" />
          </S.CompanyLogo>
        )} */}
        <S.PageHeaderTitle>
          <h4>Ordem {orderNumber}</h4>
          <span>{date}</span>
        </S.PageHeaderTitle>
        <S.PageHeaderStatus>
          {/* <span>Status: {Formatter.orderStatusName(order.status)}</span> */}
          {!!order.cancelationReason && (
            <span>
              Justificativa de cancelamento: {order.cancelationReason.name}
            </span>
          )}
        </S.PageHeaderStatus>
      </S.PageHeader>
    );
  }, [order]);

  const WarehouseGrid = useCallback((): JSX.Element => {
    if (!order) return <></>;
    const { company } = order;

    return (
      <S.WarehouseGrid>
        <S.Detail>
          <S.DetailLabel>Origem</S.DetailLabel>
          <S.DetailValue>{company.tradeName}</S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Endereço do armazém</S.DetailLabel>
          <S.DetailValue>{Formatter.address(company)}</S.DetailValue>
        </S.Detail>
      </S.WarehouseGrid>
    );
  }, [order]);

  const MainGrid = useCallback((): JSX.Element => {
    if (!order) return <></>;

    const {
      orderItems,
      totalWeight,
      carrier,
      client,
      driverName,
      driverDocument,
      vehicleSetup,
      totalValue,
    } = order;

    //arrumnar esses erros aqui

    const getNullableDetail = (
      label: string,
      value: string | number | null
    ): Detail[] => {
      if (value === null) return [];
      return [{ label, value }];
    };

    const details: Detail[] = [
      {
        label: 'Destino',
        value: client?.tradeName || '---',
      },
      {
        label: 'Qtd documentos',
        value: orderItems.length,
      },
      {
        label: 'Peso',
        value: Formatter.weight(totalWeight),
      },
      {
        label: 'Transportadora',
        value: carrier.tradeName,
      },
      {
        label: 'Motorista',
        value: driverName,
      },
      {
        label: 'CPF do motorista',
        value: Formatter.document(driverDocument),
      },
      {
        label: 'Placa do veículo',
        value: vehicleSetup.vehiclePlate || '---',
      },
      {
        label: 'Tipo de carga',
        value: vehicleSetup.cargoType.name || '---',
      },
      {
        label: 'Valor total',
        value: totalValue.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
      },
    ];

    return (
      <S.MainGrid>
        {details.map(({ label, value }, i) => (
          <S.Detail key={i}>
            <S.DetailLabel>{label}</S.DetailLabel>
            <S.DetailValue>{value}</S.DetailValue>
          </S.Detail>
        ))}
      </S.MainGrid>
    );
  }, [order]);

  const InfoGrid = useCallback((): JSX.Element => {
    if (!order) return <></>;
    const { observation, material } = order;

    return (
      <S.InfoGrid>
        <S.Detail>
          <S.DetailLabel>Observações</S.DetailLabel>
          <S.DetailValue>{observation || EMPTY_COLUMN_VALUE}</S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Materiais</S.DetailLabel>
          <S.DetailValue>{material || EMPTY_COLUMN_VALUE}</S.DetailValue>
        </S.Detail>
      </S.InfoGrid>
    );
  }, [order]);

  const Events = useCallback((): JSX.Element => {
    if (!order) return <></>;
    const {
      vehicleArrivalCompany,
      orderStartedCompany,
      orderFinishedCompany,
      vehicleExitCompany,
      vehicleArrivalClient,
      orderStartedClient,
      orderFinishedClient,
      vehicleExitClient,
    } = order;

    const getDatetimeValue = (event: string | null): string => {
      if (!event) {
        return Formatter.date(order.schedulingDate, {
          format: 'dd MMM yyyy, __:__',
        });
      }

      return Formatter.date(event, { format: 'dd MMM yyyy, HH:mm' });
    };

    return (
      <S.EventsGrid>
        <S.Detail>
          <S.DetailLabel>Chegada na origem</S.DetailLabel>
          <S.DetailValue>
            {getDatetimeValue(vehicleArrivalCompany)}
          </S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Início da coleta</S.DetailLabel>
          <S.DetailValue>{getDatetimeValue(orderStartedCompany)}</S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Fim da coleta</S.DetailLabel>
          <S.DetailValue>
            {getDatetimeValue(orderFinishedCompany)}
          </S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Saída da origem</S.DetailLabel>
          <S.DetailValue>{getDatetimeValue(vehicleExitCompany)}</S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Chegada no destino</S.DetailLabel>
          <S.DetailValue>
            {getDatetimeValue(vehicleArrivalClient)}
          </S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Início da descarga</S.DetailLabel>
          <S.DetailValue>{getDatetimeValue(orderStartedClient)}</S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Fim da descarga</S.DetailLabel>
          <S.DetailValue>{getDatetimeValue(orderFinishedClient)}</S.DetailValue>
        </S.Detail>
        <S.Detail>
          <S.DetailLabel>Saída do destino</S.DetailLabel>
          <S.DetailValue>{getDatetimeValue(vehicleExitClient)}</S.DetailValue>
        </S.Detail>
      </S.EventsGrid>
    );
  }, [order]);

  const OrderItems = useCallback((): JSX.Element => {
    if (!order?.orderItems.length) return <></>;

    return (
      <>
        <S.Subtitle>Itens do agendamento</S.Subtitle>
        <S.OrderItemsGrid>
          <S.ListHeader>
            <div>Documento</div>
            <div>Peso</div>
            <div>Valor</div>
          </S.ListHeader>
          {order.orderItems.map((item, i) => (
            <S.ListItem key={item.id}>
              <S.Column>{item.document}</S.Column>
              <S.Column>{Formatter.weight(item.weight)}</S.Column>
              <S.Column>{Formatter.currency(item.value)}</S.Column>
            </S.ListItem>
          ))}
        </S.OrderItemsGrid>
      </>
    );
  }, [order?.orderItems]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return (
    <S.PageContainer>
      {loading && <S.ActivityIndicator />}
      <Header />
      <WarehouseGrid />
      <MainGrid />
      <InfoGrid />
      <Events />
      <OrderItems />
    </S.PageContainer>
  );
};
