import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useField } from "@unform/core";
import * as S from "./styles";

type Props = JSX.IntrinsicElements["input"] & {
  name: string;
  id?: string;
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
  classContainer?: string;
  onSearch?: (value: any) => void;
  onRemove: () => void;
  filter?: { carrierId?: number | string | null };
  hasInvoice: boolean;
};

export const InvoiceInput: React.FC<Props> = ({
  name,
  id,
  label,
  type,
  placeholder = " ",
  isLoading = false,
  classContainer = "",
  onSearch,
  onRemove,
  filter,
  hasInvoice,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [tempType, setTempType] = useState(type);

  const LabelComponent = useCallback((): JSX.Element => {
    if (!label) return <></>;
    return <S.FieldLabel htmlFor={id || fieldName}>{label}</S.FieldLabel>;
  }, [fieldName, id, label]);

  const ErrorComponent = useCallback((): JSX.Element => {
    if (!error) return <></>;
    return <S.FieldError>{error}</S.FieldError>;
  }, [error]);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: "value",
      ref: inputRef.current,
    });
  }, [fieldName, registerField]);

  if (type === "hidden") {
    return (
      <input
        name={fieldName}
        id={id || fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        type={type}
        {...rest}
      />
    );
  }

  const handleFocusDateType = () => {
    if (type === "date") {
      setTempType("date");
    }
  };

  const handleBlurDateType = () => {
    if (type === "date" && !inputRef.current?.value) {
      setTempType("text");
    }
  };

  return (
    <S.Container
      className={
        classContainer ? `field-container ${classContainer}` : "field-container"
      }
    >
      <LabelComponent />
      <S.RelativeWrapper>
        <input
          name={fieldName}
          id={id || fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          placeholder={placeholder}
          type={tempType}
          onFocus={handleFocusDateType}
          onChange={(e) => setInvoiceNumber(e.target.value)}
          onBlur={handleBlurDateType}
          {...rest}
        />
        {isLoading ? (
          <S.ActivityIndicatorContainer>
            <S.ActivityIndicator />
          </S.ActivityIndicatorContainer>
        ) : filter?.carrierId && !hasInvoice && /^[0-9]+$/.test(invoiceNumber) ? (
          <S.SearchContainer
            onClick={() => {
              onSearch && onSearch({ invoiceNumber, ...filter });
            }}
          >
            <S.SearchIcon />
          </S.SearchContainer>
        ) : hasInvoice ? (
          <S.RemoveContainer onClick={onRemove}>&#x2715;</S.RemoveContainer>
        ) : (
          <></>
        )}
      </S.RelativeWrapper>
      <ErrorComponent />
    </S.Container>
  );
};
