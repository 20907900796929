import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Colors,
  ColorScheme,
  Fonts,
  getScrollbarStyle,
} from 'styles/constants';
import { BasePanel, ChevronRightCircleIcon } from 'styles/components';
export {
  ListIcon,
  UserPinIcon,
  SettingsIcon,
  CompanyIcon,
  UserRectangleIcon,
  AdminIcon,
  InvoiceIcon,
  ReverseLogisticsIcon,
  BarChartIcon,
  CalendarIcon,
  WorldIcon,
  ReservedIntervalIcon,
} from 'styles/components';

interface Recall {
  recall?: boolean;
}

interface IMenuItem {
  active?: boolean;
}

export const MenuItem = styled(Link)<IMenuItem>`
  display: flex;
  flex-direction: column;
  color: currentColor;
  padding: 8px 32px;
  height: 95px;
  width: 240px;
  border-left: 4px solid #ffffff00;
  transition: all 0.7s;
  &:hover {
    border-left: 4px solid ${ColorScheme.Primary};
    cursor: pointer;
  }
  ${({ active }) =>
    active &&
    css`
      border-left: 4px solid ${ColorScheme.Primary};
    `}
`;

export const MenuHrefItem = styled.a<IMenuItem>`
  display: flex;
  flex-direction: column;
  color: currentColor;
  padding: 8px 32px;
  height: 95px;
  width: 240px;
  border-left: 4px solid #ffffff00;
  transition: all 0.7s;
  &:hover {
    border-left: 4px solid ${ColorScheme.Primary};
    cursor: pointer;
  }
  ${({ active }) =>
    active &&
    css`
      border-left: 4px solid ${ColorScheme.Primary};
    `}
`;

export const MenuItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${Fonts.GilroySemiBold};
  font-size: 15px;
  margin-bottom: 8px;
  gap: 0 9px;
  
  p {
    transition all 0.4s;
  }
`;

export const MenuItemBody = styled.p`
  font-size: 12px;
  font-family: ${Fonts.OpenSans};
  transition all 0.4s;
  color: ${ColorScheme.LightText};
`;

// Body

export const NavigationMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 8px;
  ${getScrollbarStyle()};
  ::-webkit-scrollbar-thumb {
    border-color: ${Colors.Gray70};
    background-color: ${Colors.Gray50};
  }
  ::-webkit-scrollbar-track {
    background-color: ${Colors.Gray70};
  }
`;

export const LeftPanel = styled(BasePanel)<Recall>`
  flex: 1 0 100%;
  width: ${({ recall }) => (recall ? '90px' : '220px')};
  transition: width 0.7s;
  height: calc(100vh - 48px);
  overflow: hidden;
  flex-direction: column;
  position: sticky;
  top: 24px;
  background: ${Colors.Gray70};
  color: ${Colors.White};

  ${({ recall }) =>
    recall &&
    css`
      img {
        width: 70px;
      }
      ${MenuItem} {
        height: 45px;
      }
      ${MenuItemHeader} {
        p {
          opacity: 0;
        }
      }
      ${MenuItemBody} {
        opacity: 0;
      }
      ${NavigationMenu} {
        overflow-y: hidden;
      }
    `};
`;

export const Container = styled.div`
  position: sticky;
  top: 24px;
  width: min-content;
`;

export const CollapseButton = styled.button<Recall>`
  background: ${Colors.Gray70};
  border-radius: 50%;
  padding: 1px;
  width: 26px;
  height: 26px;
  position: absolute;
  transition: transform 0.5s;
  transform: ${({ recall }) => (recall ? 'rotate(0deg)' : 'rotate(180deg)')};
  right: -12px;
  top: 90px;
  z-index: 1;
`;

export const ArrowIcon = styled(ChevronRightCircleIcon)`
  color: ${Colors.Gray50};
  transition: color 0.4s;
  :hover {
    color: ${Colors.Gray30};
  }
`;

// Header

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.figure`
  margin: 52px 0;
`;

export const LogoImage = styled.img`
  width: 130px;
  transition: width 0.7s;
`;

// Footer

export const Footer = styled.div`
  text-align: center;
  font-size: 11px;
  padding: 12px 24px;
  margin-top: auto;
  white-space: nowrap;
  color: ${Colors.Gray50};
`;

