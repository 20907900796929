import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Select } from 'components/Shared';
import type { SelectOption } from 'contracts/Common';
import type { DockClient } from 'contracts/Docks';
import { useValidation } from 'hooks';
import { Formatter } from 'utils';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import {
  CreateDockClientActions as CreateActions,
  DeleteDockClientActions as DeleteActions,
} from 'store/ducks/dockClients';
import * as Yup from 'yup';
import * as S from './styles';

export interface Ref {
  resetForm: () => void;
}

interface Props {
  dockId?: number;
  dockClients: DockClient[];
  onUpdate?: () => void;
}

interface IClientsManager
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

interface ItemProps {
  dockClient: DockClient;
}

const ClientsManager: IClientsManager = forwardRef<Ref, Props>(
  (props, ref) => {
    const { dockId, dockClients, onUpdate } = props;

    const dispatch: AppDispatch = useDispatch();
    const formRef = useRef<FormHandles>(null);

    const { handleFormErrors, handleApiErrors } = useValidation();
    const [clientOptions, setClientOptions] = useState<SelectOption[]>([]);

    /**
     * this component will not fetch carriers here,
     * we lifted it to a upper level DocksManager component)
     * to avoid dispatching multiple times
     * */
    const { loading: loadingClients, data: clients } = useSelector(
      (state: RootState) => state.listClients
    );
    const { loading: creating, validationErrors } = useSelector(
      (state: RootState) => state.createDockClient
    );

    const { id: deletingId } = useSelector(
      (state: RootState) => state.deleteDockClient
    );

    /**
     * we will also filter the list of clients to only show
     * the ones that are not already bound to the dock
     * */
    const onClientsLoad = useCallback((): void => {
      setClientOptions(
        clients
          .filter((client) => {
            return !dockClients?.find(
              (dockClient) => dockClient.client.id === client.id
            );
          })
          .map((client) => ({
            value: client.id,
            label: `${client.tradeName} - ${Formatter.document(
              client.document,
              client.documentType
            )}`,
          }))
      );
    }, [clients, dockClients]);

    const onDelete = useCallback(
      (id: number): void => {
        dispatch(DeleteActions.request(id, onUpdate));
      },
      [dispatch, onUpdate]
    );

    const onSuccess = useCallback((): void => {
      onUpdate && onUpdate();
      formRef.current?.reset();
    }, [onUpdate]);

    const onSubmit = useCallback(
      async (data: any): Promise<void> => {
        try {
          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            clientId: Yup.number()
              .typeError('Selecione o cliente')
              .required('Selecione o cliente'),
          });

          const validData = await schema.validate(data, {
            abortEarly: false,
          });

          dispatch(CreateActions.request({ ...validData, dockId }, onSuccess));
        } catch (error) {
          handleFormErrors(error, formRef);
        }
      },
      [dispatch, dockId, handleFormErrors, onSuccess]
    );

    const Item = useCallback(
      (props: ItemProps): JSX.Element => {
        const { id, client } = props.dockClient;

        return (
          <S.ListItem>
            <S.Column>
              {client.tradeName} -{' '}
              {Formatter.document(client.document, client.documentType)}
            </S.Column>
            <S.ActionsColumn>
              <S.ActionButton
                disabled={deletingId === id}
                onClick={() => onDelete(id)}
                mood="danger"
              >
                {deletingId === id ? <S.ActivityIndicator /> : <S.TrashIcon />}
              </S.ActionButton>
            </S.ActionsColumn>
          </S.ListItem>
        );
      },
      [deletingId, onDelete]
    );

    useEffect(() => {
      onClientsLoad();
    }, [onClientsLoad]);

    useEffect(() => {
      handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    useImperativeHandle(
      ref,
      () => ({
        resetForm: () => {
          formRef.current?.reset();
          formRef.current?.setErrors({});
        },
      }),
      []
    );

    return (
      <S.Container>
        <Form ref={formRef} onSubmit={onSubmit}>
          <S.FormRow>
            <Select
              name="clientId"
              label="Cliente"
              options={clientOptions}
              isLoading={loadingClients}
              menuPortalTarget={document.body}
            />
            <S.Button type="submit">
              {creating ? <S.ActivityIndicator /> : 'Adicionar'}
            </S.Button>
          </S.FormRow>
        </Form>
        {dockClients?.map((dockClient) => (
          <Item dockClient={dockClient} key={dockClient.id} />
        ))}
      </S.Container>
    );
  }
);

export default ClientsManager;
