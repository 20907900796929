import { ConfirmationDialog, ConfirmationDialogRef } from 'components/Shared';
import type { PaginatedCompanyVehicleSetup } from 'contracts/CompanyVehicleSetups';
import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { DeleteCompanyVehicleSetupActions as DeleteActions } from 'store/ducks/companyVehicleSetups';
import { Formatter } from 'utils';
import * as S from './styles';

interface Props {
  setups: PaginatedCompanyVehicleSetup[];
  onSelectSetup?: (bond: PaginatedCompanyVehicleSetup) => void;
  onDelete?: () => void;
}

interface ItemProps {
  companyVehicleSetup: PaginatedCompanyVehicleSetup;
}

const SetupsList: React.FC<Props> = ({ setups, onSelectSetup, onDelete }) => {
  const dispatch: AppDispatch = useDispatch();
  const dialogRef = useRef<ConfirmationDialogRef>(null);
  const { id: deletingId } = useSelector(
    (state: RootState) => state.deleteCompanyVehicleSetup
  );

  const handleDelete = useCallback(
    async (setup: PaginatedCompanyVehicleSetup): Promise<void> => {
      const confirmed = await dialogRef.current?.openDialog({
        title: 'Deseja remover o vínculo com este setup de veículo?',
        message: 'Esta ação não poderá ser desfeita após confirmada.',
        confirmButtonMood: 'outlinedDanger',
      });
      if (confirmed) {
        dispatch(DeleteActions.request(setup.id, onDelete));
      }
    },
    [dispatch, onDelete]
  );

  const Item = useCallback(
    ({ companyVehicleSetup }: ItemProps): JSX.Element => {
      const { id, loadDuration, unloadDuration, weightCapacity, vehicleSetup } =
        companyVehicleSetup;

      return (
        <S.ListItem>
          <S.Column>{id}</S.Column>
          <S.Column>{vehicleSetup.vehicleType.name}</S.Column>
          <S.Column>{vehicleSetup.cargoType.name}</S.Column>
          <S.Column>{Formatter.weight(weightCapacity)}</S.Column>
          <S.Column>{Formatter.minutesToTime(loadDuration)}</S.Column>
          <S.Column>{Formatter.minutesToTime(unloadDuration)}</S.Column>
          <S.ActionsColumn>
            <S.ActionButton
              mood="danger"
              onClick={() => handleDelete(companyVehicleSetup)}
            >
              {deletingId === id ? <S.ActivityIndicator /> : <S.TrashIcon />}
            </S.ActionButton>
            <S.ActionButton
              onClick={() => onSelectSetup?.(companyVehicleSetup)}
            >
              <S.EditIcon />
            </S.ActionButton>
          </S.ActionsColumn>
        </S.ListItem>
      );
    },
    [deletingId, handleDelete, onSelectSetup]
  );

  if (setups.length === 0) {
    return (
      <S.EmptyListPlaceholder>
        Ainda não há setups de veículos vinculados a este armazém
      </S.EmptyListPlaceholder>
    );
  }

  return (
    <S.List>
      <ConfirmationDialog ref={dialogRef} />
      <S.ListHeader>
        <div>ID</div>
        <div>TIPO DE VEÍCULO</div>
        <div>TIPO DE CARGA</div>
        <div>CAP. DE CARGA</div>
        <div>CARREGAMENTO</div>
        <div>DESCARREGAMENTO</div>
      </S.ListHeader>
      {setups.map((setup) => (
        <Item key={setup.id} companyVehicleSetup={setup} />
      ))}
    </S.List>
  );
};

export default SetupsList;
