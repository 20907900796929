import styled from 'styled-components';
import { BasePanel } from 'styles/components';
import { Colors } from 'styles/constants';
export {
  FormRow,
  Button,
  LinkButton,
  ActivityIndicator,
  FormActions,
  CalendarIcon,
  ArrowLeftIcon,
  CheckIcon,
} from 'styles/components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Panel = styled(BasePanel)`
  flex: 0 1 100%;
  flex-direction: column;
  background: ${Colors.White};
  padding: 24px;
`;
