import { useLocation } from 'react-router-dom';

import * as S from './styles';
import { useMemo } from 'react';
import { usePermission } from 'hooks/usePermission';

interface RouteBase {
  title: string
  path: string
  show?: boolean
}

export const NavigationDashboard = () => {
  const location = useLocation();
  const { hasPermissionTo } = usePermission()

//   const { userBelongsToAnyOf } = useAuth()

  const routesWithActived = useMemo(() => {

    const routes: RouteBase[] = [
      { 
        title: 'Tracking', 
        path: '#tracking',
        show: true,
      },
      {
        title: 'Retrabalho',
        path: '#rework',
        show: hasPermissionTo(['DASHBOARD.REWORK'])
      },
      // {
      //   title: 'Warehouse',
      //   path: '#warehouse',
      //   show: isAdmin
      // },
      {
        title: 'Inventário',
        path: '#inventory',
        show: hasPermissionTo(['DASHBOARD.INVENTORY'])
      },
      {
        title: 'RCCP',
        path: '#rccp',
        show: hasPermissionTo(['DASHBOARD.RCCP'])
      },
      {
        title: 'Operação FDR',
        path: '#fdr-operation',
        show: hasPermissionTo(['DASHBOARD.FDR'])
      },
    ]

    return routes
        .filter(({show}) => show )
        .map(route => {
          const path = `/dashboard${route.path}`
          const isEmptyHash = location.hash === '' && path === `/dashboard${routes[0].path}`
          const isSamePath = path === `/dashboard${location.hash}`

          return {
            ...route,
            isActived: isSamePath || isEmptyHash
          }
        })

  }, [location.hash, hasPermissionTo])

  return (
    <S.Container>
      {routesWithActived.map(route => (
        <S.Button
          key={route.path}
          to={`/dashboard${route.path}`}
          className={route.isActived ? 'actived': ''}
        >
          {route.title}
        </S.Button>
      ))}
    </S.Container>
  );
};
