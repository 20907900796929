import { call, put } from "@redux-saga/core/effects"
import { api, notify, apiErrorHandler } from "services"
import {
  DeleteWarehouseCompanyMemberActions as Actions,
  DeleteWarehouseCompanyMemberRequestAction as RequestAction,
} from "store/ducks/warehouseMembers"

export function* deleteWarehouseCompanyMemberRequest(action: any) {
  const { companyId, userId, onSuccess, onFailure }: RequestAction = action
  try {
    yield call(
      api.delete,
      `company-members/remove-company/${companyId}/${userId}`
    )
    onSuccess && onSuccess()
    notify("success", "Armzém removido com sucesso")
    yield put(Actions.success())
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error)
    onFailure && onFailure()
    notify("error", errorMessage)
    yield put(Actions.failure(errorMessage, validationErrors))
  }
}
