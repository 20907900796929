import { isPast, isToday } from 'date-fns';
import { Validator } from 'utils';
import * as Yup from 'yup';

interface Props {
  withinTimeframeCompany: boolean;
  withinTimeframeClient: boolean;
  escortRequired: boolean;
  canEditDriver: boolean;
}

const testDate: any = {
  name: 'testDate',
  test: function (value: any) {
    try {
      if (!value) {
        throw new Error('Data inválida');
      }

      if (isPast(value) && !isToday(value)) {
        throw new Error('A data não pode estar no passado');
      }

      return true;
    } catch (error: any) {
      return this.createError({
        message: error?.message,
      });
    }
  },
};

const testOptionalDate: any = {
  name: 'testOptionalDate',
  test: function (value: any) {
    try {
      if (!value) return true;

      if (isPast(value) && !isToday(value)) {
        throw new Error('A data não pode estar no passado');
      }

      return true;
    } catch (error: any) {
      return this.createError({
        message: error?.message,
      });
    }
  },
};

export class UpdateOrderValidator {
  constructor(private props: Props) {}

  public get schema() {
    const requiredMessage = 'Campo obrigatório';
    return {
      loadSchema: Yup.object().shape({
        withinTimeframeCompany: Yup.string().typeError(requiredMessage),
        ...(this.props.canEditDriver && {
          driverName: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
          driverDocument: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage)
            .test({
              name: 'testDocument',
              test(value = '') {
                try {
                  Validator.validateDocument(value, 'cpf');
                  return true;
                } catch (error: any) {
                  return this.createError({
                    message: error?.message,
                  });
                }
              },
            }),
        }),
        ...(!this.props.withinTimeframeCompany && {
          responsibleCompany: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
          reasonCompany: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
          ncProcessCompany: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
        }),
        escortRequired: Yup.boolean()
          .required(requiredMessage)
          .typeError(requiredMessage),
        ...(this.props?.escortRequired && {
          escortRequestTime: Yup.date()
            .required(requiredMessage)
            .test(testDate),
          geNumber: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
        }),
        deliveryDocumentationTime: Yup.date().optional().test(testOptionalDate),
        materialSeparationTime: Yup.date().optional().test(testOptionalDate),
      }),
      unloadSchema: Yup.object().shape({
        dischargeDocumentationTime: Yup.date()
          .optional()
          .test(testOptionalDate),
        responsible: Yup.string().typeError(requiredMessage),
        clientId: Yup.string().typeError(requiredMessage),
        withinTimeframeClient: Yup.string()
          .required(requiredMessage)
          .typeError(requiredMessage),
        ...(!this.props.withinTimeframeClient && {
          responsibleClient: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
          reasonClient: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
          ncProcessClient: Yup.string()
            .required(requiredMessage)
            .typeError(requiredMessage),
        }),
      }),
    };
  }
}
