import { Formatter } from 'utils';
import * as S from './styles';
import type { Order } from 'contracts/Orders';

interface Detail {
  label: string;
  value?: string | number | null;
}

interface Props {
  order: Order | null;
}

export const MainGrid = ({ order }: Props): JSX.Element => {
  if (!order) return <></>;

  const {
    id,
    createdAt,
    schedulingDate,
    code,
    carrier,
    client,
    vehicleSetup,
    driverName,
    driverDocument,
    escortRequestTime,
    plannedDepartureTime,
    materialSeparationTime,
    deliveryDocumentationTime,
    optimalOutputTime,
    expectedLoadingTime,
    company,
    createdByUser,
  } = order;

  const details: Detail[] = [
    {
      label: 'Identificador',
      value: id,
    },
    {
      label: 'Coleta',
      value: code,
    },
    {
      label: 'Data agendada',
      value: Formatter.date(schedulingDate, {
        format: 'dd/MM/yyyy HH:mm',
      }),
    },
    {
      label: 'Transportadora',
      value: carrier.tradeName,
    },
    {
      label: 'Origem',
      value: company.tradeName,
    },
    {
      label: 'Destino',
      value: client?.tradeName,
    },
    {
      label: 'Motorista',
      value: driverName,
    },
    {
      label: 'CPF do motorista',
      value: Formatter.document(driverDocument),
    },
    {
      label: 'Tipo de veículo',
      value: vehicleSetup?.vehicleType.name,
    },
    {
      label: 'Placa do veículo',
      value: vehicleSetup?.vehiclePlate,
    },
    {
      label: 'Tipo de carga',
      value: vehicleSetup.cargoType.name,
    },
    {
      label: 'Horario escolta',
      value: Formatter.date(escortRequestTime, {
        format: 'dd/MM/yyyy HH:mm',
      }),
    },
    {
      label: 'Saida planejada',
      value: Formatter.date(plannedDepartureTime, {
        format: 'dd/MM/yyyy HH:mm',
      }),
    },
    {
      label: 'Horário separação Material',
      value: Formatter.date(materialSeparationTime, {
        format: 'dd/MM/yyyy HH:mm',
      }),
    },
    {
      label: 'Entrega documentação carregamento',
      value: Formatter.date(deliveryDocumentationTime, {
        format: 'dd/MM/yyyy HH:mm',
      }),
    },
    {
      label: 'Horário esperado carregamento',
      value: Formatter.date(expectedLoadingTime, {
        format: 'dd/MM/yyyy HH:mm',
      }),
    },
    {
      label: 'Saída ideal',
      value: Formatter.date(optimalOutputTime, {
        format: 'dd/MM/yyyy HH:mm',
      }),
    },
  ];

  return (
    <>
      <S.MainGrid>
        {details.map(({ label, value }, i) => (
          <S.Detail key={i}>
            <S.DetailLabel>{label}</S.DetailLabel>
            <S.DetailValue>{value || '--'}</S.DetailValue>
          </S.Detail>
        ))}
      </S.MainGrid>
      <S.FooterModal className=''>
        <div className='flex gap-2'>
          <S.DetailLabel>Usuário:</S.DetailLabel>
          <S.DetailValue>{createdByUser.name}</S.DetailValue>
        </div>
        <div className='flex gap-2'>
          <S.DetailLabel>Data:</S.DetailLabel>
          <S.DetailValue>
            {Formatter.date(createdAt, {
              format: 'dd/MM/yyyy HH:mm',
            })}
          </S.DetailValue>
        </div>
      </S.FooterModal>
    </>
  );
};
