import { RouteObject, Outlet, Navigate } from 'react-router-dom';
import type { RouterProps } from 'contracts/Router';
import {
  OrderCreationPage,
  OrderPrintingPage,
  ListOrdersPage,
  OrderUpdatePage,
} from 'pages/Scheduling';
import { ReschedulePage } from 'pages/Scheduling/Reschedule';

const schedulingRoutes = ({
  isLoggedIn,
  location,
}: RouterProps): RouteObject => ({
  path: 'agendamento',
  element: isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} />
  ),
  children: [
    {
      path: '',
      element: <ListOrdersPage nonProduction />,
    },
    {
      path: 'criar',
      element: <OrderCreationPage nonProduction/>,
    },
    {
      path: 'editar/:id',
      element: <OrderUpdatePage nonProduction order={location.state as any} />,
    },
    {
      path: 'reagendar/:id',
      element: <ReschedulePage nonProduction order={location.state as any} />,
    },
    {
      path: ':id/impressao',
      element: <OrderPrintingPage />,
    },
  ],
});

export default schedulingRoutes;
