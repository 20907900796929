import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { SortingParams } from 'contracts/Common';
import type { PaginatedDockReservedInterval } from 'contracts/DockReservedIntervals';
import React, { useCallback } from 'react';
import { Formatter } from 'utils';
import * as S from './styles';

interface Props {
  intervals: PaginatedDockReservedInterval[];
  onSort?: (sort: SortingParams) => void;
  currentSort: SortingParams;
}

interface ItemProps {
  interval: PaginatedDockReservedInterval;
}

export const IntervalsList: React.FC<Props> = ({
  intervals,
  onSort,
  currentSort,
}) => {
  const Item = useCallback(({ interval }: ItemProps): JSX.Element => {
    const { id, startDate, endDate, startTime, endTime, dock } = interval;

    return (
      <S.ListItem>
        <S.Column>{id}</S.Column>
        <S.Column>{dock.company.tradeName}</S.Column>
        <S.Column>{dock.name}</S.Column>
        <S.Column>
          {Formatter.date(`${startDate.split('T')[0]} ${startTime}`)}
        </S.Column>
        <S.Column>
          {Formatter.date(`${endDate.split('T')[0]} ${endTime}`)}
        </S.Column>
        <S.Column>{startTime}</S.Column>
        <S.Column>{endTime}</S.Column>
        <S.ActionsColumn>
          <S.LinkActionButton
            to={`/configuracoes/bloqueio-de-horarios/${id}/editar`}
          >
            <S.EditIcon />
          </S.LinkActionButton>
        </S.ActionsColumn>
      </S.ListItem>
    );
  }, []);

  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="ID" />
          <SortableHeader column="warehouseTradeName" label="ARMAZÉM" />
          <SortableHeader column="dockName" label="DOCA" />
          <SortableHeader column="startDate" label="DATA INÍCIO" />
          <SortableHeader column="endDate" label="DATA TÉRMINO" />
          <SortableHeader column="startTime" label="HORA INÍCIO" />
          <SortableHeader column="endTime" label="HORA TÉRMINO" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {intervals.map((interval) => (
        <Item key={interval.id} interval={interval} />
      ))}
    </S.List>
  );
};
