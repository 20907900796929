import styled from 'styled-components';
import { BaseFiltersContainer, ClearFilterIcon } from 'styles/components';
import { Colors } from 'styles/constants';
export { FormRow } from 'styles/components';

export const Container = styled(BaseFiltersContainer)`
  form {
    > div:nth-child(1) {
      grid-area: warehouse;
      // fot the select to be above the acalendar content
      z-index: 2;
    }

    > div:nth-child(2) {
      grid-area: fromDate;
    }

    > div:nth-child(3) {
      grid-area: toDate;
    }

    > div:nth-child(4) {
      grid-area: clearFilter;
    }

    > div:nth-child(5) {
      grid-area: status;
    }

    grid-template-columns: 360px 180px 180px 40px auto;
    grid-template-areas: 'warehouse fromDate toDate clearFilter status';

    @media (max-width: 2000px) {
      grid-template-columns: auto 200px 200px 40px;
      grid-template-areas:
        'warehouse fromDate toDate'
        'status status status';
    }
  }
`;

export const ClearButton = styled.button`
  border-radius: 4px;
  border: 1px solid ${Colors.Gray30};
  transition: background 120ms;
  aspect-ratio: 1;
  padding: 4px;
  width: min-content;
  height: min-content;

  &:disabled {
    opacity: 0.7;
  }

  &:not([disabled]):hover {
    background: ${Colors.Gray10};
  }
`;

export const ClearIcon = styled(ClearFilterIcon)`
  path {
    fill: transparent;
  }
`;
