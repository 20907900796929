import type { FormHandles } from '@unform/core';
import * as S from './styles';
import { Form } from '@unform/web';
import { useEffect, useState } from 'react';
import { useClients } from 'hooks';
import * as C from '../../Constants';
import type { Order } from 'contracts/Orders';

interface IUnload {
  formRef: React.RefObject<FormHandles>;
  data: Order | null;
}

interface ICheckState {
  performance?: boolean;
}

export const Unload = ({ formRef, data }: IUnload) => {
  const [checks, setChecks] = useState<ICheckState>({ performance: true });
  const { fetchClients, clientOptions, loadingClients } = useClients();

  const onPerformanceChange = (value: boolean) => {
    const fieldsToClear = [
      'responsibleClient',
      'reasonClient',
      'ncProcessClient',
    ];
    setChecks((prev) => ({ ...prev, performance: value }));
    fieldsToClear.forEach((field) => formRef.current?.clearField(field));
  };

  const onDataLoad = () => {
    setChecks((prev) => ({
      ...prev,
      performance: Boolean(data?.withinTimeframeClient),
    }));
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    onDataLoad();
  }, [data]);

  return (
    <Form ref={formRef} onSubmit={() => {}}>
      <S.Panel>
        <S.FormRow>
          <S.Input
            name="calculatedArrivalTime"
            label="Chegada Calculada"
            type="datetime-local"
            disabled
          />
          <S.Input
            name="dischargeDocumentationTime"
            label="Entrega Documentação Descarga"
            type="datetime-local"
            min={C.MIN_DATETIME}
          />
          <S.Input name="responsible" label="Responsável" />
          <S.Select
            name="clientId"
            label="Armazém Destino"
            options={clientOptions}
            isLoading={loadingClients}
          />
        </S.FormRow>
        <S.FormRow>
          <S.FlipFlopCheckbox
            name="withinTimeframeClient"
            label="Atendeu Performance"
            defaultChecked="Y"
            onChange={onPerformanceChange}
          />
          <S.Input
            name="responsibleClient"
            label="Responsável"
            disabled={checks?.performance}
          />
          <S.Input
            name="reasonClient"
            label="Motivo Macro"
            disabled={checks?.performance}
          />
          <S.Input
            name="ncProcessClient"
            label="Houve alguma NC"
            disabled={checks?.performance}
          />
        </S.FormRow>
      </S.Panel>
    </Form>
  );
};
