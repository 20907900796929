import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import * as S from './styles';
import { Scaffold } from 'layouts';
import { useAuth } from 'hooks';
import { NavigationDashboard } from '../NavigationDashboard';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePermission } from 'hooks/usePermission';
import { dashboardUrl } from 'services';

const Indicators = () => {
  const { profile, userBelongsToAnyOf } = useAuth();
  const { hasPermissionTo } = usePermission();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showNavigator, setShowNavigator] = useState<boolean>(true);

  const handleClick = useCallback(() => {
    if (ref.current) {
      if (window.document.fullscreenElement) {
        if (window.document.exitFullscreen) {
          window.document.exitFullscreen();
        }
      } else {
        ref.current.requestFullscreen();
      }
    }
  }, []);

  const handleKeyDown = useCallback(() => {
    setIsFullscreen((v) => !v);
    setShowNavigator((prev) => !prev);
  }, []);

  useEffect(() => {
    if (window.document) {
      window.document.addEventListener('fullscreenchange', handleKeyDown);
    }

    return () => {
      window.document.removeEventListener('fullscreenchange', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (
      ![
        '#rework',
        '#tracking',
        '#warehouse',
        '#inventory',
        '#rccp',
        '#fdr-operation',
      ].includes(hash) ||
      !hash
    ) {
      navigate('/dashboard#tracking');
    }
  }, [hash, navigate]);

  const url = useMemo(() => {
    const zohoTrackingUrl = `${dashboardUrl}/corteva-tracking`;
    const zohoReworkUrl = `${dashboardUrl}/corteva-rework`;
    const zohoWarehouseUrl = `${dashboardUrl}/warehouse`;
    const zohoInventoryUrl = `${dashboardUrl}/corteva-inventory`;
    const zohoRCCPUrl = `${dashboardUrl}/corteva-rccp`;
    const zohoFRDOperationUrl = `${dashboardUrl}/corteva-operation-fdr`;

    const zoho = {
      '#tracking': {
        show: true,
        url: zohoTrackingUrl,
      },
      '#rework': {
        show: hasPermissionTo(['DASHBOARD.REWORK']),
        url: zohoReworkUrl,
      },
      // '#warehouse': {
      //   show: hasPermissionTo(['DASHBOARD.REWORK']),
      //   url: zohoWarehouseUrl,
      // },
      '#inventory': {
        show: hasPermissionTo(['DASHBOARD.INVENTORY']),
        url: zohoInventoryUrl,
      },
      '#rccp': {
        show: hasPermissionTo(['DASHBOARD.RCCP']),
        url: zohoRCCPUrl,
      },
      '#fdr-operation': {
        show: hasPermissionTo(['DASHBOARD.FDR']),
        url: zohoFRDOperationUrl,
      },
    }[hash];

    if (zoho?.show) return zoho.url;

    navigate('/dashboard#tracking');
  }, [hash, navigate, profile, userBelongsToAnyOf]);

  return (
    <Scaffold showComponents={showNavigator}>
      <S.PageContainer ref={ref}>
        {showNavigator && <NavigationDashboard />}
        <S.Button onClick={handleClick} isFull={isFullscreen}>
          {isFullscreen ? <S.IconExitFullscreen /> : <S.IconFullscreen />}
        </S.Button>
        <S.Iframe width="100%" height="auto" src={url} />
      </S.PageContainer>
    </Scaffold>
  );
};

export { Indicators };
