import { EMPTY_COLUMN_VALUE } from 'constants/Common';
import * as S from '../../styles';
import type { IBase } from '../types';

interface InfoGridProps extends IBase {}

export const InfoGrid = ({ order }: InfoGridProps): JSX.Element => {
  if (!order) return <></>;
  const { observation, material } = order;

  return (
    <S.InfoGrid>
      <S.Detail>
        <S.DetailLabel>Observações</S.DetailLabel>
        <S.DetailValue>{observation || EMPTY_COLUMN_VALUE}</S.DetailValue>
      </S.Detail>
      <S.Detail>
        <S.DetailLabel>Materiais</S.DetailLabel>
        <S.DetailValue>{material || EMPTY_COLUMN_VALUE}</S.DetailValue>
      </S.Detail>
    </S.InfoGrid>
  );
};