import { call, put } from '@redux-saga/core/effects';
import { api, notify, apiErrorHandler } from 'services';
import {
  AbonedInvoiceJustificationsActions as Actions,
  AbonedInvoiceJustificationsRequestAction as RequestAction,
} from 'store/ducks/invoiceJustifications';

export function* abonedInvoiceJustificationsRequest(action: any) {
  const { payload, onSuccess, onFailure }: RequestAction = action;
  try {
    const { data } = yield call(api.put, `invoices/aboned`, payload);
    onSuccess && onSuccess();
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    yield put(Actions.failure(errorMessage));
  }
}
