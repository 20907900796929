import styled from 'styled-components'
import { BasePanel } from 'styles/components';
import { Colors } from 'styles/constants';

export { Select, Input,MaskedInput, FlipFlopCheckbox, HiddenInput, Textarea, FileInput } from "components/Shared";
export { FormRow, Button } from "styles/components";

export const Panel = styled(BasePanel)`
  flex: 0 1 100%;
  flex-direction: column;
  background: ${Colors.White};
  padding: 24px 0;
`;

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`