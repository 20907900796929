import type { RouterProps } from 'contracts/Router';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

// guest
import loginRoute from './guest/login';
import forgotMyPasswordRoute from './guest/forgotMyPassword';
import passwordResetRoute from './guest/passwordReset';

// authenticated
import adminsRoutes from './admins';
// import productTypesRoutes from './productTypes';
import carriersRoutes from './carriers';
import carrierMemberRoutes from './carrierMembers';
import companiesRoutes from './companies';
import clientsRoutes from './clients';
import auditorsRoutes from './auditors';
import reverseLogisticsRoutes from './reverseLogistics';
import reverseLogisticsConfigurationRoutes from './reverseLogisticsConfiguration';
// import productRoutes from './products';
import suppliersRoutes from './suppliers';
import justificationsRoutes from './justifications';
import vehicleTypesRoutes from './vehicleTypes';
import pendingReleaseRoutes from './invoices/pendingRelease';
import operationTypesCFOPRoutes from './operationTypesCFOP';
import { MyAccountPage } from 'pages/Account';
import transitTimesRoutes from './transitTimes';
import { Indicators } from 'pages/Dashboard';
import publicInvoiceRoute from './guest/publicInvoice';
import warehouseMemberRoutes from './warehouseMember';
import perfisRoutes from './perfils';
import usersRoutes from './users';
import schedulingRoutes from './scheduling';
import vehicleSetupsRoutes from './vehicleSetups';
import dockReservedIntervalsRoutes from './dockReservedIntervals';
import cancelationReasonsRoutes from './cancelationReasons';
import authenticatingRoute from './guest/authenticating';

const routes = (props: RouterProps): RouteObject[] => {
  const { isLoggedIn, location, hasPermissionTo } = props;

    const routeRedirectTo = () => {

        if(hasPermissionTo(['PENDINGINVOICES.VISUALIZE'])) {
           return <Navigate to="/inbound/notas-fiscais/pendentes-de-liberacao" />
        }

        if(hasPermissionTo(['TRACKING.VISUALIZE'])) {
            return <Navigate to="/inbound/notas-fiscais/tracking" />
        }


        if(hasPermissionTo(['DELIVEREDINVOICES.VISUALIZE'])) {
            return <Navigate to="/inbound/notas-fiscais/entregues" />
        }

    }


  return [
    {
      path: '/',
      element: isLoggedIn ? (
        <Outlet />
      ) : (
        <Navigate to="/login" state={{ from: location }} />
      ),
      children: [
        {
            path: '',
            element: routeRedirectTo(),
        },
        // {
        //   path: 'agendamento-calendario',
        //   element: <WarehouseSchedulePage />,
        // },
        adminsRoutes(props),
        auditorsRoutes(props),
        carriersRoutes(props),
        carrierMemberRoutes(props),
        companiesRoutes(props),
        clientsRoutes(props),
        reverseLogisticsRoutes(props),
        reverseLogisticsConfigurationRoutes(props),
        // productRoutes(props),
        suppliersRoutes(props),
        // productTypesRoutes(props),
        justificationsRoutes(props),
        vehicleTypesRoutes(props),
        transitTimesRoutes(props),
        warehouseMemberRoutes(props),
        perfisRoutes(props),
        usersRoutes(props),
        {
          path: 'configuracoes',
          element: <Navigate to="/configuracoes/empresas" />,
        },
        {
          path: 'meus-dados',
          element: <MyAccountPage />,
        },
        {
          path: 'dashboard',
          element: hasPermissionTo(['CARRIER-MEMBERS.FINDMEMBER', 'CARRIER-MEMBERS.FINDMEMBERS']) ? (
              <Indicators />
            ) : (
              <Navigate to="/" />
            ),
        },
        pendingReleaseRoutes(props),
        operationTypesCFOPRoutes(props),
        schedulingRoutes(props),
        vehicleSetupsRoutes(props),
        dockReservedIntervalsRoutes(props),
        cancelationReasonsRoutes(props),
      ],
    },
    loginRoute(props),
    forgotMyPasswordRoute(props),
    passwordResetRoute(props),
    publicInvoiceRoute(),
    authenticatingRoute()
  ];
};

export default routes;
