import { UpdateFormType } from "pages/Settings/Usuários/formSchema"
import { api } from "services"

interface Urls {
    [key: string]: string
}

interface Payload {
  data: UpdateFormType,
  id: string | number
}
const postUrls: Urls = {
    carrierMember: "/carrier-members",
    warehouseMember: "/company-members",
    admin: "/admins",
    clientMember: "/client-members",
}

export async function updateUser(payload: Payload) {
  const { data } = await api.put(`${postUrls[payload.data.type]}/${payload.id}`, payload.data)

  return data
}
