import { SortableHeader, SortableHeadersGroup } from 'components/Shared';
import { SortingParams } from 'contracts/Common';
import type { PaginatedOrder } from 'contracts/Orders';
import React from 'react';
import { Formatter } from 'utils';
import * as S from './styles';
import * as HoverCard from '@radix-ui/react-hover-card';

interface Props {
  orders: PaginatedOrder[];
  currentSort: SortingParams;
  onSort?: (sort: SortingParams) => void;
  onSelectOrder?: (orderId: number) => void;
}

interface ItemProps {
  order: PaginatedOrder;
  onSelectOrder?: (id: any) => void;
}

const Item = ({ order, onSelectOrder }: ItemProps): JSX.Element => {
  const {
    id,
    orderItems,
    schedulingDate,
    vehicleSetup,
    totalWeight,
    totalValue,
    client,
    carrier,
    status,
    orderReader,
    code
  } = order;

  return (
    <S.ListItem>
      <S.Column title={code}>{`${code}`.slice(0, 5)}</S.Column>
      <S.Column>
        <S.StatusLabel status={status as any}>
          {Formatter.orderStatusName(status as any)}
        </S.StatusLabel>
      </S.Column>
      <S.Column>{orderItems.length}</S.Column>
      <S.Column>
        {Formatter.date(schedulingDate, { format: 'dd/MM/yy HH:mm' })}
      </S.Column>
      <S.Column></S.Column>
      <S.Column>{Formatter.weight(totalWeight)}</S.Column>
      <S.Column>{Formatter.currency(totalValue)}</S.Column>
      <S.Column>{vehicleSetup.vehicleType?.name || '---'}</S.Column>
      <S.Column>{vehicleSetup.vehiclePlate}</S.Column>
      <S.Column title={client?.tradeName}>{client?.tradeName || "---"}</S.Column>
      <S.Column title={carrier.tradeName}>{carrier.tradeName}</S.Column>
      <S.ActionsColumn>
        {orderReader && (
          <>
            <HoverCard.Root>
              <HoverCard.Trigger asChild className="px-1">
                <a href="#" rel="noreferrer noopener">
                  <S.Eye />
                </a>
              </HoverCard.Trigger>
              <HoverCard.Portal>
                <S.HoverCardContent>
                  <S.HoverCardArrow />
                  <p>
                    {orderReader.user.name} <br />{' '}
                    {Formatter.date(orderReader.schedulingDate, {
                      format: 'dd/MM/yyyy - HH:mm',
                    })}
                  </p>
                </S.HoverCardContent>
              </HoverCard.Portal>
            </HoverCard.Root>
          </>
        )}
        <S.ButtonIcon
          title="Reagendar"
          state={order}
          to={`reagendar/${id}`}
        >
          <S.UpdateIcon />
        </S.ButtonIcon>
        <S.ButtonIcon
          title="Editar agendamento"
          state={order}
          to={`editar/${id}`}
        >
          <S.EditIcon />
        </S.ButtonIcon>
        <S.ActionButton title='Visualizar agendamento' mood="void" onClick={() => onSelectOrder?.(id)}>
          <S.ChevronIcon />
        </S.ActionButton>
      </S.ActionsColumn>
    </S.ListItem>
  );
};

const OrdersList: React.FC<Props> = ({
  orders,
  currentSort,
  onSort,
  onSelectOrder,
}) => {
  return (
    <S.List>
      <S.ListHeader>
        <SortableHeadersGroup onSort={onSort} currentSort={currentSort}>
          <SortableHeader column="id" label="COLETA" />
          <SortableHeader column="statusName" label="STATUS" />
          <SortableHeader column="itemsCount" label="DOCS" />
          <SortableHeader column="schedulingDate" label="DATA" />
          <SortableHeader column="dockName" label="DOCA" />
          <SortableHeader column="totalWeight" label="PESO" />
          <SortableHeader column="totalValue" label="VALOR" />
          <SortableHeader column="vehicleTypeName" label="VEÍCULO" />
          <SortableHeader column="vehiclePlate" label="PLACA" />
          <SortableHeader column="companyTradeName" label="CLIENTE" />
          <SortableHeader column="carrierTradeName" label="TRANSPORTADORA" />
        </SortableHeadersGroup>
      </S.ListHeader>
      {orders.map((order) => (
        <Item key={order.id} order={order} onSelectOrder={onSelectOrder} />
      ))}
    </S.List>
  );
};

export default OrdersList;
