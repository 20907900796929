import type { CompanyDocumentType, WithAddress } from 'contracts/Common';
import { format, isValid, formatISO, parseISO, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { format as formatZone } from 'date-fns-tz'
import { OrderStatus } from 'contracts/Orders';

export class Formatter {
  public static date(
    dateString: string | null,
    options = { format: 'dd/MM/yyyy' }
  ): string {
    if(!dateString) return '--';

    return formatZone(this.getValidDate(dateString), options.format, {
      locale: ptBR,
      timeZone: 'America/Sao_Paulo'
    });
  }
  
  public static cellPhone(number?: string): string {
    if (!number) return '--';
    return number.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3');
  }

  public static bytesToSize(bytes: number): string {
    const units = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte'];

    const navigatorLocal =
      navigator.languages && navigator.languages.length >= 0
        ? navigator.languages[0]
        : 'en-US';
    const unitIndex = Math.max(
      0,
      Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), units.length - 1)
    );

    return Intl.NumberFormat(navigatorLocal, {
      style: 'unit',
      unit: units[unitIndex],
    }).format(bytes / 1024 ** unitIndex);
  }

  public static datePicker(
    dateString: string,
    options = { format: 'yyyy-MM-dd' }
  ): string {
    return formatZone(this.getValidDate(dateString), options.format, {
      locale: ptBR,
      timeZone: 'America/Sao_Paulo'
    });
  }

  public static dateFull(
    dateString: string,
    options = { format: 'dd/MM/yyyy HH:mm:ss' }
  ): string {
    return formatZone(this.getValidDate(dateString), options.format, {
      locale: ptBR,
      timeZone: 'America/Sao_Paulo'
    });
  }

  public static dateToTime(
    dateString: string,
    options = { format: 'yyyy-MM-dd' }
  ): string {
    return formatZone(parse(dateString,'dd/MM/yyyy',new Date()), options.format);
  }

  public static dateToISO(
    dateString: string,
    options = { format: 'dd/MM/yyyy' }
  ): string {

    if (!isValid(new Date(dateString))) {
      return ''
    }

    const formatted = formatZone(parseISO(dateString), options.format, {
      locale: ptBR,
      timeZone: 'America/Sao_Paulo'
    })

    return formatISO(new Date(formatted));
  }

  public static dayOfWeek(
    dateString: string,
    options = { format: 'EEEE' }
  ): string {
    return format(this.getValidDate(dateString), options.format, {
      locale: ptBR,
    });
  }

  private static getValidDate(dateString: string): Date {
    const validString = dateString.replace(' ', 'T');
    if (!isValid(new Date(validString))) {
      throw new Error(
        `The string ${dateString} cannot be converted to a valid date.`
      );
    }
    return new Date(validString);
  }

  public static document(value: string, type?: CompanyDocumentType): string {
    if (type === 'other') {
      return value;
    }

    const clearValue = value.replace(/\D/g, '');

    if (clearValue.length === 11) {
      return this.cpf(clearValue);
    }

    return this.cnpj(clearValue);
  }

  public static cnpj(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }

  public static cpf(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  public static cep(value: string): string {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{3})(\d{3})/g, '$1.$2-$3');
  }

  public static weight(weight: number, unit = 'Kg'): string {
    return `${weight.toLocaleString('pt-BR')} ${unit}`;
  }

  public static currency(
    value: number,
    currency: 'BRL' | 'USD' = 'BRL'
  ): string {
    const locale = currency === 'BRL' ? 'pt-BR' : 'en-US';

    return value.toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    });
  }

  public static decimal(
    value: number,
  ): string {
    const locale = 'pt-BR';

    return value.toLocaleString(locale, {
      style: 'decimal',
      minimumFractionDigits: 3,
    });
  }

  public static minutesToTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;

    const h = hours < 10 ? `0${hours}` : `${hours}`;
    const m = minutesLeft < 10 ? `0${minutesLeft}` : `${minutesLeft}`;
    return `${h}:${m}`;
  }

  public static timeToMinutes(time: string): number {
    const [h, m] = time.split(':');
    return Number(h) * 60 + Number(m);
  }

  public static orderStatusName(orderStatus: OrderStatus): string {
    switch (orderStatus) {
      case 'chegada':
        return 'Chegada';
      case 'iniciado':
        return 'Iniciado';
      case 'finalizado':
        return 'Finalizado';
      case 'liberado':
        return 'Liberado';
      case 'cancelado':
        return 'Cancelado';
      case 'noShow':
        return 'No show';
      case 'todos':
        return 'Todos';
      case 'aguardando':
      default:
        return 'Aguardando';
    }
  }

  public static address(
    subject: Omit<WithAddress, 'addressLatitude' | 'addressLongitude' | 'ibge'>
  ): string {
    const {
      addressStreet,
      addressNumber,
      addressComplement,
      addressNeighborhood,
      addressUf,
      addressCity,
      addressZipcode,
      country,
    } = subject;

    let address = `${addressStreet}`;

    address += addressNumber ? `, ${addressNumber}` : ', S/N';
    address += addressComplement ? ` - ${addressComplement}` : '';
    address += addressNeighborhood ? `, ${addressNeighborhood}` : '';

    address += addressUf
      ? ` - ${addressCity}/${addressUf}`
      : ` - ${addressCity}`;

    address += addressZipcode ? `, CEP ${addressZipcode}` : '';

    address += country.id !== 1 ? ` - ${country.name}` : '';

    return address;
  }
}
