import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from 'components/ui/DataTable';
import {
  DataTableActions,
  IDataTableAction,
} from 'components/ui/DataTableActions';
import { Order } from 'contracts/Orders';
import { Formatter } from 'utils';

export interface OldListProps {
  onRowClick: (order: Order) => void;
}

export function useColumns({ onRowClick }: OldListProps) {
  const columns: ColumnDef<Order>[] = [
    {
      id: 'Coleta',
      accessorKey: 'code',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Coleta" />
      ),
      cell: ({ row }) => {
        return row.original.code;
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      id: 'Transportadora',
      accessorKey: 'carrier',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Transportadora" />
      ),
      cell: ({ row }) => {
        return row.original.carrier?.tradeName || '--';
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      id: 'Data',
      accessorKey: 'schedulingDate',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Dt. Agendada" />
      ),
      cell: ({ row }) => {
        return `${Formatter.date(row.original.schedulingDate, {
          format: 'dd/MM/yy HH:mm',
        })}`;
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      id: 'Saida',
      accessorKey: 'plannedDepartureTime',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Saída Planejada" />
      ),
      cell: ({ row }) => {
        return `${Formatter.date(row.original.plannedDepartureTime, {
          format: 'dd/MM/yy HH:mm',
        })}`;
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      id: 'Origem',
      accessorKey: 'company',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Origem" />
      ),
      cell: ({ row }) => {
        return row.original.company?.tradeName || '--';
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      id: 'Destino',
      accessorKey: 'email',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Destino" />
      ),
      cell: ({ row }) => {
        return row.original.client?.tradeName || '--';
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const actions: IDataTableAction[] = [
          {
            name: 'Visualizar',
            route: '',
            onPress: () => onRowClick(row.original),
          },
        ];
        return <DataTableActions actions={actions} />;
      },
    },
  ];

  return { columns };
}
