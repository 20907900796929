import { Modal } from 'components/Shared';
import type { Order } from 'contracts/Orders';
import * as S from './styles';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useImperativeHandle,
  useState,
} from 'react';
import { MainGrid } from './MainGrid';

export interface ModalOldRef {
  showOrder: (order: Order) => void;
}

interface IDockCreationModal
  extends ForwardRefExoticComponent<RefAttributes<ModalOldRef>> {}

export const ModalOld: IDockCreationModal = forwardRef<ModalOldRef>(
  (_, ref) => {
    const [order, setOrder] = useState<Order | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        showOrder: (orderToShow: Order) => setOrder(orderToShow),
      }),
      []
    );

    return (
      <Modal
        isOpen={!!order}
        onClickOutside={() => {
          setOrder(null);
        }}
      >
        <S.ModalContent style={{ maxWidth: '840px' }}>
          <S.ModalHeader>
            <S.ModalTitle>Detalhamento</S.ModalTitle>
            <S.ModalCloseButton onClick={() => setOrder(null)} />
          </S.ModalHeader>
          <S.ModalBody>
            <MainGrid order={order} />
          </S.ModalBody>
        </S.ModalContent>
      </Modal>
    );
  }
);
