import { OrderItem } from 'contracts/OrderItems';
import React from 'react';
import { Formatter } from 'utils';
import * as S from './styles';

interface Props {
  items: OrderItem[];
}

export const OrderItems: React.FC<Props> = ({ items }) => {
  return (
    <S.List>
      <S.ListHeader>
        <div>Documento</div>
        <div>Peso</div>
        <div>Valor</div>
        <div></div>
      </S.ListHeader>
      {items.map((item) => (
        <S.ListItem key={item.id}>
          <S.Column>{item.document}</S.Column>
          <S.Column>{Formatter.weight(item.weight)}</S.Column>
          <S.Column>{Formatter.currency(item.value)}</S.Column>
          <a
            title="Visualizar NF"
            className={
              item.invoiceId
                ? `p-1 rounded-md [&>svg]:text-slate-500 [&>svg]:hover:text-slate-600 [&>svg]:transition-all`
                : 'p-1 [&>svg]:text-slate-300 cursor-default'
            }
            href={
              item.invoiceId
                ? `/inbound/notas-fiscais/pendentes-de-liberacao/${item.invoiceId}`
                : undefined
            }
            target="_blank"
          >
            <S.ChevronIcon />
          </a>
          <S.Column></S.Column>
        </S.ListItem>
      ))}
    </S.List>
  );
};
