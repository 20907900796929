import * as S from '../../styles';
import type { IBase } from "../types";

interface CancelationReasonProps extends IBase {}

export const CancelationReasonAlert = ({
  order,
}: CancelationReasonProps): JSX.Element => {
  if (!order?.cancelationReason) return <></>;
  return (
    <S.Alert>
      <S.AlertTitle>Justificativa do cancelamento:</S.AlertTitle>
      <S.AlertMessage>{order.cancelationReason.name}</S.AlertMessage>
    </S.Alert>
  );
};