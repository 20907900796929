import { Formatter } from 'utils';
import * as S from '../../styles';
import { IBase } from '../types';

interface WarehouseGridProps extends IBase {}

export const WarehouseGrid = ({ order }: WarehouseGridProps): JSX.Element => {
  if (!order) return <></>;

  const { company } = order;

  return (
    <S.WarehouseGrid>
      <S.Detail>
        <S.DetailLabel>Armazém</S.DetailLabel>
        <S.DetailValue>{company.tradeName}</S.DetailValue>
      </S.Detail>
      <S.Detail>
        <S.DetailLabel>Endereço do armazém</S.DetailLabel>
        <S.DetailValue>{Formatter.address(company)}</S.DetailValue>
      </S.Detail>
    </S.WarehouseGrid>
  );
};