import styled from "styled-components";
import { Colors, Fonts } from "styles/constants";

export { ModalContent, ModalHeader, ModalBody } from "styles/components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const baseGrid = `0.6fr 0.7fr 1.5fr 1.5fr 1.5fr 1fr`

export const HeaderList = styled.div`
  display: grid;
  grid-template-columns: ${baseGrid};
  font-family: ${Fonts.GilroyBold};
  font-size: 14px;
  margin-bottom: 8px;
  padding: 10px;

  span {
    padding-right: 5px;
  }
`

export const Row = styled.button`
  display: grid;
  grid-template-columns: ${baseGrid};
  font-size: 14px;
  font-family: ${Fonts.GilroyRegular};
  border-radius: 7px;
  padding: 10px;

  :hover {
    background: ${Colors.Gray30};
  }

  span {
    display: block;
    text-align: justify;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
  }
`