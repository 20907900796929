import type { FormHandles } from '@unform/core';
import { useEffect, useRef } from 'react';
import * as S from './styles';
import { FormPageHeader } from 'components/Shared';
import * as Tabs from 'components/Shared/Tabs';
import * as C from '../Constants';
import { Load } from './Forms/Load';
import { Unload } from './Forms/Unload';
import { useValidation } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Loading } from 'components/Shared/Loading';
import { RescheduleOrderActions } from 'store/ducks/orders';
import { useNavigate } from 'react-router-dom';
import { Cleaner } from 'utils';
import { OldList } from './List';
import { RescheduleOrderValidator } from 'validators/Orders';
import { ModalOld, ModalOldRef } from './ModalOld';

export const RescheduleForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading: rescheduling } = useSelector(
    (state: RootState) => state.rescheduleOrder
  );
  const { data, loading } = useSelector((state: RootState) => state.fetchOrder);

  const loadRef = useRef<FormHandles>(null);
  const unloadRef = useRef<FormHandles>(null);
  const tabsRef = useRef<Tabs.Ref>(null);
  const modalRef = useRef<ModalOldRef>(null);

  const { handleFormErrors } = useValidation();

  const handleSubmit = async () => {
    if (loading) return;

    const loadData = loadRef.current?.getData() as any;
    const unloadData = unloadRef.current?.getData() as any;

    loadRef.current?.setErrors({});
    unloadRef.current?.setErrors({});

    let hasError: any = null;

    const abort = {
      abortEarly: false,
    };

    const allData: any = Cleaner.removeEmptyFields(
      Object.assign(loadData, unloadData)
    );

    const {
      schema: { loadSchema, unloadSchema },
    } = new RescheduleOrderValidator({ ...allData });

    await loadSchema.validate(allData, abort).catch((error) => {
      hasError = true;
      handleFormErrors(error, loadRef);
    });

    await unloadSchema.validate(allData, abort).catch((error) => {
      hasError = true;
      handleFormErrors(error, unloadRef);
    });

    if (hasError) return;

    dispatch(
      RescheduleOrderActions.request(data?.id, allData, () =>
        navigate(`/agendamento`)
      )
    );
  };

  const fillFieldValues = () => {
    const selectFields = [
      'vehicleSetupId',
      'fleetType',
      'companyId',
      'carrierId',
      'clientId',
    ];

    if (data) {
      const { company, carrier, client, vehicleSetup, fleetType } = data;

      const companyOption = {
        label: company.tradeName,
        value: company.id,
      };
      const carrierOption = {
        label: carrier.tradeName,
        value: carrier.id,
      };
      const clientOption = {
        label: client?.tradeName,
        value: client?.id,
      };
      const vehicleSetupOption = {
        value: vehicleSetup.id,
        label: `${vehicleSetup.code} ▪ ${vehicleSetup.vehicleType?.name} (${vehicleSetup.cargoType?.name}) ${vehicleSetup.vehiclePlate}`,
      };

      const fleetTypeOption = C.optionsFleet.find(
        ({ value }) => value === fleetType
      );

      Object.entries(data).forEach(([key, value]) => {
        if (selectFields.includes(key)) return;

        if (value !== null && value !== undefined) {
          loadRef.current?.setFieldValue(key, value);
          unloadRef.current?.setFieldValue(key, value);
        }
      });

      loadRef.current?.setFieldValue('carrierId', carrierOption);
      loadRef.current?.setFieldValue('companyId', companyOption);
      loadRef.current?.setFieldValue('vehicleSetupId', vehicleSetupOption);
      loadRef.current?.setFieldValue('fleetType', fleetTypeOption);
      unloadRef.current?.setFieldValue('clientId', clientOption);
    }
  };

  useEffect(() => {
    fillFieldValues();
  }, [data]);

  return (
    <S.Container>
      <S.Panel>
        <FormPageHeader
          title={`Reagendar ${data?.code}`}
          icon={<S.CalendarIcon />}
          showBorder={false}
          removeSpacing={true}
          actions={
            <>
              <S.LinkButton
                size="small"
                to={'/agendamento'}
                style={{ pointerEvents: 'auto' }}
              >
                <S.ArrowLeftIcon /> Voltar
              </S.LinkButton>
              {!data?.vehicleArrivalCompany && (
                <S.Button
                  size="small"
                  mood="secondary"
                  className="p-0"
                  onClick={handleSubmit}
                  disabled={rescheduling || loading}
                >
                  {rescheduling ? <Loading color="white" /> : <S.CheckIcon />}{' '}
                  Reagendar
                </S.Button>
              )}
            </>
          }
        />
        <Tabs.Controller onTabChange={(i) => tabsRef?.current?.selectTab(i)}>
          <Tabs.ModalTab title="Carregamento" />
          <Tabs.ModalTab title="Descarregamento" />
          <Tabs.ModalTab title="Histórico" />
        </Tabs.Controller>
        <Tabs.Outlet ref={tabsRef}>
          <Load formRef={loadRef} data={data} />
          <Unload formRef={unloadRef} data={data} />
          <OldList
            orderCode={data?.code}
            onRowClick={(order) => modalRef.current?.showOrder(order)}
          />
        </Tabs.Outlet>
      </S.Panel>
      <ModalOld ref={modalRef} />
    </S.Container>
  );
};
