import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  CompactCheckbox,
  CompactInput,
  CompactSelect,
} from 'components/Shared';
import { CHECKBOX_OPTIONS } from 'constants/Checkboxes';
import { FindMany, SelectOption } from 'contracts/Common';
import { OrderStatus } from 'contracts/Orders';
import { useCompanies } from 'hooks';
import { throttle } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as S from './styles';

export interface FindOrders extends FindMany {
  companyId?: unknown;
  date?: unknown;
  fromDate?: unknown;
  toDate?: unknown;
  status?: string[];
}

interface Props {
  onFilter?: (query: FindOrders) => void;
  cacheFilter?: FindOrders;
}

const initialFilter = {
  page: 1,
  limit: 10,
  status: [
    'aguardando',
    'chegada',
    'iniciado',
    'finalizado',
    'liberado',
    'noShow',
    'cancelado',
    'todos',
  ],
};

const Filters: React.FC<Props> = ({ cacheFilter, onFilter }) => {
  const formRef = useRef<FormHandles>(null);
  const { companyOptions, loadingCompanies, fetchCompanies } = useCompanies();

  const [filters, setFilters] = useState<FindOrders>({ ...initialFilter });

  const canClearFilter = useMemo(() => {
    const fields = ['companyId', 'fromDate', 'toDate'];
    const statusField = CHECKBOX_OPTIONS.ORDER_STATUS.some(
      ({ value }) => !filters.status?.includes(String(value))
    );

    return (
      statusField ||
      Object.entries(filters).some(
        ([key, value]) => fields.includes(key) === Boolean(value)
      )
    );
  }, [filters]);

  const handleClear = useCallback(() => {
    const fields = ['companyId', 'fromDate', 'toDate'];

    fields.forEach((field) => formRef.current?.clearField(field));

    setFilters({
      ...initialFilter,
      dirty: true,
      companyId: undefined,
      fromDate: undefined,
      toDate: undefined,
    });
  }, []);

  const onInputChange = useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);

      if (isValidDate || !value) {
        setFilters((state) => ({
          ...state,
          dirty: true,
          [name]: value,
        }));
      }
    },
    []
  );

  const onWarehouseChange = useCallback((e: SelectOption | null) => {
    setFilters((state) => ({
      ...state,
      dirty: true,
      companyId: e?.value,
    }));
  }, []);

  const onStatusChange = useCallback((options: string[]): void => {
    setFilters((state) => ({
      ...state,
      dirty: true,
      status: options as OrderStatus[],
    }));
  }, []);

  const setDefaultFilter = useCallback(
    (cached?: FindOrders) => {
      if (cached?.status) {
        formRef.current?.setFieldValue('status', cached.status);
      }
      formRef.current?.setFieldValue('fromDate', cached?.fromDate);
      formRef.current?.setFieldValue('toDate', cached?.toDate);

      if (cached?.companyId && companyOptions) {
        const currentCompany = companyOptions.find(
          ({ value }) => value === cached.companyId
        );

        if (currentCompany) {
          formRef.current?.setFieldValue('companyId', currentCompany);
        }
      }
    },
    [companyOptions]
  );

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const invokeFilter = useCallback((): void => {
    if (onFilter && filters.dirty) onFilter(filters);
  }, [filters]);

  useEffect(() => {
    invokeFilter();
  }, [invokeFilter]);

  useEffect(() => {
    const hasCache = cacheFilter && Object.keys(cacheFilter).length;
    if (hasCache) {
      setDefaultFilter(cacheFilter);
    } else {
      setDefaultFilter(initialFilter);
    }
  }, [setDefaultFilter]);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={() => {}}>
        <CompactSelect
          name="companyId"
          placeholder="Armazém"
          options={companyOptions}
          onChange={onWarehouseChange}
          isLoading={loadingCompanies}
        />
        <CompactInput
          name="fromDate"
          type="date"
          placeholder="Data inicial"
          label="De"
          direction="mixed"
          onChange={onInputChange}
        />
        <CompactInput
          name="toDate"
          type="date"
          placeholder="Data final"
          label="Até"
          direction="mixed"
          onChange={onInputChange}
        />
        <S.ClearButton
          type="button"
          onClick={handleClear}
          disabled={!canClearFilter}
        >
          <S.ClearIcon />
        </S.ClearButton>
        <CompactCheckbox
          name="status"
          options={CHECKBOX_OPTIONS.ORDER_STATUS}
          onChange={onStatusChange}
        />
      </Form>
    </S.Container>
  );
};

export default Filters;
