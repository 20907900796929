import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  DurationInput,
  Modal,
  ModalCloseButton,
  NumberInput,
  Select,
} from 'components/Shared';
import { MODAL_DISMISS_ACTION } from 'constants/Common';
import { SelectOption } from 'contracts/Common';
import { PaginatedCompanyVehicleSetup } from 'contracts/CompanyVehicleSetups';
import { useValidation, useVehicleSetups } from 'hooks';
import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { UpdateCompanyVehicleSetupActions as UpdateActions } from 'store/ducks/companyVehicleSetups';
import { Formatter } from 'utils';
import { UpdateCompanyVehicleSetupValidator as UpdateSetupValidator } from 'validators/CompanyVehicleSetups';
import * as S from './styles';

export interface Ref {
  selectSetup: (setup: PaginatedCompanyVehicleSetup) => void;
}

interface Props {
  onUpdate?: () => void;
}

interface ISetupUpdateModal
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

const SetupUpdateModal: ISetupUpdateModal = forwardRef<Ref, Props>(
  (props, ref) => {
    const { onUpdate } = props;
    const dispatch: AppDispatch = useDispatch();
    const formRef = useRef<FormHandles>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [setup, setSetup] = useState<
      PaginatedCompanyVehicleSetup | undefined
    >();

    const { handleFormErrors, handleApiErrors } = useValidation();
    const {
      vehicleSetups,
      vehicleSetupOptions,
      loadingVehicleSetups,
      enableVehicleSetupIfBlocked,
    } = useVehicleSetups();

    const setupLabel = useMemo((): string => {
      if (!setup) return '';
      const { vehicleType, cargoType } = setup.vehicleSetup;
      return `${vehicleType.name} (${cargoType.name})`;
    }, [setup]);

    const { loading: updatingSetup, validationErrors } = useSelector(
      (state: RootState) => state.updateCompanyVehicleSetup
    );

    const onSetupChange = useCallback((): void => {
      if (!setup || !formRef.current) return;

      const vehicleSetupOption = vehicleSetupOptions.find(
        (option) => option.value === setup.vehicleSetup.id
      );

      enableVehicleSetupIfBlocked(setup.vehicleSetup.id);

      formRef.current.setData({
        ...(vehicleSetupOption && { vehicleSetupId: vehicleSetupOption }),
        weightCapacity: setup.weightCapacity,
        loadDuration: Formatter.minutesToTime(setup.loadDuration),
        unloadDuration: Formatter.minutesToTime(setup.unloadDuration),
      });
    }, [enableVehicleSetupIfBlocked, setup, vehicleSetupOptions]);

    const onVehicleSetupChange = useCallback(
      (option: SelectOption | null): void => {
        if (!option) return;
        const selectedSetup = vehicleSetups.find(
          (setup) => setup.id === option.value
        );

        formRef.current?.setData({
          ...(selectedSetup && {
            weightCapacity: selectedSetup.weightCapacity,
            loadDuration: Formatter.minutesToTime(selectedSetup.loadDuration),
            unloadDuration: Formatter.minutesToTime(
              selectedSetup.unloadDuration
            ),
          }),
        });
      },
      [vehicleSetups]
    );

    const onModalClose = useCallback((): void => {
      formRef.current?.reset();
      dispatch(UpdateActions.reset());
      setSetup(undefined);
      setIsOpen(false);
    }, [dispatch]);

    const onUpdateSuccess = useCallback((): void => {
      onModalClose();
      onUpdate && onUpdate();
    }, [onModalClose, onUpdate]);

    const onSubmit = useCallback(
      async (data: any): Promise<void> => {
        try {
          formRef.current?.setErrors({});

          Object.assign(data, { companyId: setup?.company.id });

          const { schema } = new UpdateSetupValidator();

          const validData = await schema.validate(data, {
            abortEarly: false,
          });

          dispatch(
            UpdateActions.request(setup?.id, validData, onUpdateSuccess)
          );
        } catch (error) {
          handleFormErrors(error, formRef);
        }
      },
      [setup, dispatch, handleFormErrors, onUpdateSuccess]
    );

    useEffect(() => {
      onSetupChange();
    }, [onSetupChange]);

    useEffect(() => {
      handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    useEffect(() => {
      return () => {
        dispatch(UpdateActions.reset());
      };
    }, [dispatch]);

    useImperativeHandle(
      ref,
      () => ({
        selectSetup: (setup) => {
          setSetup(setup);
          setIsOpen(true);
        },
      }),
      []
    );

    return (
      <Modal isOpen={isOpen} onClickOutside={onModalClose}>
        <S.ModalContent style={{ maxWidth: '720px' }}>
          <S.ModalHeader>
            <h4>Atualizar vínculo com {setupLabel}</h4>
            <ModalCloseButton onClick={onModalClose} />
          </S.ModalHeader>
          <S.ModalBody>
            <Form ref={formRef} onSubmit={onSubmit}>
              <S.FormRow>
                <Select
                  name="vehicleSetupId"
                  label="Setup de veículo"
                  options={vehicleSetupOptions}
                  onChange={onVehicleSetupChange}
                  isLoading={loadingVehicleSetups}
                  menuPortalTarget={document.body}
                />
                <NumberInput
                  name="weightCapacity"
                  label="Capacidade de carga (Kg)"
                  suffix=" Kg"
                />
              </S.FormRow>
              <S.FormRow>
                <DurationInput
                  name="loadDuration"
                  label="Tempo de carregamento"
                />
                <DurationInput
                  name="unloadDuration"
                  label="Tempo de descarregamento"
                />
              </S.FormRow>
              <S.FormActions>
                <S.Button type="button" mood="light" onClick={onModalClose}>
                  {MODAL_DISMISS_ACTION}
                </S.Button>
                <S.Button type="submit">
                  {updatingSetup ? <S.ActivityIndicator /> : 'Salvar'}
                </S.Button>
              </S.FormActions>
            </Form>
          </S.ModalBody>
        </S.ModalContent>
      </Modal>
    );
  }
);

export default SetupUpdateModal;
