import { Combine } from "utils/TypesHelper";
import { z } from "zod";



const carrierMemberSchema = z.object({
    carrierId: z.array(
        z.object({
            value: z.any({ required_error: "Transportadora do usuário é obrigatória" }),
            label: z.string()
        })
    ),
})
const warehouseMemberSchema = z.object({
    companyId: z.array(
        z.object({
            value: z.any({ required_error: "Armazém do usuário é obrigatório" }),
            label: z.string()
        })
    ),
})
const clientMemberSchema = z.object({
    clientId: z.array(
        z.object({
            value: z.any({ required_error: "Cliente do usuário é obrigatório" }),
            label: z.string()
        })
    ),
})

const userSchema = z.object({
    user: z.object({
        name: z.string({ required_error: "Nome do usuário é obrigatório" }),
        email: z
            .string({ required_error: "Email do usuário é obrigatório" })
            .email("Email inválido"),
        password: z.string({ required_error: "Senha do usuário é obrigatório" }),
    })
})

const updateUserSchema = z.object({
    user: z.object({
        name: z.string({ required_error: "Nome do usuário é obrigatório" }),
        email: z
            .string({ required_error: "Email do usuário é obrigatório" })
            .email("Email inválido")
    })
})

export const updateFormSchema = z.discriminatedUnion('type', [
    updateUserSchema.extend({
        type: z.literal("carrierMember"),
    }).merge(carrierMemberSchema),
    updateUserSchema.extend({
        type: z.literal("warehouseMember"),
    }).merge(warehouseMemberSchema),
    updateUserSchema.extend({
        type: z.literal("clientMember"),
    }).merge(clientMemberSchema),
    updateUserSchema.extend({
        type: z.literal("admin"),
    }),
], {
    errorMap: (issue, ctx) => {
        if (issue.code === 'invalid_union_discriminator')
            return { message: `Selecione o valor.` }
        return { message: ctx.defaultError }
    },
})


export const formSchema = z.discriminatedUnion('type', [
    userSchema.extend({
        type: z.literal("carrierMember"),
    }).merge(carrierMemberSchema),
    userSchema.extend({
        type: z.literal("warehouseMember"),
    }).merge(warehouseMemberSchema),
    userSchema.extend({
        type: z.literal("clientMember"),
    }).merge(clientMemberSchema),
    userSchema.extend({
        type: z.literal("admin"),
    }),
], {
    errorMap: (issue, ctx) => {
        if (issue.code === 'invalid_union_discriminator')
            return { message: `Selecione o tipo de usuário.` }
        return { message: ctx.defaultError }
    },
})


export type FormType = Combine<z.infer<typeof formSchema>>
export type UpdateFormType = Combine<z.infer<typeof updateFormSchema>>