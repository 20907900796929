import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListOperationTypesActions as MainActions } from 'store/ducks/operationTypes';

interface FindOperationTypes {
  excludeBlocked?: boolean;
}

export const useOperationTypes = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: operationTypes, loading: loadingOperationTypes } = useSelector(
    (state: RootState) => state.listOperationTypes
  );

  const [ignoredBlockedId, enableOperationTypeIfBlocked] = useState<
    number | null
  >(null);

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchOperationTypes = useCallback(
    (query?: FindOperationTypes): void => {
      dispatch(MainActions.request({ ...query }));
    },
    [dispatch]
  );

  const onOperationTypesLoad = useCallback((): void => {
    setOptions(
      operationTypes.map(({ id, name, blockedAt }) => ({
        value: id,
        label: name,
        isDisabled: ignoredBlockedId !== id && Boolean(blockedAt),
      }))
    );
  }, [ignoredBlockedId, operationTypes]);

  useEffect(() => {
    onOperationTypesLoad();
  }, [onOperationTypesLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    operationTypes,
    operationTypesOptions: options,
    loadingOperationTypes,
    fetchOperationTypes,
    enableOperationTypeIfBlocked,
  };
};

export type OperationTypesHook = ReturnType<typeof useOperationTypes>;
