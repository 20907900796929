import React, { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Scaffold } from 'layouts';
import * as S from './styles';
import { OrderCreationForm } from 'components/Pages/Scheduling/CreationForm';
import { PageProps } from 'types/global';

export const OrderCreationPage: React.FC<PageProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const fromPathname = useMemo((): string => {
    return (location.state as { from?: string })?.from || '/agendamento';
  }, [location]);

  const onCreate = useCallback((): void => {
    navigate(fromPathname);
  }, [fromPathname, navigate]);

  return (
    <Scaffold {...props}>
      <S.PageContainer>
        <OrderCreationForm
          // onCreate={onCreate}
          // backActionPathname={fromPathname}
        />
      </S.PageContainer>
    </Scaffold>
  );
};
