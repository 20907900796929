import { InvoiceRecalculateTypes } from './../ducks/pendingRelease/recalculateInvoice';
import {
  CreateTransitTimeTypes,
  FetchTransitTimeTypes,
  ListTransitTimesTypes,
  PaginateTransitTimesTypes,
  UpdateTransitTimeTypes,
  RecalculateTransitTimeTypes,
} from 'store/ducks/transitTime';
import { all, takeLatest } from 'redux-saga/effects';
// ducks
import {
  CreateAdminTypes,
  DeleteAdminTypes,
  FetchAdminTypes,
  PaginateAdminsTypes,
  UpdateAdminTypes,
} from '../ducks/admins';
import {
  CreateCarrierTypes,
  FetchCarrierTypes,
  ListCarriersTypes,
  PaginateCarriersTypes,
  UpdateCarrierTypes,
} from '../ducks/carriers';
import {
  CreateCarrierMemberTypes,
  DeleteCarrierMemberTypes,
  FetchCarrierMemberTypes,
  PaginateCarrierMembersTypes,
  UpdateCarrierMemberTypes,
} from '../ducks/carrierMembers';
import {
  AbonedInvoiceJustificationTypes,
  AbonedInvoiceJustificationsTypes,
  CreateInvoiceJustificationTypes,
  CreateManyInvoiceJustificationsTypes,
  FetchInvoiceJustificationTypes,
  ListInvoiceJustificationsTypes,
} from '../ducks/invoiceJustifications';
import {
  CreateJustificationTypes,
  FetchJustificationTypes,
  ListJustificationsTypes,
  PaginateJustificationsTypes,
  UpdateJustificationTypes,
} from '../ducks/justifications';
import {
  CreateJustificationReverseLogisticsTypes,
  FetchJustificationReverseLogisticsTypes,
  ListJustificationsReverseLogisticsTypes,
  PaginateJustificationsReverseLogisticsTypes,
  UpdateJustificationReverseLogisticsTypes,
} from '../ducks/justificationsReverseLogistics';
import {
  CreateVehicleTypeTypes,
  FetchVehicleTypeTypes,
  ListVehicleTypesTypes,
  PaginateVehicleTypesTypes,
  UpdateVehicleTypeTypes,
} from '../ducks/vehicleTypes';
import {
  LoginTypes,
  ImpersonateTypes,
  LogoutTypes,
  UpdateAccountTypes,
  GeneratePasswordResetTokenTypes,
  ResetPasswordTypes,
} from '../ducks/auth';
import { ListCountriesTypes } from './../ducks/countries/listCountries';
import { ListCitiesTypes } from './../ducks/cities/listCities';
import { ListProductUnitsTypes } from './../ducks/productUnits/listProductUnits';
import {
  CreateCompanyTypes,
  FetchCompanyTypes,
  ListCompaniesTypes,
  PaginateCompaniesTypes,
  UpdateCompanyTypes,
} from '../ducks/companies';
import {
  CreateClientTypes,
  FetchClientTypes,
  ListClientsTypes,
  UpdateClientTypes,
  PaginateClientsTypes,
} from '../ducks/clients';
import {
  FetchAuditorTypes,
  ListAuditorsTypes,
  PaginateAuditorsTypes,
  CreateAuditorTypes,
  UpdateAuditorTypes,
} from '../ducks/auditors';
import {
  FetchReasonTypes,
  ListReasonsTypes,
  PaginateReasonsTypes,
  CreateReasonTypes,
  UpdateReasonTypes,
} from '../ducks/reasons';
import {
  FetchClassificationTypes,
  ListClassificationsTypes,
  PaginateClassificationsTypes,
  CreateClassificationTypes,
  UpdateClassificationTypes,
} from '../ducks/classifications';
import {
  FetchCollectTypes,
  ListCollectsTypes,
  PaginateCollectsTypes,
  CreateCollectTypes,
  FetchDocumentTypes,
  ExportCollectsTypes,
} from '../ducks/collects';
import {
  CreateSupplierTypes,
  FetchSupplierTypes,
  ListSuppliersTypes,
  PaginateSuppliersTypes,
  UpdateSupplierTypes,
} from '../ducks/suppliers';
import { FetchAddressByZipcodeTypes } from '../ducks/addressLookup';
import {
  CreateProductTypes,
  FetchProductTypes,
  ListProductsTypes,
  PaginateProductsTypes,
  UpdateProductTypes,
} from '../ducks/products';
import {
  FetchOperationTypeTypes,
  ListOperationTypesTypes,
  PaginateOperationTypesTypes,
} from '../ducks/operationTypes';
import {
  PaginateOperationTypesCfopTypes,
  CreateOperationTypesCfopTypes,
  ToggleDisableOperationTypeCFOPTypes,
} from '../ducks/operationsTypesCfop';
import {
  CreateProductTypeTypes,
  FetchProductTypeTypes,
  ListProductTypesTypes,
  PaginateProductTypesTypes,
  UpdateProductTypeTypes,
} from '../ducks/productTypes';
import {
  PaginatePendingReleaseTypes,
  FetchPendingReleaseTypes,
  UpdateInvoiceStatusTypes,
  UpdateInvoiceDeadlineTypes,
  UpdateInvoiceDeliveryDateTypes,
  ReleaseInvoicesToTrackingTypes,
  UpdateInvoiceCarrierTypes,
  CreateCorrectionLetterTypes,
  FetchCorrectionLetterTypes,
  DisablePendingInvoiceTypes,
} from '../ducks/pendingRelease';
import { FetchCarriersListTypes } from 'store/ducks/carriers';
import { FetchStatusListTypes } from 'store/ducks/status';
import { PaginateReleasedInvoicesTypes } from 'store/ducks/releasedInvoices';
import {
  PaginateDeliveredInvoicesTypes,
  UpdateSAPCodeInvoiceTypes,
  IgnoreInvoiceTypes,
} from 'store/ducks/deliveredInvoices';
import { FetchPublicInvoiceTypes } from 'store/ducks/publicInvoice';

// sagas
import {
  createAdminRequest,
  deleteAdminRequest,
  fetchAdminRequest,
  paginateAdminsRequest,
  updateAdminRequest,
} from './admins';
import {
  createCarrierRequest,
  fetchCarrierRequest,
  listCarriersRequest,
  paginateCarriersRequest,
  updateCarrierRequest,
} from './carriers';
import {
  createCarrierMemberRequest,
  deleteCarrierMemberRequest,
  fetchCarrierMemberRequest,
  paginateCarrierMembersRequest,
  updateCarrierMemberRequest,
} from './carrierMembers';
import {
  createWarehouseMemberRequest,
  deleteWarehouseCompanyMemberRequest,
  deleteWarehouseMemberRequest,
  fetchWarehouseMemberRequest,
  paginateWarehouseMembersRequest,
  updateWarehouseMemberRequest,
} from './warehouseMembers';
import {
  createCompanyRequest,
  fetchCompanyRequest,
  listCompaniesRequest,
  paginateCompaniesRequest,
  updateCompanyRequest,
} from './companies';
import {
  createClientRequest,
  fetchClientRequest,
  listClientsRequest,
  updateClientRequest,
  paginateClientsRequest,
} from './clients';
import {
  fetchAuditorRequest,
  listAuditorsRequest,
  paginateAuditorsRequest,
  createAuditorRequest,
  updateAuditorRequest,
} from './auditors';
import {
  fetchReasonRequest,
  listReasonsRequest,
  paginateReasonsRequest,
  createReasonRequest,
  updateReasonRequest,
} from './reasons';
import {
  fetchClassificationRequest,
  listClassificationsRequest,
  paginateClassificationsRequest,
  createClassificationRequest,
  updateClassificationRequest,
} from './classifications';
import {
  fetchCollectRequest,
  listCollectsRequest,
  paginateCollectsRequest,
  createCollectRequest,
  fetchDocumentRequest,
  exportCollectsRequest,
} from './collects';
import {
  createSupplierRequest,
  fetchSupplierRequest,
  listSuppliersRequest,
  paginateSuppliersRequest,
  updateSupplierRequest,
} from './suppliers';
import { listCountriesRequest } from './countries';
import { listCitiesRequest } from './cities';
import { listProductUnitsRequest } from './productUnits';
import { rehydrateToken } from './hydration';
import {
  loginRequest,
  impersonateRequest,
  logoutRequest,
  updateAccountRequest,
  generatePasswordResetTokenRequest,
  resetPasswordRequest,
} from './auth';
import { fetchAddressByZipcodeRequest } from './addressLookup';
import {
  createProductRequest,
  fetchProductRequest,
  listProductsRequest,
  paginateProductsRequest,
  updateProductRequest,
} from './products';
import {
  fetchOperationTypeRequest,
  listOperationTypesRequest,
  paginateOperationTypesRequest,
} from './operationTypes';
import {
  createOperationTypesCfopRequest,
  paginateOperationTypesCfopRequest,
  toggleDisableOperationTypeCfopRequest,
} from './operationTypesCfop';
import {
  createProductTypeRequest,
  fetchProductTypeRequest,
  listProductTypesRequest,
  paginateProductTypesRequest,
  updateProductTypeRequest,
} from './productTypes';
import {
  paginatePendingReleaseRequest,
  fetchPendingReleaseRequest,
  updateInvoiceStatusRequest,
  updateInvoiceDeadlineRequest,
  updateInvoiceDeliveryDateRequest,
  releaseInvoicesToTrackingRequest,
  updateInvoiceCarrierRequest,
  createCorrectionLetterRequest,
  fetchCorrectionLetterRequest,
  disablePendingInvoiceRequest,
} from './pendingRelease';
import { fetchCarriersListRequest } from './carriers';
import { fetchStatusListRequest } from './status';
import { paginateReleasedInvoicesRequest } from './releasedInvoices';
import {
  paginateDeliveredInvoicesRequest,
  updateSAPCodeInvoiceRequest,
  IgnoreInvoiceRequest,
} from './deliveredInvoices';
import { PaginateFinishedInvoicesTypes } from 'store/ducks/finishedInvoices';
import { paginateFinishedInvoicesRequest } from './finishedInvoices';
import {
  createInvoiceJustificationRequest,
  createManyInvoiceJustificationsRequest,
  fetchInvoiceJustificationRequest,
  listInvoiceJustificationsRequest,
  abonedInvoiceJustificationRequest,
  abonedInvoiceJustificationsRequest,
} from './InvoiceJustifications';
import {
  createJustificationRequest,
  fetchJustificationRequest,
  listJustificationsRequest,
  paginateJustificationsRequest,
  updateJustificationRequest,
} from './justifications';
import {
  createJustificationReverseLogisticsRequest,
  fetchJustificationReverseLogisticsRequest,
  listJustificationsReverseLogisticsRequest,
  paginateJustificationsReverseLogisticsRequest,
  updateJustificationReverseLogisticsRequest,
} from './justificationsReverseLogistics';
import {
  createVehicleTypeRequest,
  fetchVehicleTypeRequest,
  listVehicleTypesRequest,
  paginateVehicleTypesRequest,
  updateVehicleTypeRequest,
} from './vehicleTypes';
import { fetchTransitTimeRequest } from './transitTime/fetchTransitTime';
import { invoiceRecalculateTypesRequest } from './pendingRelease/recalculateInvoice';
import {
  createTransitTimeRequest,
  listTransitTimesRequest,
  paginateTransitTimesRequest,
  updateTransitTimeRequest,
  recalculateTransitTimeRequest,
} from './transitTime';
import { ExportInvoicesTypes } from 'store/ducks/exportInvoices';
import { exportInvoicesRequest } from './exportInvoices';
import { fetchPublicInvoiceRequest } from './publicInvoice';
import {
  CreateWarehouseMemberTypes,
  DeleteWarehouseCompanyMemberTypes,
  DeleteWarehouseMemberTypes,
  FetchWarehouseMemberTypes,
  PaginateWarehouseMembersTypes,
  UpdateWarehouseMemberTypes,
} from 'store/ducks/warehouseMembers';
import { FetchJustificationTypesTypes } from 'store/ducks/justificationTypes';
import { fetchJustificationTypesRequest } from './justificationTypes';
import { CreateCargoTypeTypes, FetchCargoTypeTypes, ListCargoTypesTypes, PaginateCargoTypesTypes, UpdateCargoTypeTypes } from 'store/ducks/cargoTypes';
import { FetchOrderTypeTypes, ListOrderTypesTypes } from 'store/ducks/orderTypes';
import { ListSchedulingDocksTypes, ListSchedulingVehicleSetupsTypes, ListWarehouseScheduleEntriesTypes } from 'store/ducks/scheduling';
import { listSchedulingDocksRequest, listSchedulingVehicleSetupsRequest, listWarehouseScheduleEntriesRequest } from './scheduling';
import { createCargoTypeRequest, fetchCargoTypeRequest, listCargoTypesRequest, paginateCargoTypesRequest, updateCargoTypeRequest } from './cargoTypes';
import { fetchOrderTypeRequest, listOrderTypesRequest } from './orderTypes';
import { CreateOrderTypes, ExportOrdersTypes, FetchOrderTypes, ListOldOrdersTypes, PaginateOrdersTypes, RescheduleOrderTypes, UpdateOrderCancelationReasonTypes, UpdateOrderDriverAndVehicleTypes, UpdateOrderEventsTypes, UpdateOrderNoShowTypes, UpdateOrderTypes } from 'store/ducks/orders';
import { createOrderRequest, exportOrdersRequest, fetchOrderRequest, listOldOrdersRequest, paginateOrdersRequest, rescheduleOrderRequest, updateOrderCancelationReasonRequest, updateOrderDriverAndVehicleRequest, updateOrderEventsRequest, updateOrderNoShowRequest, updateOrderRequest } from './orders';
import { CreateCancelationReasonTypes, FetchCancelationReasonTypes, ListCancelationReasonsTypes, PaginateCancelationReasonsTypes, UpdateCancelationReasonTypes } from 'store/ducks/cancelationReasons';
import { createCancelationReasonRequest, fetchCancelationReasonRequest, listCancelationReasonsRequest, paginateCancelationReasonsRequest, updateCancelationReasonRequest } from './cancelationReasons';
import { CreateDockTypes, FetchDockTypes, ListDocksTypes, PaginateDocksTypes, UpdateDockTypes } from 'store/ducks/docks';
import { createDockRequest, fetchDockRequest, listDocksRequest, paginateDocksRequest, updateDockRequest } from './docks';
import { CreateDockCarrierTypes, DeleteDockCarrierTypes } from 'store/ducks/dockCarriers';
import { CreateDockClientTypes, DeleteDockClientTypes } from 'store/ducks/dockClients';
import { CreateDockOperationTypeTypes, DeleteDockOperationTypeTypes } from 'store/ducks/dockOperationTypes';
import { createDockOperationTypeRequest, deleteDockOperationTypeRequest } from './dockOperationTypes';
import { CreateDockReservedIntervalTypes, DeleteDockReservedIntervalTypes, FetchDockReservedIntervalTypes, ListDockReservedIntervalsTypes, PaginateDockReservedIntervalsTypes, UpdateDockReservedIntervalTypes } from 'store/ducks/dockReservedIntervals';
import { createDockReservedIntervalRequest, deleteDockReservedIntervalRequest, fetchDockReservedIntervalRequest, listDockReservedIntervalsRequest, paginateDockReservedIntervalsRequest, updateDockReservedIntervalRequest } from './dockReservedIntervals';
import { createDockCarrierRequest, deleteDockCarrierRequest } from './dockCarriers';
import { createDockClientRequest, deleteDockClientRequest } from './dockClients';
import { CreateVehicleSetupTypes, FetchVehicleSetupTypes, ListVehicleSetupsTypes, PaginateVehicleSetupsTypes, UpdateVehicleSetupTypes } from 'store/ducks/vehicleSetups';
import { createVehicleSetupRequest, fetchVehicleSetupRequest, listVehicleSetupsRequest, paginateVehicleSetupsRequest, updateVehicleSetupRequest } from './vehicleSetups';
import { CreateCompanyVehicleSetupTypes, DeleteCompanyVehicleSetupTypes, FetchCompanyVehicleSetupTypes, ListCompanyVehicleSetupsTypes, PaginateCompanyVehicleSetupsTypes, UpdateCompanyVehicleSetupTypes } from 'store/ducks/companyVehicleSetups';
import { createCompanyVehicleSetupRequest, deleteCompanyVehicleSetupRequest, fetchCompanyVehicleSetupRequest, listCompanyVehicleSetupsRequest, paginateCompanyVehicleSetupsRequest, updateCompanyVehicleSetupRequest } from './warehouseVehicleSetups';
import { ListOrderItemInvoicesTypes } from 'store/ducks/orderItemInvoices';
import { listOrderItemInvoicesRequest } from './orderItemInvoices';
import { CreateCompanyCarrierTypes, DeleteCompanyCarrierTypes } from 'store/ducks/companyCarriers';
import { createCompanyCarrierRequest, deleteCompanyCarrierRequest } from './companyCarriers';
import { CreateCompanyClientTypes, DeleteCompanyClientTypes } from 'store/ducks/companyClients';
import { createCompanyClientRequest, deleteCompanyClientRequest } from './companyClients';
import { CreateCompanyVehicleTypeTypes, DeleteCompanyVehicleTypeTypes } from 'store/ducks/companyVehicleTypes';
import { createCompanyVehicleTypeRequest, deleteCompanyVehicleTypeRequest } from './companyVehicleTypes';
import { CreateCompanyOperationTypeTypes, DeleteCompanyOperationTypeTypes } from 'store/ducks/companyOperationTypes';
import { createCompanyOperationTypeRequest, deleteCompanyOperationTypeRequest } from './companyOperationTypes';
import { FetchOrderFileTypes } from 'store/ducks/orderFiles';
import { fetchOrderFileRequest } from './orderFiles';

export default function* rootSaga() {
  yield all([
    // hydration
    takeLatest('persist/REHYDRATE', rehydrateToken),
    // admins
    takeLatest(CreateAdminTypes.REQUEST, createAdminRequest),
    takeLatest(DeleteAdminTypes.REQUEST, deleteAdminRequest),
    takeLatest(FetchAdminTypes.REQUEST, fetchAdminRequest),
    takeLatest(PaginateAdminsTypes.REQUEST, paginateAdminsRequest),
    takeLatest(UpdateAdminTypes.REQUEST, updateAdminRequest),
    // auth
    takeLatest(LoginTypes.REQUEST, loginRequest),
    takeLatest(ImpersonateTypes.REQUEST, impersonateRequest),
    takeLatest(LogoutTypes.REQUEST, logoutRequest),
    takeLatest(UpdateAccountTypes.REQUEST, updateAccountRequest),
    takeLatest(
      GeneratePasswordResetTokenTypes.REQUEST,
      generatePasswordResetTokenRequest
    ),
    takeLatest(ResetPasswordTypes.REQUEST, resetPasswordRequest),
    // address lookup
    takeLatest(
      FetchAddressByZipcodeTypes.REQUEST,
      fetchAddressByZipcodeRequest
    ),
    // carriers
    takeLatest(CreateCarrierTypes.REQUEST, createCarrierRequest),
    takeLatest(FetchCarrierTypes.REQUEST, fetchCarrierRequest),
    takeLatest(ListCarriersTypes.REQUEST, listCarriersRequest),
    takeLatest(PaginateCarriersTypes.REQUEST, paginateCarriersRequest),
    takeLatest(UpdateCarrierTypes.REQUEST, updateCarrierRequest),
    // carrier members
    takeLatest(CreateCarrierMemberTypes.REQUEST, createCarrierMemberRequest),
    takeLatest(DeleteCarrierMemberTypes.REQUEST, deleteCarrierMemberRequest),
    takeLatest(FetchCarrierMemberTypes.REQUEST, fetchCarrierMemberRequest),
    takeLatest(
      PaginateCarrierMembersTypes.REQUEST,
      paginateCarrierMembersRequest
    ),
    takeLatest(UpdateCarrierMemberTypes.REQUEST, updateCarrierMemberRequest),
    // warehouse members
    takeLatest(
      CreateWarehouseMemberTypes.REQUEST,
      createWarehouseMemberRequest
    ),
    takeLatest(
      DeleteWarehouseMemberTypes.REQUEST,
      deleteWarehouseMemberRequest
    ),
    takeLatest(
      DeleteWarehouseCompanyMemberTypes.REQUEST,
      deleteWarehouseCompanyMemberRequest
    ),
    takeLatest(FetchWarehouseMemberTypes.REQUEST, fetchWarehouseMemberRequest),
    takeLatest(
      PaginateWarehouseMembersTypes.REQUEST,
      paginateWarehouseMembersRequest
    ),
    takeLatest(
      UpdateWarehouseMemberTypes.REQUEST,
      updateWarehouseMemberRequest
    ),
    // countries
    takeLatest(ListCountriesTypes.REQUEST, listCountriesRequest),
    // cities
    takeLatest(ListCitiesTypes.REQUEST, listCitiesRequest),
    // product units
    takeLatest(ListProductUnitsTypes.REQUEST, listProductUnitsRequest),
    // companies
    takeLatest(CreateCompanyTypes.REQUEST, createCompanyRequest),
    takeLatest(FetchCompanyTypes.REQUEST, fetchCompanyRequest),
    takeLatest(ListCompaniesTypes.REQUEST, listCompaniesRequest),
    takeLatest(PaginateCompaniesTypes.REQUEST, paginateCompaniesRequest),
    takeLatest(UpdateCompanyTypes.REQUEST, updateCompanyRequest),
    //clients
    takeLatest(CreateClientTypes.REQUEST, createClientRequest),
    takeLatest(FetchClientTypes.REQUEST, fetchClientRequest),
    takeLatest(ListClientsTypes.REQUEST, listClientsRequest),
    takeLatest(UpdateClientTypes.REQUEST, updateClientRequest),
    takeLatest(PaginateClientsTypes.REQUEST, paginateClientsRequest),
    //auditors
    takeLatest(FetchAuditorTypes.REQUEST, fetchAuditorRequest),
    takeLatest(ListAuditorsTypes.REQUEST, listAuditorsRequest),
    takeLatest(PaginateAuditorsTypes.REQUEST, paginateAuditorsRequest),
    takeLatest(CreateAuditorTypes.REQUEST, createAuditorRequest),
    takeLatest(UpdateAuditorTypes.REQUEST, updateAuditorRequest),
    //reasons
    takeLatest(FetchReasonTypes.REQUEST, fetchReasonRequest),
    takeLatest(ListReasonsTypes.REQUEST, listReasonsRequest),
    takeLatest(PaginateReasonsTypes.REQUEST, paginateReasonsRequest),
    takeLatest(CreateReasonTypes.REQUEST, createReasonRequest),
    takeLatest(UpdateReasonTypes.REQUEST, updateReasonRequest),
    //classifications
    takeLatest(FetchClassificationTypes.REQUEST, fetchClassificationRequest),
    takeLatest(ListClassificationsTypes.REQUEST, listClassificationsRequest),
    takeLatest(
      PaginateClassificationsTypes.REQUEST,
      paginateClassificationsRequest
    ),
    takeLatest(CreateClassificationTypes.REQUEST, createClassificationRequest),
    takeLatest(UpdateClassificationTypes.REQUEST, updateClassificationRequest),
    // collects
    takeLatest(FetchCollectTypes.REQUEST, fetchCollectRequest),
    takeLatest(ListCollectsTypes.REQUEST, listCollectsRequest),
    takeLatest(PaginateCollectsTypes.REQUEST, paginateCollectsRequest),
    takeLatest(CreateCollectTypes.REQUEST, createCollectRequest),
    takeLatest(FetchDocumentTypes.REQUEST, fetchDocumentRequest),
    takeLatest(ExportCollectsTypes.REQUEST, exportCollectsRequest),
    // pending release
    takeLatest(
      PaginatePendingReleaseTypes.REQUEST,
      paginatePendingReleaseRequest
    ),
    takeLatest(FetchPendingReleaseTypes.REQUEST, fetchPendingReleaseRequest),
    takeLatest(UpdateInvoiceStatusTypes.REQUEST, updateInvoiceStatusRequest),
    takeLatest(
      UpdateInvoiceDeadlineTypes.REQUEST,
      updateInvoiceDeadlineRequest
    ),
    takeLatest(
      UpdateInvoiceDeliveryDateTypes.REQUEST,
      updateInvoiceDeliveryDateRequest
    ),
    takeLatest(
      ReleaseInvoicesToTrackingTypes.REQUEST,
      releaseInvoicesToTrackingRequest
    ),
    takeLatest(UpdateInvoiceCarrierTypes.REQUEST, updateInvoiceCarrierRequest),
    takeLatest(
      CreateCorrectionLetterTypes.REQUEST,
      createCorrectionLetterRequest
    ),
    takeLatest(
      FetchCorrectionLetterTypes.REQUEST,
      fetchCorrectionLetterRequest
    ),
    takeLatest(
      DisablePendingInvoiceTypes.REQUEST,
      disablePendingInvoiceRequest
    ),
    // released Invoices
    takeLatest(
      PaginateReleasedInvoicesTypes.REQUEST,
      paginateReleasedInvoicesRequest
    ),
    // export Invoices
    takeLatest(ExportInvoicesTypes.REQUEST, exportInvoicesRequest),
    // delivered Invoices
    takeLatest(
      PaginateDeliveredInvoicesTypes.REQUEST,
      paginateDeliveredInvoicesRequest
    ),
    takeLatest(UpdateSAPCodeInvoiceTypes.REQUEST, updateSAPCodeInvoiceRequest),
    takeLatest(IgnoreInvoiceTypes.REQUEST, IgnoreInvoiceRequest),
    // finished Invoices
    takeLatest(
      PaginateFinishedInvoicesTypes.REQUEST,
      paginateFinishedInvoicesRequest
    ),
    // carriers
    takeLatest(FetchCarriersListTypes.REQUEST, fetchCarriersListRequest),
    // products
    takeLatest(CreateProductTypes.REQUEST, createProductRequest),
    takeLatest(FetchProductTypes.REQUEST, fetchProductRequest),
    takeLatest(ListProductsTypes.REQUEST, listProductsRequest),
    takeLatest(PaginateProductsTypes.REQUEST, paginateProductsRequest),
    takeLatest(UpdateProductTypes.REQUEST, updateProductRequest),
    // operation types
    takeLatest(FetchOperationTypeTypes.REQUEST, fetchOperationTypeRequest),
    takeLatest(ListOperationTypesTypes.REQUEST, listOperationTypesRequest),
    takeLatest(
      PaginateOperationTypesTypes.REQUEST,
      paginateOperationTypesRequest
    ),
    // operation types cfop
    takeLatest(
      CreateOperationTypesCfopTypes.REQUEST,
      createOperationTypesCfopRequest
    ),
    takeLatest(
      PaginateOperationTypesCfopTypes.REQUEST,
      paginateOperationTypesCfopRequest
    ),
    takeLatest(
      ToggleDisableOperationTypeCFOPTypes.REQUEST,
      toggleDisableOperationTypeCfopRequest
    ),
    // product types
    takeLatest(CreateProductTypeTypes.REQUEST, createProductTypeRequest),
    takeLatest(FetchProductTypeTypes.REQUEST, fetchProductTypeRequest),
    takeLatest(ListProductTypesTypes.REQUEST, listProductTypesRequest),
    takeLatest(PaginateProductTypesTypes.REQUEST, paginateProductTypesRequest),
    takeLatest(UpdateProductTypeTypes.REQUEST, updateProductTypeRequest),
    //recalculate invoices
    takeLatest(InvoiceRecalculateTypes.REQUEST, invoiceRecalculateTypesRequest),
    // suppliers
    takeLatest(CreateSupplierTypes.REQUEST, createSupplierRequest),
    takeLatest(FetchSupplierTypes.REQUEST, fetchSupplierRequest),
    takeLatest(ListSuppliersTypes.REQUEST, listSuppliersRequest),
    takeLatest(PaginateSuppliersTypes.REQUEST, paginateSuppliersRequest),
    takeLatest(UpdateSupplierTypes.REQUEST, updateSupplierRequest),
    // invoiceJustifications
    takeLatest(
      CreateInvoiceJustificationTypes.REQUEST,
      createInvoiceJustificationRequest
    ),
    takeLatest(
      CreateManyInvoiceJustificationsTypes.REQUEST,
      createManyInvoiceJustificationsRequest
    ),
    takeLatest(
      FetchInvoiceJustificationTypes.REQUEST,
      fetchInvoiceJustificationRequest
    ),
    takeLatest(
      ListInvoiceJustificationsTypes.REQUEST,
      listInvoiceJustificationsRequest
    ),
    takeLatest(
      AbonedInvoiceJustificationTypes.REQUEST,
      abonedInvoiceJustificationRequest
    ),
    takeLatest(
        AbonedInvoiceJustificationsTypes.REQUEST,
        abonedInvoiceJustificationsRequest
      ),
    //justifications - tracking
    takeLatest(CreateJustificationTypes.REQUEST, createJustificationRequest),
    takeLatest(FetchJustificationTypes.REQUEST, fetchJustificationRequest),
    takeLatest(ListJustificationsTypes.REQUEST, listJustificationsRequest),
    takeLatest(UpdateJustificationTypes.REQUEST, updateJustificationRequest),
    takeLatest(
      PaginateJustificationsTypes.REQUEST,
      paginateJustificationsRequest
    ),
    //justifications - reverseLogistics
    takeLatest(
      CreateJustificationReverseLogisticsTypes.REQUEST,
      createJustificationReverseLogisticsRequest
    ),
    takeLatest(
      FetchJustificationReverseLogisticsTypes.REQUEST,
      fetchJustificationReverseLogisticsRequest
    ),
    takeLatest(
      ListJustificationsReverseLogisticsTypes.REQUEST,
      listJustificationsReverseLogisticsRequest
    ),
    takeLatest(
      UpdateJustificationReverseLogisticsTypes.REQUEST,
      updateJustificationReverseLogisticsRequest
    ),
    takeLatest(
      PaginateJustificationsReverseLogisticsTypes.REQUEST,
      paginateJustificationsReverseLogisticsRequest
    ),
    //vehicle types
    takeLatest(CreateVehicleTypeTypes.REQUEST, createVehicleTypeRequest),
    takeLatest(FetchVehicleTypeTypes.REQUEST, fetchVehicleTypeRequest),
    takeLatest(ListVehicleTypesTypes.REQUEST, listVehicleTypesRequest),
    takeLatest(UpdateVehicleTypeTypes.REQUEST, updateVehicleTypeRequest),
    takeLatest(PaginateVehicleTypesTypes.REQUEST, paginateVehicleTypesRequest),
    // status
    takeLatest(FetchStatusListTypes.REQUEST, fetchStatusListRequest),
    //transit time
    takeLatest(CreateTransitTimeTypes.REQUEST, createTransitTimeRequest),
    takeLatest(FetchTransitTimeTypes.REQUEST, fetchTransitTimeRequest),
    takeLatest(ListTransitTimesTypes.REQUEST, listTransitTimesRequest),
    takeLatest(PaginateTransitTimesTypes.REQUEST, paginateTransitTimesRequest),
    takeLatest(
      RecalculateTransitTimeTypes.REQUEST,
      recalculateTransitTimeRequest
    ),
    takeLatest(UpdateTransitTimeTypes.REQUEST, updateTransitTimeRequest),
    takeLatest(FetchPublicInvoiceTypes.REQUEST, fetchPublicInvoiceRequest),
    //justification types
    takeLatest(
      FetchJustificationTypesTypes.REQUEST,
      fetchJustificationTypesRequest
    ),
    // cargo types
    takeLatest(CreateCargoTypeTypes.REQUEST, createCargoTypeRequest),
    takeLatest(FetchCargoTypeTypes.REQUEST, fetchCargoTypeRequest),
    takeLatest(ListCargoTypesTypes.REQUEST, listCargoTypesRequest),
    takeLatest(PaginateCargoTypesTypes.REQUEST, paginateCargoTypesRequest),
    takeLatest(UpdateCargoTypeTypes.REQUEST, updateCargoTypeRequest),
    // order types
    takeLatest(FetchOrderTypeTypes.REQUEST, fetchOrderTypeRequest),
    takeLatest(ListOrderTypesTypes.REQUEST, listOrderTypesRequest),
    // scheduling
    takeLatest(ListSchedulingDocksTypes.REQUEST, listSchedulingDocksRequest),
    takeLatest(
      ListSchedulingVehicleSetupsTypes.REQUEST,
      listSchedulingVehicleSetupsRequest
    ),
    takeLatest(
      ListWarehouseScheduleEntriesTypes.REQUEST,
      listWarehouseScheduleEntriesRequest
    ),
    // orders
    takeLatest(CreateOrderTypes.REQUEST, createOrderRequest),
    takeLatest(UpdateOrderTypes.REQUEST, updateOrderRequest),
    takeLatest(FetchOrderTypes.REQUEST, fetchOrderRequest),
    takeLatest(ListOldOrdersTypes.REQUEST, listOldOrdersRequest),
    takeLatest(RescheduleOrderTypes.REQUEST, rescheduleOrderRequest),
    takeLatest(PaginateOrdersTypes.REQUEST, paginateOrdersRequest),
    takeLatest(UpdateOrderEventsTypes.REQUEST, updateOrderEventsRequest),
    takeLatest(UpdateOrderNoShowTypes.REQUEST, updateOrderNoShowRequest),
    takeLatest(
      UpdateOrderCancelationReasonTypes.REQUEST,
      updateOrderCancelationReasonRequest
    ),
    takeLatest(
      UpdateOrderDriverAndVehicleTypes.REQUEST,
      updateOrderDriverAndVehicleRequest
    ),
    takeLatest(ExportOrdersTypes.REQUEST, exportOrdersRequest),
    // cancelation reasons
    takeLatest(
      CreateCancelationReasonTypes.REQUEST,
      createCancelationReasonRequest
    ),
    takeLatest(
      FetchCancelationReasonTypes.REQUEST,
      fetchCancelationReasonRequest
    ),
    takeLatest(
      ListCancelationReasonsTypes.REQUEST,
      listCancelationReasonsRequest
    ),
    takeLatest(
      PaginateCancelationReasonsTypes.REQUEST,
      paginateCancelationReasonsRequest
    ),
    takeLatest(
      UpdateCancelationReasonTypes.REQUEST,
      updateCancelationReasonRequest
    ),
    // docks
    takeLatest(CreateDockTypes.REQUEST, createDockRequest),
    takeLatest(FetchDockTypes.REQUEST, fetchDockRequest),
    takeLatest(ListDocksTypes.REQUEST, listDocksRequest),
    takeLatest(PaginateDocksTypes.REQUEST, paginateDocksRequest),
    takeLatest(UpdateDockTypes.REQUEST, updateDockRequest),
    // dock and carriers
    takeLatest(CreateDockCarrierTypes.REQUEST, createDockCarrierRequest),
    takeLatest(DeleteDockCarrierTypes.REQUEST, deleteDockCarrierRequest),
    // dock and companies
    takeLatest(CreateDockClientTypes.REQUEST, createDockClientRequest),
    takeLatest(DeleteDockClientTypes.REQUEST, deleteDockClientRequest),
    // dock and operation types
    takeLatest(
      CreateDockOperationTypeTypes.REQUEST,
      createDockOperationTypeRequest
    ),
    takeLatest(
      DeleteDockOperationTypeTypes.REQUEST,
      deleteDockOperationTypeRequest
    ),
    // dock reserved intervals
    takeLatest(
      CreateDockReservedIntervalTypes.REQUEST,
      createDockReservedIntervalRequest
    ),
    takeLatest(
      DeleteDockReservedIntervalTypes.REQUEST,
      deleteDockReservedIntervalRequest
    ),
    takeLatest(
      FetchDockReservedIntervalTypes.REQUEST,
      fetchDockReservedIntervalRequest
    ),
    takeLatest(
      ListDockReservedIntervalsTypes.REQUEST,
      listDockReservedIntervalsRequest
    ),
    takeLatest(
      PaginateDockReservedIntervalsTypes.REQUEST,
      paginateDockReservedIntervalsRequest
    ),
    takeLatest(
      UpdateDockReservedIntervalTypes.REQUEST,
      updateDockReservedIntervalRequest
    ),
    // vehicle setups
    takeLatest(CreateVehicleSetupTypes.REQUEST, createVehicleSetupRequest),
    takeLatest(FetchVehicleSetupTypes.REQUEST, fetchVehicleSetupRequest),
    takeLatest(ListVehicleSetupsTypes.REQUEST, listVehicleSetupsRequest),
    takeLatest(
      PaginateVehicleSetupsTypes.REQUEST,
      paginateVehicleSetupsRequest
    ),
    takeLatest(UpdateVehicleSetupTypes.REQUEST, updateVehicleSetupRequest),
    // company vehicle setups
    takeLatest(
      CreateCompanyVehicleSetupTypes.REQUEST,
      createCompanyVehicleSetupRequest
    ),
    takeLatest(
      DeleteCompanyVehicleSetupTypes.REQUEST,
      deleteCompanyVehicleSetupRequest
    ),
    takeLatest(
      FetchCompanyVehicleSetupTypes.REQUEST,
      fetchCompanyVehicleSetupRequest
    ),
    takeLatest(
      ListCompanyVehicleSetupsTypes.REQUEST,
      listCompanyVehicleSetupsRequest
    ),
    takeLatest(
      PaginateCompanyVehicleSetupsTypes.REQUEST,
      paginateCompanyVehicleSetupsRequest
    ),
    takeLatest(
      UpdateCompanyVehicleSetupTypes.REQUEST,
      updateCompanyVehicleSetupRequest
    ),
    //order item invoices
    takeLatest(
      ListOrderItemInvoicesTypes.REQUEST,
      listOrderItemInvoicesRequest
    ),
    //company carrier rules
    takeLatest(
      DeleteCompanyCarrierTypes.REQUEST,
      deleteCompanyCarrierRequest
    ),
    takeLatest(
      CreateCompanyCarrierTypes.REQUEST,
      createCompanyCarrierRequest
    ),
    //company company rules
    takeLatest(
      DeleteCompanyClientTypes.REQUEST,
      deleteCompanyClientRequest
    ),
    takeLatest(
      CreateCompanyClientTypes.REQUEST,
      createCompanyClientRequest
    ),
    //company vehicle type rules
    takeLatest(
      DeleteCompanyVehicleTypeTypes.REQUEST,
      deleteCompanyVehicleTypeRequest
    ),
    takeLatest(
      CreateCompanyVehicleTypeTypes.REQUEST,
      createCompanyVehicleTypeRequest
    ),
    //company operation type rules
    takeLatest(
      DeleteCompanyOperationTypeTypes.REQUEST,
      deleteCompanyOperationTypeRequest
    ),
    takeLatest(
      CreateCompanyOperationTypeTypes.REQUEST,
      createCompanyOperationTypeRequest
    ),
    //order file
    takeLatest(
      FetchOrderFileTypes.REQUEST,
      fetchOrderFileRequest
    )
  ]);
}
