import { FormType } from "pages/Settings/Usuários/formSchema"
import { api } from "services"

interface Urls {
    [key: string]: string
}

interface Payload extends FormType {}
const postUrls: Urls = {
    carrierMember: "/carrier-members",
    warehouseMember: "/company-members",
    admin: "/admins",
    clientMember: "/client-members",
}

export async function createUser(payload: Payload) {
  const { data } = await api.post(`${postUrls[payload.type]}`, payload)

  return data
}
