import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  FormPageHeader,
  HiddenInput,
  InvoiceInput,
  MoneyInput,
  NumberInput,
} from 'components/Shared';
import type { OrderItem } from 'contracts/OrderItems';
import { useValidation } from 'hooks';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { notify } from 'services';
import { Formatter } from 'utils';
import { v4 as uuidV4 } from 'uuid';
import { CreateItemValidator } from 'validators/OrderItems';
import * as S from './styles';
import { ModalInvoices } from '../ModalInvoices';
import { OrderItemInvoice } from 'contracts/Orders';
import { ListOrderItemInvoicesActions } from 'store/ducks/orderItemInvoices';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table';
import { useNavigate } from 'react-router-dom';

interface OrderItemsEntry
  extends Pick<OrderItem, 'document' | 'weight' | 'value' | 'invoiceId'> {
  uuid: string;
}
export interface Ref {
  getItems: () => OrderItemsEntry[];
}

interface Props {
  weightCapacity?: number;
  duration?: number;
  carrierId?: string | number | null;
}

interface IOrderItems
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

const OrderItems: IOrderItems = forwardRef<Ref, Props>((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { weightCapacity, duration } = props;
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const [selectedInvoice, setSelectedInvoice] =
    useState<OrderItemInvoice | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { data, loading } = useSelector(
    (state: RootState) => state.listOrderItemInvoices
  );

  const [items, setItems] = useState<OrderItemsEntry[]>([]);

  const { totalWeight, totalValue } = useMemo(
    () =>
      items.reduce(
        (acc, item) => ({
          totalWeight: acc.totalWeight + item.weight,
          totalValue: acc.totalValue + item.value,
        }),
        { totalWeight: 0, totalValue: 0 }
      ),
    [items]
  );

  const onDelete = useCallback((uuid: string): void => {
    setItems((items) => items.filter((item) => item.uuid !== uuid));
  }, []);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        if (!weightCapacity) {
          return notify(
            'error',
            'É necessário selecionar ambos Tipo de veículo e Tipo de carga'
          );
        }

        const { schema } = new CreateItemValidator({
          weightCapacity,
          totalWeight,
        });

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        const newItem: OrderItemsEntry = {
          ...validData,
          uuid: uuidV4(),
        };

        const exist = items.some(
          (value) => value.document === validData.document
        );

        if (exist) {
          notify('error', 'Item já adicionado');
        } else {
          setItems((items) => [...items, newItem]);
          onReset();
        }
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors, totalWeight, weightCapacity]
  );

  const WeightAndDurationComponent = useCallback((): JSX.Element => {
    return (
      <S.WeightAndDuration>
        {!!items.length && (
          <div title="Valor total">
            <S.WalletIcon />
            {totalValue.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </div>
        )}
        {!!weightCapacity && (
          <div title="Limite de peso">
            <S.WeightIcon />
            {Formatter.weight(weightCapacity)}
          </div>
        )}
        {!!duration && (
          <div title="Limite de tempo">
            <S.TimerIcon /> {duration} minutos
          </div>
        )}
      </S.WeightAndDuration>
    );
  }, [duration, weightCapacity, totalValue, items]);

  const onOpenChange = useCallback(() => setModalOpen((prev) => !prev), []);

  const onSuccess = useCallback((invoices: OrderItemInvoice[]) => {
    if (!invoices.length) return notify('error', 'Nota fiscal não encontrada');

    if (invoices?.length > 1) {
      onOpenChange();
    } else {
      onSelectInvoice(invoices[0]);
    }
  }, []);

  const fetchOrderItemInvoice = useCallback((filter: any) => {
    dispatch(ListOrderItemInvoicesActions.request(filter, onSuccess));
  }, []);

  const onSelectInvoice = useCallback((invoice: OrderItemInvoice) => {
    setSelectedInvoice(invoice);
    formRef.current?.setFieldValue('invoiceId', invoice.id);
    formRef.current?.setFieldValue('document', invoice.invoiceNumber);
    formRef.current?.setFieldValue('value', invoice.totalValue);
    formRef.current?.setFieldValue('weight', invoice.totalWeight);
  }, []);

  const onReset = useCallback(() => {
    setSelectedInvoice(null);
    formRef.current?.reset();
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      getItems: () => items,
    }),
    [items]
  );

  return (
    <S.Panel removePadding>
      <ModalInvoices
        invoices={data}
        modalOpen={modalOpen}
        onOpenChange={onOpenChange}
        onSelectInvoice={onSelectInvoice}
      />
      <FormPageHeader
        title="Itens do agendamento"
        icon={<S.PackageIcon />}
        actions={<WeightAndDurationComponent />}
      />
      <Form ref={formRef} onSubmit={onSubmit}>
        <S.FormRow>
          <HiddenInput name="invoiceId" value={selectedInvoice?.id} />
          <InvoiceInput
            name="document"
            label="Documento"
            disabled={!!selectedInvoice}
            hasInvoice={!!selectedInvoice}
            value={selectedInvoice?.invoiceNumber}
            filter={{ carrierId: props.carrierId }}
            isLoading={loading}
            onSearch={fetchOrderItemInvoice}
            onRemove={onReset}
          />
          <NumberInput
            name="weight"
            label="Peso"
            suffix=" Kg"
            value={selectedInvoice?.totalWeight}
            disabled={!!selectedInvoice}
          />
          <MoneyInput
            name="value"
            label="Valor"
            value={selectedInvoice?.totalValue}
            disabled={!!selectedInvoice}
          />
          <S.Button
            style={{ marginTop: 28 }}
            disabled={!weightCapacity}
            mood="primary"
            type="submit"
          >
            Adicionar
          </S.Button>
        </S.FormRow>
      </Form>
      {!!items.length && (
        <div className="rounded-md border bg-white">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Documento</TableHead>
                <TableHead>Peso</TableHead>
                <TableHead>Valor</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="text-xs font-sans">
              {items.map(({ invoiceId, document, value, weight, uuid }) => (
                <TableRow key={uuid}>
                  <TableCell>{document}</TableCell>
                  <TableCell>{Formatter.weight(weight)}</TableCell>
                  <TableCell>{Formatter.currency(value)}</TableCell>
                  <TableCell className="flex justify-end gap-2">
                    <button
                      title="Remover"
                      onClick={() => onDelete(uuid)}
                      className="p-1 rounded-md [&>svg]:text-gray-500 [&>svg]:hover:text-red-500 [&>svg]:transition-all"
                    >
                      <S.TrashIcon />
                    </button>
                    <a
                      title='Visualizar NF'
                      className={
                        invoiceId
                          ? `p-1 rounded-md [&>svg]:text-slate-500 [&>svg]:hover:text-slate-600 [&>svg]:transition-all`
                          : 'p-1 [&>svg]:text-slate-300 cursor-default'
                      }
                      href={
                        invoiceId
                          ? `/inbound/notas-fiscais/pendentes-de-liberacao/${invoiceId}`
                          : ''
                      }
                      target="_blank"
                    >
                      <S.ChevronIcon />
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </S.Panel>
  );
});

export default OrderItems;
