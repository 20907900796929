import * as Yup from 'yup';
import { getDockShape } from 'validators/Common/baseDockValidator';

export class CreateDockValidator {
  public get schema() {
    return Yup.object().shape({
      ...getDockShape(),
      companyId: Yup.number()
        .typeError('Informe o id do armazém')
        .required('Informe o id do armazém'),
    });
  }
}
