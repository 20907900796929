import {
  FetchOrderFileActions as Actions,
  FetchOrderFileRequestAction as RequestAction,
} from "./../../ducks/orderFiles/fetchOrderFile";
import { call, put } from "@redux-saga/core/effects";
import { api, notify, apiErrorHandler } from "services";

export function* fetchOrderFileRequest(action: any) {
  const { id, onSuccess, onFailure }: RequestAction = action;
  try {
    const { data } = yield call(api.get, `order-files/${id}`);
    onSuccess && onSuccess(data);
    yield put(Actions.success(data, data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
