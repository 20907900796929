import styled from 'styled-components';
import { Colors } from 'styles/constants';
import { FieldLabel } from 'styles/components';
export { Button, ActivityIndicator } from 'styles/components';

export const MainContainer = styled.div`
  margin-top: 16px;
  padding: 16px;
  border-radius: 4px;
`;

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: 220px auto 230px;
  gap: 0 24px;
  padding-top: 16px;
  &:not(:last-child) {
    border-bottom: 1px dashed ${Colors.Gray40};
  }
`;

export const Label = styled(FieldLabel)`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  &:not(:last-child) {
    margin-bottom: 14px;
  }
`;

export const Controls = styled.fieldset`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 16px;
  border: none;
  .field-container {
    margin-bottom: 0;
    position: relative;
    .field-error {
      position: absolute;
      left: -16px;
      transform: translateX(-100%);
      width: max-content;
    }
  }
`;
