import axios from 'axios';

const environment = {
  local: {
    api: 'http://localhost:3334/dashboard',
  },
  production: {
    api: process.env.REACT_APP_API_URL,
  },
  import: {
    api: process.env.REACT_APP_API_IMPORT_URL,
  },
};

const baseURL =
  process.env.NODE_ENV === 'development'
    ? environment.local
    : environment.production;

const BaseURLImport = environment.import;

export const showProductionApiAlert =
  process.env.NODE_ENV === 'development' && baseURL === environment.production;

export const api = axios.create({
  baseURL: baseURL.api,
  headers: {},
  withCredentials: true,
});

export const importApi = axios.create({
  baseURL: BaseURLImport.api,
  headers: {},
});

export const dashboardUrl = `https://webcol-dashboard-b6c241cd5fb6.herokuapp.com`;

export const apiDashboard = axios.create({
  baseURL: dashboardUrl,
  headers: {},
  withCredentials: true,
});

export const applyQueryString = (
  url: string,
  payload: Record<string, any>
): string => {
  const validObject: Record<string, any> = {};

  delete payload.dirty;

  for (const [param, value] of Object.entries(payload)) {
    if (value) Object.assign(validObject, { [param]: value });
  }

  if (Object.keys(validObject).length === 0) {
    return url;
  }

  const searchParams = new URLSearchParams(validObject).toString();

  return `${url}?${searchParams}`;
};

export const formDataBuilder = (payload: Record<string, any>): FormData => {
  const formData = new FormData();

  for (const [param, value] of Object.entries(payload)) {
    if (value instanceof File) {
      formData.append(param, value, value.name);
    } else if (value instanceof FileList) {
      for (let i = 0; i < value.length; i++) {
        formData.append(`${param}[]`, value[i], value[i].name);
      }
    } else if (value instanceof Array) {
      value.forEach((val, index) => {
        if (val instanceof File) {
          return formData.append(`${param}[${index}]`, val, val.name);
        } else if (val instanceof Object) {
          return Object.entries(val).forEach(([key, value]: any) => {
            formData.append(`${param}[${index}].${key}`, value);
          });
        }

        formData.append(`${param}[]`, value[index]);
      });
    } else if (value) {
      formData.append(param, value);
    }
  }

  return formData;
};
