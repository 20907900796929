import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Input, Modal, ModalCloseButton, ToggleInput } from 'components/Shared';
import { MODAL_DISMISS_ACTION } from 'constants/Common';
import { useValidation } from 'hooks';
import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { CreateDockActions as CreateActions } from 'store/ducks/docks';
import { TimeHelper } from 'utils';
import { CreateDockValidator } from 'validators/Docks';
import * as S from './styles';

export interface Ref {
  openModal: () => void;
}

interface Props {
  companyId: string | number;
  onCreate?: () => void;
}

interface IDockCreationModal
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

const TIME_STEP = 60 * 5; // 5 minutes

const DockCreationModal: IDockCreationModal = forwardRef<Ref, Props>(
  (props, ref) => {
    const { companyId, onCreate } = props;

    const dispatch: AppDispatch = useDispatch();
    const formRef = useRef<FormHandles>(null);
    const { handleFormErrors, handleApiErrors } = useValidation();
    const { loading: creatingDock, validationErrors } = useSelector(
      (state: RootState) => state.createDock
    );

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onStartTimeBlur = useCallback(
      (event: ChangeEvent<HTMLInputElement>): void => {
        const { value, name } = event.target;
        // manipulate the start time to be the next closest 5 minutes
        const newStartTime = TimeHelper.roundUpMinutes(value, 5);
        formRef.current?.setFieldValue(name, newStartTime);
      },
      []
    );

    const onEndTimeBlur = useCallback(
      (event: ChangeEvent<HTMLInputElement>): void => {
        const { value, name } = event.target;
        // manipulate the end time to be the previous closest 5 minutes
        const newEndTime = TimeHelper.roundDownMinutes(value, 5);
        formRef.current?.setFieldValue(name, newEndTime);
      },
      []
    );

    const onModalClose = useCallback(() => {
      setIsOpen(false);
      formRef.current?.setErrors({});
      dispatch(CreateActions.reset());
    }, [dispatch]);

    const onSuccess = useCallback((): void => {
      formRef.current?.reset();
      onModalClose();
      onCreate && onCreate();
    }, [onCreate, onModalClose]);

    const onSubmit = useCallback(
      async (data: any): Promise<void> => {
        try {
          formRef?.current?.setErrors({});

          Object.assign(data, { companyId, blockedAt: null });

          const { schema } = new CreateDockValidator();

          const validData = await schema.validate(data, {
            abortEarly: false,
          });

          dispatch(CreateActions.request(validData, onSuccess));
        } catch (error) {
          handleFormErrors(error, formRef);
        }
      },
      [dispatch, handleFormErrors, onSuccess, companyId]
    );

    useEffect(() => {
      handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    useEffect(() => {
      handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    useEffect(() => {
      return () => {
        dispatch(CreateActions.reset());
      };
    }, [dispatch]);

    useImperativeHandle(
      ref,
      () => ({
        openModal: () => setIsOpen(true),
      }),
      []
    );

    return (
      <Modal isOpen={isOpen} onClickOutside={onModalClose}>
        <S.ModalContent style={{ maxWidth: '840px' }}>
          <S.ModalHeader>
            <h4>Nova doca</h4>
            <ModalCloseButton onClick={onModalClose} />
          </S.ModalHeader>
          <S.ModalBody>
            <Form ref={formRef} onSubmit={onSubmit}>
              <S.FormRow>
                <Input name="name" label="Nome" />
                <Input
                  name="startTime"
                  type="time"
                  label="Hora de início"
                  step={TIME_STEP}
                  onBlur={onStartTimeBlur}
                />
                <Input
                  name="endTime"
                  type="time"
                  label="Hora de término"
                  step={TIME_STEP}
                  onBlur={onEndTimeBlur}
                />
              </S.FormRow>
              <S.FormRow>
                <ToggleInput
                  name="loadEnabled"
                  label="Carregamento"
                  defaultChecked
                />
                <ToggleInput
                  name="unloadEnabled"
                  label="Descarregamento"
                  defaultChecked
                />
                <ToggleInput name="blockedAt" label="bloqueado" />
              </S.FormRow>
              <S.FormActions>
                <S.Button type="button" mood="light" onClick={onModalClose}>
                  {MODAL_DISMISS_ACTION}
                </S.Button>
                <S.Button type="submit">
                  {creatingDock ? <S.ActivityIndicator /> : 'Salvar'}
                </S.Button>
              </S.FormActions>
            </Form>
          </S.ModalBody>
        </S.ModalContent>
      </Modal>
    );
  }
);

export default DockCreationModal;
