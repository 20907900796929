import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  CompactInput,
  ConfirmationDialog,
  ConfirmationDialogRef,
} from 'components/Shared';
import { Order } from 'contracts/Orders';
import { parseISO } from 'date-fns';
import { useAuth, useValidation } from 'hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { UpdateOrderEventsActions as MainActions } from 'store/ducks/orders';
import { Formatter } from 'utils';
import { UpdateOrderEventsValidator } from 'validators/Orders';
import * as S from './styles';

interface Props {
  order: Order;
  onUpdate?: () => void;
}

type FieldName =
  | 'orderFinishedClient'
  | 'orderFinishedCompany'
  | 'vehicleArrivalClient'
  | 'vehicleArrivalCompany'
  | 'orderStartedClient'
  | 'orderStartedCompany'
  | 'vehicleExitCompany'
  | 'vehicleExitClient';

interface FormField {
  label: string;
  name: FieldName;
}

const fields: FormField[] = [
  {
    label: 'Armazém - Chegada do veículo',
    name: 'vehicleArrivalCompany',
  },
  {
    label: 'Armazém - Ordem iniciada',
    name: 'orderStartedCompany',
  },
  {
    label: 'Armazém - Ordem finalizada',
    name: 'orderFinishedCompany',
  },
  {
    label: 'Armazém - Saída do veículo',
    name: 'vehicleExitCompany',
  },
  {
    label: 'Cliente - Chegada do veículo',
    name: 'vehicleArrivalClient',
  },
  {
    label: 'Cliente - Ordem iniciada',
    name: 'orderStartedClient',
  },
  {
    label: 'Cliente - Ordem finalizada',
    name: 'orderFinishedClient',
  },
  {
    label: 'Cliente - Saída do veículo',
    name: 'vehicleExitClient',
  },
];

export const EventsManager: React.FC<Props> = ({ order, onUpdate }) => {
  const formRef = useRef<FormHandles>(null);
  const dialogRef = useRef<ConfirmationDialogRef>(null);

  const { handleFormErrors, handleApiErrors } = useValidation();
  const { userBelongsToAnyOf } = useAuth();

  const dispatch: AppDispatch = useDispatch();

  const { loading: updatingOrder, validationErrors } = useSelector(
    (state: RootState) => state.updateOrderEvents
  );

  const setExistingValues = useCallback((): void => {
    fields.forEach(({ name }) => {
      if (!order[name]) return;

      formRef.current?.setFieldValue(
        name,
        Formatter.date(order[name], { format: 'HH:mm' })
      );
    });
  }, [order]);

  const getDatetimeLabel = useCallback(
    (event: string | null): string => {
      if (!event) {
        return Formatter.date(order.schedulingDate, {
          format: 'dd MMM yyyy, __:__',
        });
      }

      return Formatter.date(event, { format: 'dd MMM yyyy, HH:mm' });
    },
    [order.schedulingDate]
  );

  const getDatetimeValueFromTime = useCallback(
    (time: string): string => {
      const [hour, minute] = time.split(':').map(Number);
      const date = parseISO(order.schedulingDate);
      date.setHours(hour);
      date.setMinutes(minute);
      return date.toISOString();
    },
    [order.schedulingDate]
  );

  const isLockedField = useCallback(
    (field: FieldName): boolean => {
      const {
        vehicleArrivalCompany,
        orderStartedCompany,
        orderFinishedCompany,
        vehicleExitCompany,
        orderStartedClient,
        vehicleArrivalClient,
        orderFinishedClient,
        noShowAt,
        cancelationReason
      } = order;

      if (noShowAt || cancelationReason) return true;

      const hasDataField = Boolean(order[field]);

      const fieldsConfig = {
        vehicleArrivalCompany: hasDataField,
        orderStartedCompany: hasDataField || !vehicleArrivalCompany,
        orderFinishedCompany: hasDataField || !orderStartedCompany,
        vehicleExitCompany: hasDataField || !orderFinishedCompany,
        vehicleArrivalClient: hasDataField || !vehicleExitCompany,
        orderStartedClient: hasDataField || !vehicleArrivalClient,
        orderFinishedClient: hasDataField || !orderStartedClient,
        vehicleExitClient: hasDataField || !orderFinishedClient,
      }[field];

      return fieldsConfig;
    },
    [order, userBelongsToAnyOf]
  );

  const onSuccess = useCallback((): void => {
    onUpdate && onUpdate();
  }, [onUpdate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        const { schema } = new UpdateOrderEventsValidator({
          order,
        });

        const validData = (await schema.validate(data, {
          abortEarly: false,
        })) as Record<string, any>;

        Object.entries(validData).forEach(([field, value]) => {
          if (!value) {
            validData[field] = null;
            return;
          }
          validData[field] = getDatetimeValueFromTime(value);
        });

        const confirmed = await dialogRef.current?.openDialog({
          title: 'Confirmar apontamento?',
          message: 'Esta ação não poderá ser desfeita.',
          confirmButtonText: 'Confirmar',
        });

        if (confirmed) {
          dispatch(MainActions.request(order.id, validData, onSuccess));
        }
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      dispatch,
      getDatetimeValueFromTime,
      handleFormErrors,
      onSuccess,
      order,
      userBelongsToAnyOf,
    ]
  );

  useEffect(() => {
    setExistingValues();
  }, [setExistingValues]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  return (
    <S.MainContainer>
      <ConfirmationDialog ref={dialogRef} />
      <Form ref={formRef} onSubmit={onSubmit}>
        {fields.map(({ label, name }) => {
          const isLocked = isLockedField(name);
          return (
            <S.FormRow key={name} onSubmit={onSubmit}>
              <S.Label>{label}</S.Label>
              <S.Label>{getDatetimeLabel(order[name])}</S.Label>
              {userBelongsToAnyOf('admin', 'warehouseMember') && (
                <S.Controls disabled={isLocked}>
                  <CompactInput type="time" name={name} readOnly={isLocked} />
                  <S.Button
                    style={{ marginBottom: 16 }}
                    type={isLocked ? 'button' : 'submit'}
                    size="small"
                  >
                    {!isLocked && updatingOrder ? (
                      <S.ActivityIndicator />
                    ) : (
                      'Marcar'
                    )}
                  </S.Button>
                </S.Controls>
              )}
            </S.FormRow>
          );
        })}
      </Form>
    </S.MainContainer>
  );
};
