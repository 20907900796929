import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Input, Modal, ModalCloseButton, ToggleInput } from 'components/Shared';
import * as Tabs from 'components/Shared/Tabs';
import { MODAL_DISMISS_ACTION } from 'constants/Common';
import { useValidation } from 'hooks';
import {
  ChangeEvent,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import {
  FetchDockActions as FetchActions,
  UpdateDockActions as UpdateActions,
} from 'store/ducks/docks';
import { TimeHelper } from 'utils';
import { UpdateDockValidator } from 'validators/Docks';
import CarrriersManager, { Ref as CarriersManagerRef } from './CarriersManager';
import ClientsManager, {
  Ref as clientsManagerRef,
} from './ClientsManager';
import OperationTypesManager, {
  Ref as OperationTypesManagerRef,
} from './OperationTypesManager';
import * as S from './styles';

export interface Ref {
  selectDock: (id: number) => void;
}

interface Props {
  onUpdate?: () => void;
}

interface IDockUpdateModal
  extends ForwardRefExoticComponent<Props & RefAttributes<Ref>> {}

const TIME_STEP = 60 * 5; // 5 minutes

const DockUpdateModal: IDockUpdateModal = forwardRef<Ref, Props>(
  (props, ref) => {
    const { onUpdate } = props;

    const dispatch: AppDispatch = useDispatch();
    const { handleApiErrors, handleFormErrors } = useValidation();
    const [isOpen, setIsOpen] = useState(false);

    const formRef = useRef<FormHandles>(null);
    const carriersManagerRef = useRef<CarriersManagerRef>(null);
    const clientsManagerRef = useRef<clientsManagerRef>(null);
    const operationTypesManagerRef = useRef<OperationTypesManagerRef>(null);
    const tabsOutletRef = useRef<Tabs.Ref>(null);

    const { data: dock, loading: fetchingDock } = useSelector(
      (state: RootState) => state.fetchDock
    );

    const { loading: updatingDock, validationErrors } = useSelector(
      (state: RootState) => state.updateDock
    );

    const onStartTimeBlur = useCallback(
      (event: ChangeEvent<HTMLInputElement>): void => {
        const { value, name } = event.target;
        // manipulate the start time to be the next closest 5 minutes
        const newStartTime = TimeHelper.roundUpMinutes(value, 5);
        formRef.current?.setFieldValue(name, newStartTime);
      },
      []
    );

    const onEndTimeBlur = useCallback(
      (event: ChangeEvent<HTMLInputElement>): void => {
        const { value, name } = event.target;
        // manipulate the end time to be the previous closest 5 minutes
        const newEndTime = TimeHelper.roundDownMinutes(value, 5);
        formRef.current?.setFieldValue(name, newEndTime);
      },
      []
    );

    const onSelectDock = useCallback(
      (id: number): void => {
        setIsOpen(true);
        dispatch(FetchActions.request(id));
      },
      [dispatch]
    );

    const onModalClose = useCallback((): void => {
      formRef.current?.reset();
      dispatch(FetchActions.reset());
      dispatch(UpdateActions.reset());
      setIsOpen(false);
    }, [dispatch]);

    const onDockLoad = useCallback(() => {
      if (!dock || !formRef.current) return;
      const {
        name,
        startTime,
        endTime,
        loadEnabled,
        unloadEnabled,
        blockedAt,
      } = dock;

      formRef.current.setData({
        name,
        startTime: startTime.substring(0, 5),
        endTime: endTime.substring(0, 5),
        blockedAt: blockedAt ? '1' : '0',
        loadEnabled: loadEnabled ? '1' : '0',
        unloadEnabled: unloadEnabled ? '1' : '0',
      });
    }, [dock]);

    const onSuccess = useCallback((): void => {
      onUpdate && onUpdate();
      onModalClose();
    }, [onModalClose, onUpdate]);

    const onSubmit = useCallback(
      async (data: any): Promise<void> => {
        try {
          formRef?.current?.setErrors({});

          const { schema } = new UpdateDockValidator();

          const validData = await schema.validate(data, {
            abortEarly: false,
          });

          dispatch(UpdateActions.request(dock?.id, validData, onSuccess));
        } catch (error) {
          handleFormErrors(error, formRef);
        }
      },
      [dispatch, dock?.id, handleFormErrors, onSuccess]
    );

    useEffect(() => {
      onDockLoad();
    }, [onDockLoad]);

    useEffect(() => {
      handleApiErrors(validationErrors, formRef);
    }, [handleApiErrors, validationErrors]);

    useImperativeHandle(
      ref,
      () => ({
        selectDock: onSelectDock,
      }),
      [onSelectDock]
    );

    useEffect(() => {
      return () => {
        dispatch(FetchActions.reset());
        dispatch(UpdateActions.reset());
      };
    }, [dispatch]);

    return (
      <Modal isOpen={isOpen} onClickOutside={onModalClose}>
        <S.ModalContent style={{ maxWidth: '960px' }}>
          <S.ModalHeader>
            <h4>Editar doca</h4>
            {fetchingDock && <S.ActivityIndicator />}
            <ModalCloseButton onClick={onModalClose} />
          </S.ModalHeader>

          {!!dock && (
            <S.ModalBody>
              <Form ref={formRef} onSubmit={onSubmit}>
                <S.FormRow>
                  <Input id="editName" name="name" label="Nome" />
                  <Input
                    id="editStartTime"
                    name="startTime"
                    type="time"
                    label="Hora de início"
                    step={TIME_STEP}
                    onBlur={onStartTimeBlur}
                  />
                  <Input
                    id="editEndTime"
                    name="endTime"
                    type="time"
                    label="Hora de término"
                    step={TIME_STEP}
                    onBlur={onEndTimeBlur}
                  />
                </S.FormRow>
                <S.FormRow>
                  <ToggleInput
                    id="editLoadEnabled"
                    name="loadEnabled"
                    label="Carregamento"
                  />
                  <ToggleInput
                    id="editUnloadEnabled"
                    name="unloadEnabled"
                    label="Descarregamento"
                  />
                  <ToggleInput
                    id="editBlockedAt"
                    name="blockedAt"
                    label="bloqueado"
                  />
                </S.FormRow>
              </Form>

              {!!dock && (
                <S.TabedContainer>
                  <Tabs.Controller
                    onTabChange={(i) => tabsOutletRef?.current?.selectTab(i)}
                  >
                    <Tabs.ModalTab title="Transportadoras" />
                    <Tabs.ModalTab title="Clientes" />
                    <Tabs.ModalTab title="Tipos de operação" />
                  </Tabs.Controller>
                  <Tabs.Outlet ref={tabsOutletRef}>
                    <CarrriersManager
                      ref={carriersManagerRef}
                      dockId={dock.id}
                      dockCarriers={dock.dockCarriers}
                      onUpdate={() => onSelectDock(dock.id)}
                    />
                    <ClientsManager
                      ref={clientsManagerRef}
                      dockId={dock.id}
                      dockClients={dock.dockClients}
                      onUpdate={() => onSelectDock(dock.id)}
                    />
                    <OperationTypesManager
                      ref={operationTypesManagerRef}
                      dockId={dock.id}
                      dockOperationTypes={dock.dockOperationTypes}
                      onUpdate={() => onSelectDock(dock.id)}
                    />
                  </Tabs.Outlet>
                </S.TabedContainer>
              )}

              <S.FormActions>
                <S.Button type="button" mood="light" onClick={onModalClose}>
                  {MODAL_DISMISS_ACTION}
                </S.Button>
                <S.Button
                  type="submit"
                  onClick={() => formRef?.current?.submitForm()}
                >
                  {updatingDock ? <S.ActivityIndicator /> : 'Salvar'}
                </S.Button>
              </S.FormActions>
            </S.ModalBody>
          )}
        </S.ModalContent>
      </Modal>
    );
  }
);

export default DockUpdateModal;
