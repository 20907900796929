import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';
import GlobalStyles from 'styles/global';
import AppRoutes from './routes';
import { QueryClient, QueryClientProvider } from 'react-query';

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ToastContainer />
                <BrowserRouter>
                    <GlobalStyles />
                    <QueryClientProvider client={new QueryClient()}>
                        <AppRoutes />
                    </QueryClientProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
};

export default App;
