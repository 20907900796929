import styled, { css } from 'styled-components';
import { ActivityIndicator, BasePaginatorHeader, BasePaginatorListItem, DownArrowSquareIcon } from 'styles/components';
import { ColorScheme } from 'styles/constants';
export {
  PaginatorColumn as Column,
  PaginatorActionsColumn as ActionsColumn,
} from 'styles/components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const baseGridCss = css`
  grid-template-columns: 1fr 70px;
  padding: 0.5rem 1rem;
`;

export const ListHeader = styled(BasePaginatorHeader)`
  ${baseGridCss}
`;

export const Loading = styled(ActivityIndicator)`
  width: 20px;
  height: 20px;
`

export const ListItem = styled(BasePaginatorListItem)`
  ${baseGridCss}
  &:hover {
    background-color: ${ColorScheme.LightText}22;
  }
  > div:last-child a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  button {
    display: flex;
    align-items: center;
  }
`;

export const DownloadIcon = styled(DownArrowSquareIcon)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${ColorScheme.Text};
`