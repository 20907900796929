import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListVehicleSetupsActions as MainActions } from 'store/ducks/vehicleSetups';

interface FindVehicleSetups {
  excludeBlocked?: boolean;
}

export const useVehicleSetups = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: vehicleSetups, loading: loadingVehicleSetups } = useSelector(
    (state: RootState) => state.listVehicleSetups
  );

  const [ignoredBlockedId, enableVehicleSetupIfBlocked] = useState<
    number | null
  >(null);

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchVehicleSetups = useCallback(
    (query: FindVehicleSetups): void => {
      dispatch(MainActions.request(query));
    },
    [dispatch]
  );

  const onVehicleSetupsLoad = useCallback((): void => {
    setOptions(
      vehicleSetups.map(
        ({ id, vehicleType, cargoType, blockedAt, vehiclePlate, code }) => ({
          value: id,
          label: `${code} ▪ ${vehicleType.name} (${cargoType.name}) ${vehiclePlate}`,
          isDisabled: ignoredBlockedId !== id && blockedAt !== null,
        })
      )
    );
  }, [vehicleSetups, ignoredBlockedId]);

  useEffect(() => {
    onVehicleSetupsLoad();
  }, [onVehicleSetupsLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    vehicleSetups,
    vehicleSetupOptions: options,
    loadingVehicleSetups,
    fetchVehicleSetups,
    enableVehicleSetupIfBlocked,
  };
};

export type VehicleSetupsHook = ReturnType<typeof useVehicleSetups>;
