import { differenceInMinutes, subMinutes } from 'date-fns';
import { Validator } from 'utils';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

interface Props {
  escortRequired: boolean;
  schedulingDate: Date;
}

type TestDate = Yup.TestConfig<Date | undefined, AnyObject>;

const testDate: TestDate = {
  name: 'testDate',
  test: function (value) {
    try {
      if(!value) throw new Error('Campo obrigatório')

      Validator.dateIsPast(value)
      return true;
    } catch (error: any) {
      return this.createError({
        message: error?.message,
      });
    }
  },
};

const testOptionalDate: TestDate = {
  name: 'testOptionalDate',
  test: function (value) {
    try {
      if (!value) return true;
      Validator.dateIsPast(value)
      return true;
    } catch (error: any) {
      return this.createError({
        message: error?.message,
      });
    }
  },
};

export class CreateOrderValidator {
  private acceptExt = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
  ];
  constructor(private props: Props) {}
  public get schema() {
    const requiredMessage = 'Campo obrigatório';
    return {
      loadSchema: Yup.object().shape({
        // parent relationships
        vehicleCode: Yup.string()
          .typeError(requiredMessage)
          .required(requiredMessage),
        vehicleSetupId: Yup.number()
          .typeError(requiredMessage)
          .required(requiredMessage),
        fleetType: Yup.string()
          .typeError(requiredMessage)
          .required(requiredMessage),
        companyId: Yup.number()
          .typeError(requiredMessage)
          .required(requiredMessage),
        carrierId: Yup.number()
          .typeError(requiredMessage)
          .required(requiredMessage),
        driverName: Yup.string()
          .required(requiredMessage)
          .typeError(requiredMessage),
        driverDocument: Yup.string()
          .required(requiredMessage)
          .typeError(requiredMessage)
          .test({
            name: 'testDocument',
            test(value = '') {
              try {
                Validator.validateDocument(value, 'cpf');
                return true;
              } catch (error: any) {
                return this.createError({
                  message: error?.message,
                });
              }
            },
          }),
        plannedDepartureTime: Yup.date()
          .typeError(requiredMessage)
          .test({
            test: (value, { createError }) => {
              const maxDiffMinutes = 2 * 60 /**2h */

              try {
                if (!value) throw new Error(requiredMessage);

                Validator.dateIsPast(value)

                if (differenceInMinutes(new Date(this.props.schedulingDate), value) > maxDiffMinutes) {
                  throw new Error('Diferença maior que 2 horas');
                }
                
                if (differenceInMinutes(new Date(this.props.schedulingDate), value) < 0) {
                  throw new Error('Saída posterior ao agendamento');
                }

                return true;
              } catch (error: any) {
                return createError({
                  message: error?.message,
                });
              }
            },
          }),
        schedulingDate: Yup.date().typeError(requiredMessage).test(testDate),
        expectedLoadingTime: Yup.date()
          .typeError(requiredMessage)
          .test(testDate),
        optimalOutputTime: Yup.date().typeError(requiredMessage).test(testDate),
        observation: Yup.string()
          .nullable()
          .transform((value: string) => {
            if (!value) return null;
            return value.trim();
          }),
        material: Yup.string()
          .nullable()
          .transform((value: string) => {
            if (!value) return null;
            return value.trim();
          }),
        documents: Yup.array()
          .of(Yup.mixed())
          .test(
            'file',
            'O arquivo deve ser PNG, JPG, JPEG ou PDF',
            async (value) => {
              if (!value) return true;

              return value.every((file) => this.acceptExt.includes(file.type));
            }
          )
          .test(
            'fileSize',
            'O tamanho máximo do arquivo é de 5 MB',
            (value) => {
              if (!value) return true;

              return value.every((file) => file.size <= 5242880);
            }
          )
          .optional(),
      }),
      unloadSchema: Yup.object().shape({
        responsible: Yup.string().optional().typeError(requiredMessage),
        clientId: Yup.string().nullable().optional().typeError(requiredMessage),
      }),
    };
  }
}
