import { CompanyUpdateForm, CompanyVehicleSetupsManager, DocksManager } from 'components/Pages/Companies';
import { RulesManager } from 'components/Pages/Companies/Rules';
import { Scaffold } from 'layouts';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FetchCompanyActions as FetchActions } from 'store/ducks/companies';

export const CompanyUpdatePage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onUpdate = useCallback((): void => {
    navigate('/configuracoes/empresas');
  }, [navigate]);

  const fetchCompany = useCallback(
    () => id && dispatch(FetchActions.request(id)),
    [dispatch, id]
  );


  return (
    <Scaffold>
      <CompanyUpdateForm companyId={id} onUpdate={onUpdate} />
      <CompanyVehicleSetupsManager companyId={id} />
      <DocksManager companyId={id} />
      <RulesManager companyId={id} onUpdate={fetchCompany} />
    </Scaffold>
  );
};
