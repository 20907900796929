import { SelectOption } from 'contracts/Common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListDocksActions as MainActions } from 'store/ducks/docks';

interface FindDocks {
  companyId?: string | number;
}

export const useDocks = () => {
  const dispatch: AppDispatch = useDispatch();

  const { data: docks, loading: loadingDocks } = useSelector(
    (state: RootState) => state.listDocks
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchDocks = useCallback(
    (query: FindDocks = {}): void => {
      dispatch(MainActions.request(query));
    },
    [dispatch]
  );

  const onDocksLoad = useCallback((): void => {
    setOptions(
      docks.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    );
  }, [docks]);

  useEffect(() => {
    onDocksLoad();
  }, [onDocksLoad]);

  useEffect(() => {
    return () => {
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    docks,
    dockOptions: options,
    loadingDocks,
    fetchDocks,
  };
};

export type DocksHook = ReturnType<typeof useDocks>;
