import * as S from "./styles";
import { Modal } from "components/Shared";
import { OrderItemInvoice } from "contracts/Orders";
import { Formatter } from "utils";

interface ModalInvoices {
  modalOpen: boolean;
  onOpenChange: () => void;
  onSelectInvoice: (invoice: OrderItemInvoice) => void;
  invoices: OrderItemInvoice[];
}

export const ModalInvoices = ({
  modalOpen,
  onOpenChange,
  onSelectInvoice,
  invoices,
}: ModalInvoices) => (
  <Modal isOpen={modalOpen} key="modalInvoices" onClickOutside={onOpenChange}>
    <S.ModalContent style={{ maxWidth: "700px" }}>
      <S.ModalHeader>Selecione a NF</S.ModalHeader>
      <S.ModalBody style={{ overflow: "auto", maxHeight: "300px" }}>
        <S.HeaderList>
          <span>ID</span>
          <span>NF</span>
          <span>Origem</span>
          <span>Transportadora</span>
          <span>Destino</span>
          <span>Valor</span>
        </S.HeaderList>
        {invoices.map((invoice) => {
          const origin = `${invoice.originTradeName}`;
          const carrier = `${invoice.carrierTradeName} - ${invoice.carrierDocument}`;
          const destiny = `${invoice.destinyTradeName}`;
          const value = Formatter.currency(invoice.totalValue)

          return (
            <S.Row
              onClick={() => {
                onSelectInvoice(invoice);
                onOpenChange();
              }}
            >
              <span title={String(invoice.id)}>{invoice.id}</span>
              <span>{invoice.invoiceNumber}</span>
              <span title={origin}>{origin}</span>
              <span title={carrier}>{carrier}</span>
              <span title={destiny}>{destiny}</span>
              <span title={value}>{value}</span>
            </S.Row>
          );
        })}
      </S.ModalBody>
    </S.ModalContent>
  </Modal>
);
