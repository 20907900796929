export class Cleaner {
  public static emptyFieldsToNull(object: any) {
    return Object.fromEntries(
      Object.entries(object).map(([key, value]) => [
        key,
        Boolean(value) ? value : null,
      ])
    );
  }
  public static removeEmptyFields(object: any) {
    return Object.fromEntries(
      Object.entries(object).filter(([, value]) =>
        typeof value === 'string' ? Boolean(value) : true
      )
    );
  }
}
