import * as Yup from 'yup';
import { getCompanyVehicleSetupShape } from 'validators/Common/baseCompanyVehicleSetupValidator';

export class CreateCompanyVehicleSetupValidator {
  public get schema() {
    return Yup.object().shape({
      ...getCompanyVehicleSetupShape(),
    });
  }
}
