import type { SelectOption } from 'contracts/Common';
import { format } from 'date-fns';
import { v4 as randomId } from 'uuid';

export const optionsEscolta = [
  {
    id: randomId(),
    label: 'Sim',
    value: 'Y',
  },
  {
    id: randomId(),
    label: 'Não',
    value: 'N',
    defaultCheck: true,
  },
];
export const optionsAtendeu = [
  {
    id: randomId(),
    label: 'Sim',
    value: 'Y',
    defaultCheck: true,
  },
  {
    id: randomId(),
    label: 'Não',
    value: 'N',
  },
];

export const optionsFleet: SelectOption[] = [
  {
    label: 'Terceiro',
    value: 'terceiro',
  },
  {
    label: 'Agregado',
    value: 'agregado',
  },
  {
    label: 'Própria',
    value: 'propria',
  },
];

export const MIN_DATE = new Date().toISOString().split('T')[0];
export const MIN_DATETIME = format(new Date(), 'yyyy-MM-dd HH:mm');

const selectFields = [
  'vehicleSetupId',
  'fleetType',
  'companyId',
  'carrierId',
  'clientId',
];

const a = [
  'vehicleCode',
  'vehicleSetupId',
  'fleetType',
  'companyId',
  'carrierId',
  'driverName',
  'driverDocument',
  'plannedDepartureTime',
  'expectedLoadingTime',
  'materialSeparationTime',
  'deliveryDocumentationTime',
  'optimalOutputTime',
  'withinTimeframeCompany',
  'responsibleCompany',
  'reasonCompany',
  'ncProcessCompany',
  'escortRequired',
  'escortRequestTime',
  'geNumber',
  'observation',
  'material',
];

const b = [
  'calculatedArrivalTime',
  'dischargeDocumentationTime',
  'responsible',
  'clientId',
  'atendeuPerfomanceDescarregamento',
  'responsibleClient',
  'reasonClient',
  'ncProcessClient',
];
